import axios from "axios";
import url from "../urls";

export default {
  //put
  add(context, data) {
    return new Promise((resolve, reject) => {
      let addUrl = url.StudentTestUrl;
      axios
        .put(addUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //get
  get(context, data) {
    return new Promise((resolve, reject) => {
      let getUrl = url.GetAllTestByStudentIdUrl;

      axios
        .get(getUrl, {
          params: data,
        })
        .then((response) => {
          //console.log("question got");
          //context.commit("mutateCompletionQuestions", response.data);
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //get
  getTestById(context, data) {
    return new Promise((resolve, reject) => {
      let getUrl = url.GetTestByIdUrl;
      axios
        .get(getUrl, {
          params: data,
        })
        .then((response) => {
          //console.log("question got");
          //context.commit("mutateCompletionQuestions", response.data);
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //get
  getAllTestByStudentId(context, data) {
    return new Promise((resolve, reject) => {
      let getUrl = url.GetAllTestByStudentIdUrl;

      axios
        .get(getUrl, {
          params: {
            userId: data.userId,
          },
        })
        .then((response) => {
          //过滤掉还未publish给学生的测试
          if (data.userRole == "Student") {
            response.data = response.data.filter((el) => el.published == true);
          }
          context.commit("mutateTestList", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //get
  getAllTestsByUserId(context, data) {
    return new Promise((resolve, reject) => {
      let getUrl = url.GetAllTestsByUserIdUrl;

      axios
        .get(getUrl, {
          params: {
            userId: data.userId,
          },
        })
        .then((response) => {
          context.commit("mutateTestList", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //post
  createEditStudentTest(context, data) {
    return new Promise((resolve, reject) => {
      let editUrl = url.StudentTestUrl;
      axios
        .post(editUrl, data)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  publishStudentTest(context, data) {
    return new Promise((resolve, reject) => {
      let editUrl = url.StudentTestUrl;
      axios
        .post(editUrl, data)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //post
  editVocStudentTestVocabularyAnswer(context, data) {
    return new Promise((resolve, reject) => {
      let editUrl = url.EditVocStudentTestVocabularyAnswer;
      axios
        .post(editUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //post
  correctStudentAudioAnswer(context, data) {
    return new Promise((resolve, reject) => {
      let editUrl = url.CorrectStudentAudioAnswer;
      console.log(editUrl)
      console.log(data)
      axios
        .post(editUrl, null, {params: data})
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  addVocabularyAnswers(context, data) {
    return new Promise((resolve, reject) => {
      let addUrl = url.AddVocabularyAnswersUrl;
      axios
        .post(addUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //post
  updateStudentTestStatus(context, data) {
    return new Promise((resolve, reject) => {
      let editUrl = url.StudentTestUrl;
      axios
        .post(editUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //post
  edit(context, data) {
    return new Promise((resolve, reject) => {
      let editUrl = url.StudentTestUrl;
      axios
        .post(editUrl, data)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  createDuplicatedTest(context, data) {
    return new Promise((resolve, reject) => {
      let createDuplicatedTestUrl = url.CreateDuplicatedTestUrl;
      axios
        .post(createDuplicatedTestUrl, data)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //delete
  delete(context, data) {
    return new Promise((resolve, reject) => {
      let deleteUrl = url.StudentTestUrl;
      axios
        .delete(deleteUrl, { params: data })
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
