import { createApp } from "vue";
import "./assets/iconfont/iconfont.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import elementplus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import axios from "axios";
import editorInitConfig from "./setting/editorInitConfig";
import { quillEditor } from 'vue3-quill'

const app = createApp(App);

app.config.globalProperties.$http = axios;
app.config.globalProperties.$editorInitConfig = editorInitConfig;

app.config.globalProperties.$playAudio = function (englishWord, componentRef) {
  return new Promise((resolve, reject) => {
    //var vocAPI = "http://listening.andover.com.cn/static/Audio/vocAudios/";
    var vocAPI = "/vocabulary/audios/listeningAudios/vocAudios/";
    ///vocabulary/audios/listeningAudios/
    // hole 和 shed发音不对，替换成英音的发音，type=1
    //if (englishWord == "hole" || englishWord == "shed" || englishWord == "loudness" || englishWord == "skier") {
    //vocAPI = "http://dict.youdao.com/dictvoice?type=1&audio=";
    //}
    componentRef.src = vocAPI + englishWord + ".mp3";

    // Event listener to resolve the promise when the audio finishes playing
    componentRef.addEventListener("ended", () => {
      resolve();
    });

    // Event listener to reject the promise if an error occurs during audio playback
    // componentRef.addEventListener("error", (error) => {
    //   reject(error);
    // });

    // Attempt to play the audio
    componentRef.play().catch(() => {
      console.log("Error catched and trying alternative sources");

      // Array of alternative sources
      const alternativeSources = [
        "http://dict.youdao.com/dictvoice?type=0&audio=" + englishWord,
        "http://dict.youdao.com/dictvoice?type=1&audio=" + englishWord,
        "http://audio.dict.cn/mbTd30L5d074d3286a200f1f38fe91bbb1aef8ec.mp3?t=" +
          englishWord,
        "http://audio.dict.cn/muc0L5459674275cd6bee904e3ed82b5b64a29.mp3?t=" +
          englishWord,
      ];

      // Try alternative sources one by one
      (function tryNextSource(index) {
        if (index < alternativeSources.length) {
          componentRef.src = alternativeSources[index];
          componentRef.play().catch(() => {
            console.log("Error catched for alternative source " + index);
            tryNextSource(index + 1);
          });
        } else {
          // No more alternative sources, reject the promise
          reject(new Error("All alternative sources failed"));
        }
      })(0);

      //先播放本地服务器音频，如果出错，播放有道美音，如果再出错，播放有道英音
      // componentRef.play().catch(() => {
      //   console.log("Error catched and switch to Youdao AC interface");
      //   componentRef.src =
      //     "http://dict.youdao.com/dictvoice?type=0&audio=" + englishWord;
      //   componentRef.play().catch(() => {
      //     console.log("Error catched and switch to Youdao EC interface");
      //     componentRef.src =
      //       "http://dict.youdao.com/dictvoice?type=1&audio=" + englishWord;
      //     componentRef.play().catch(() => {
      //       console.log("Error catched and switch to dict.cn female interface");
      //       componentRef.src =
      //         "http://audio.dict.cn/mbTd30L5d074d3286a200f1f38fe91bbb1aef8ec.mp3?t=" +
      //         englishWord;
      //       componentRef.play().catch(() => {
      //         console.log("Error catched and switch to dict.cn male interface");
      //         componentRef.src =
      //           "http://audio.dict.cn/muc0L5459674275cd6bee904e3ed82b5b64a29.mp3?t=" +
      //           englishWord;
      //         componentRef.play();
      //       });
      //     });
      //   });
    });
  });
};

app.config.globalProperties.$playYoudaoAudio = function (
  englishWord,
  componentRef
) {
  var vocAPI = "http://dict.youdao.com/dictvoice?type=0&audio=";
  componentRef.src = vocAPI + englishWord;
  componentRef.play();
};

//axios.defaults.baseURL = "http://106.14.158.100:9000";

axios.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("token")) {
      config.withCredentials = true; // allow token; fix CORS
      config.headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Encoding": "gzip",
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    if (response.status == 401) {
      localStorage.removeItem("token");
      router.push("/login");
    }

    return Promise.resolve(response);
  },
  function (error) {
    if (error.response) {
      if (error.response.status == 401) {
        localStorage.removeItem("token");
        router.push("/login");
      }
    }

    return Promise.reject(error);
  }
);

router.beforeEach((to, from, next) => {
  if (localStorage.getItem("token")) {
    next();
  } else {
    if (to.path === "/login") {
      next();
    } else {
      next("/login");
    }
  }

  //meta.title 对应routes路径配置文件中的meta对象；把页面的title变成meta里设置的title
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  // in case that student click back route to return to practice page for answering question again
  // if (from.path == "/answerresult") {
  //   next("/list");
  // }
});

//for element plus icon import
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(store).use(router).use(elementplus).use(quillEditor).mount("#app");
