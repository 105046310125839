<template>
  <div>
    <div class="student-assignment-view">
      <student-voc-practice-list :targetId="targetId" />
    </div>
    <div class="bound-student-list">
      <el-table
        ref="studentListTable"
        :data="
          boundStudentList.filter(
            (data) =>
              !search ||
              data.targetName.toLowerCase().includes(search.toLowerCase())
          )
        "
        highlight-current-row
        @current-change="onStudentClick"
        style="width: 14%; margin-left: 1%; position: absolute; top: 70px"
      >
        <el-table-column prop="targetName">
          <template #header>
            <el-input v-model="search" size="small" placeholder="search" />
          </template>
        </el-table-column>
        <el-table-column label="中文名" prop="chineseName"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import StudentVocPracticeList from "./StudentVocPracticeList.vue";

export default {
  name: "CheckStudentAssignment",

  components: {
    StudentVocPracticeList,
  },

  data() {
    return {
      search: "",
      boundStudentList: [],
      targetId: null,
    };
  },

  mounted: function () {
    this.getBoundStudentList();
  },

  methods: {
    getBoundStudentList: function () {
      if (localStorage.getItem("userRole") == "Student") return;
      let teacherId = localStorage.getItem("userId");
      this.$store
        .dispatch("Admin/getRelationship", {
          userId: teacherId,
        })
        .then((response) => {
          this.boundStudentList = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onStudentClick: function (row) {
      console.log(row);
      this.targetId = row.targetId;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
