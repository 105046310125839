<template>
  <div id="outsideContainer">
    <div class="card-wrapper">
      <div class="card-content">
        <div class="card-items">
          <div class="test-progress" v-if="testItem.studentAnswers">
            {{ currentTestingVocIndex + 1 }}/{{
              testItem.studentAnswers.length
            }}
          </div>
          <div class="test-audio-icon">
            <el-tooltip
              class="box-item"
              effect="light"
              content="点击播放，请注意剩余次数"
              placement="top"
            >
              <span
                class="iconfont icon-voc-audio"
                @click="handleAudioBtnClick"
              ></span>
            </el-tooltip>
          </div>

          <div class="test-listening-count" v-if="testItem.studentAnswers">
            剩余播放次数：{{
              testItem.studentAnswers[currentTestingVocIndex].listeningCount
            }}
          </div>

          <div class="count-down-text">
            <voc-count-down
              ref="vocCountDown"
              @on-count-down-finished="handleCountDownFinish"
            />
          </div>
          <div class="test-item">
            <audio-to-text-recorder
              ref="audioToTextRecorder"
              @on-recording-btn-click="($event) => (test.isRecording = $event)"
              @audio-to-text="handleAudioToText($event)"
            />
          </div>

          <div v-if="this.test.isTypingEn">
            <el-input
              ref="EnInputBox"
              v-model="this.test.currentEnType"
              placeholder="拼写"
              :style="{ width: '20%' }"
            ></el-input>
          </div>

          <div class="test-item audio-text" v-if="testItem.studentAnswers">
            {{
              testItem.studentAnswers[this.currentTestingVocIndex]
                .textConvertedByAudio
            }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="next-btn">
      <el-button type="primary" round @click="handleNextClick">NEXT</el-button>
    </div> -->

    <div>
      <audio ref="vocaudio"></audio>
    </div>
  </div>
</template>

<script>
import AudioToTextRecorder from "../recordtotest/RecordingCard.vue";
import VocCountDown from "../recordtotest/VocCountDown.vue";
import pinyin from "pinyin";
export default {
  name: "AudioTestItem",
  components: { AudioToTextRecorder, VocCountDown },
  data() {
    return {
      audioText: "",
      printingTextInterval: null,
      decodedQueryObject: null,
      testItem: {},
      currentTestingVocIndex: 0,
      test: {
        isRecording: false,
        recordingCardInitialized: false,
        mode: 0, //1,2,3种模式，对应录音，录音+拼写，拼写
        isTypingEn: false,
        currentEnType: "",
      },
      initialCountDownSetting: 5,
      initialEnTypeCountDownSetting: 3,
      currentOverviewId: null,
    };
  },

  watch: {
    "test.isTypingEn": {
      handler(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.$refs.EnInputBox.focus();
          });
        }
      },
      deep: true,
    },
  },

  beforeMount: function () {
    // decode query string into object
    let queryString = window.atob(this.$route.query.encodedString);
    queryString = window.decodeURIComponent(queryString);
    this.decodedQueryObject = JSON.parse(queryString);
  },
  mounted: function () {
    document.addEventListener("keyup", this.handleAudioTestKeyUp);
    //set background color
    document
      .querySelector("body")
      .setAttribute("style", "background-color: rgb(246, 247, 251)");

    //初始化用户角色
    this.userRole = localStorage.getItem("userRole");
    this.getTestById(
      this.decodedQueryObject.id,
      this.decodedQueryObject.studentId
    );
  },

  beforeUnmount: function () {
    document.removeEventListener("keyup", this.handleAudioTestKeyUp); // remove global key down event before leaving voc page
  },

  methods: {
    getTestById: function (testId, studentId) {
      this.$store
        .dispatch("StudentTest/getTestById", {
          id: testId,
          userId: studentId,
          includeAudio: false,
        })
        .then((response) => {
          //测试页面可能是从task任务进的，这时候decodedQueryObject不完整，需要补齐
          //如果query里面有task就说明是task过来的，所以初始化
          if (this.decodedQueryObject.task) {
            this.initializeDecodedQueryObject(response.data);
          }
          //状态是finished或者最后一个音频有东西，就跳转到结果页面
          if (
            response.data.status == "finished" ||
            (response.data.studentAnswers[
              response.data.studentAnswers.length - 1
            ].audioBase64str &&
              response.data.studentAnswers[
                response.data.studentAnswers.length - 1
              ].audioBase64str.length > 0)
          ) {
            //如果是从result退回来的，看一下status，是finished就继续进入result页面
            this.OpenInCurrentPageWithCryption(
              "/audiotestitemresult",
              this.decodedQueryObject
            );
          } else {
            this.testItem = response.data;
            this.getTestMode();
            //如果是拼写测试，就不处理多义词的情况了
            if (this.test.mode != 3) this.processMultimeaningVoc();
            //初始化开始下标（有可能上一次已经测过一部分）
            this.initializeStartIndex();
            this.initializeTestCountDown();
          }
        })
        .catch((error) => {
          this.$message(error.toString());
        });
    },

    initializeDecodedQueryObject: function (testData) {
      let task = this.decodedQueryObject.task;
      //testItem也就是testData先整体赋值给decodedQueryObject，然后调整
      this.decodedQueryObject = testData;
      this.decodedQueryObject.task = task || null;
    },

    //通过cd获得模式，cd是0就代表不进行录音，或者拼写
    //无论是哪个模式，都是在handlenext之后，判断是进入录音还是进入拼写，也可能跳过录音
    getTestMode: function () {
      //如果cd不是0，就是需要回答音频；如果enType CD不是0，就是需要拼写
      if (this.testItem.countDown > 0) {
        if (this.testItem.enAnswerCountDown > 0) {
          this.test.mode = 2;
        } else {
          this.test.mode = 1;
        }
      } else {
        if (this.testItem.enAnswerCountDown > 0) {
          this.test.mode = 3;
        }
      }
    },

    //2024.3.25 重做这个方法，删除对音频的依赖，提升性能
    processMultimeaningVoc: function () {
      for (let i = 0; i < this.testItem.studentAnswers.length; i++) {
        let extraMeaningCount = this.extraMultimeaningCount(
          this.testItem.studentAnswers[i]
        );
        if (extraMeaningCount > 0) {
          // 添加一个多义词的index标记
          this.testItem.studentAnswers[i].multiMeaningIndex = 1;
          //进入多义词的时候，把拼接的答案和音频缓存到多义词开始的第一个意思
          if (
            //this.testItem.studentAnswers[i].audioBase64str &&
            this.testItem.studentAnswers[i].textConvertedByAudio
          ) {
            // this.testItem.studentAnswers[i].cachedAudioBase64Str =
            //   this.testItem.studentAnswers[i].audioBase64str;
            if (
              this.testItem.studentAnswers[i].textConvertedByAudio.endsWith(
                "|true"
              ) ||
              this.testItem.studentAnswers[i].textConvertedByAudio.endsWith(
                "|false"
              )
            ) {
              this.testItem.studentAnswers[i].cachedTextConvertedByAudio =
                this.testItem.studentAnswers[i].textConvertedByAudio;
            } else {
              this.testItem.studentAnswers[i].cachedTextConvertedByAudio =
                this.testItem.studentAnswers[i].textConvertedByAudio +
                "|" +
                this.testItem.studentAnswers[i].isCorrect;
            }
          }

          let multiStudentAnswersArray = [];
          //let multiAudioBase64str = [];
          if (
            this.testItem.studentAnswers[i].textConvertedByAudio &&
            this.testItem.studentAnswers[i].textConvertedByAudio.length > 0 &&
            this.testItem.studentAnswers[i].textConvertedByAudio.includes("&")
          ) {
            multiStudentAnswersArray =
              this.testItem.studentAnswers[i].textConvertedByAudio.split("&");
            this.testItem.studentAnswers[i].textConvertedByAudio =
              multiStudentAnswersArray[0];
          }
          // if (
          //   this.testItem.studentAnswers[i].audioBase64str &&
          //   this.testItem.studentAnswers[i].audioBase64str.length > 0 &&
          //   this.testItem.studentAnswers[i].audioBase64str.includes("&")
          // ) {
          //   multiAudioBase64str =
          //     this.testItem.studentAnswers[i].audioBase64str.split("&");
          //   this.testItem.studentAnswers[i].audioBase64str =
          //     multiAudioBase64str[0];
          // }
          for (let j = 0; j < extraMeaningCount; j++) {
            this.testItem.studentAnswers.splice(
              i + j,
              0,
              JSON.parse(JSON.stringify(this.testItem.studentAnswers[i + j]))
            );
            this.testItem.studentAnswers[i + 1 + j].multiMeaningIndex += 1;
            this.testItem.studentAnswers[i + 1 + j].textConvertedByAudio =
              multiStudentAnswersArray[1 + j];
            // this.testItem.studentAnswers[i + 1 + j].audioBase64str =
            //   multiAudioBase64str[1 + j];
          }
        }
        //新增了几个单词，所以遍历的index要跳过这几个新加的单词
        i += extraMeaningCount;
      }
    },

    extraMultimeaningCount: function (voc) {
      let multiMarker = ["②", "③", "④", "⑤", "⑥", "⑦", "⑧", "⑨"];
      // let lastMultiMeaningNumber = "";
      let extraMeaningCount = 0;
      for (let i = 0; i < multiMarker.length; i++) {
        if (voc.chineseText.includes(multiMarker[i])) {
          // lastMultiMeaningNumber = multiMarker[i];
          extraMeaningCount = i + 1;
        }
      }
      return extraMeaningCount;
    },

    initializeTestCountDown: function () {
      this.initialCountDownSetting = parseInt(this.testItem.countDown);
      this.initialEnTypeCountDownSetting = parseInt(
        this.testItem.enAnswerCountDown
      );
    },

    //2024.3.25 调整startIndex，如果是多义词，只答了其中几个意思，也跳过整个多义词
    initializeStartIndex: function () {
      console.log(this.testItem.studentAnswers);
      //要反着来，从最后一个null开始，直到碰见第一个不是null的值，就是开始的单词
      for (let i = this.testItem.studentAnswers.length - 1; i >= 0; i--) {
        if (
          this.testItem.studentAnswers[i].textConvertedByAudio &&
          this.testItem.studentAnswers[i].textConvertedByAudio.length > 0
        ) {
          //有值说明已经回答过，如果是多义词，就跳过这个多义词，如果不是，下一个就是起始位置
          while (
            i < this.testItem.studentAnswers.length - 1 &&
            this.testItem.studentAnswers[i].englishText ===
              this.testItem.studentAnswers[i + 1].englishText
          ) {
            i++;
          }
          this.currentTestingVocIndex = i + 1;
          return;
        }
      }
    },

    playAudio: function (englishText) {
      this.$playAudio(englishText, this.$refs.vocaudio);
      //播放的时候，次数变化
      this.testItem.studentAnswers[
        this.currentTestingVocIndex
      ].listeningCount -= 1;
    },

    handleAudioBtnClick: function () {
      if (
        this.testItem.studentAnswers[this.currentTestingVocIndex]
          .listeningCount > 0
      ) {
        this.playAudio(
          this.testItem.studentAnswers[this.currentTestingVocIndex].englishText
        );
        //如果mode是3，就没有录音部分，不用初始化
        if (this.test.mode != 3) {
          //如果是第一次播放音频，则先初始化recordingCard
          if (!this.test.recordingCardInitialized) {
            this.$refs.audioToTextRecorder.initializeAudioRecording();
            //第一次播放的时候，设置倒计时
            this.$refs.vocCountDown.countdown(
              this.initialCountDownSetting,
              true
            );
            this.test.recordingCardInitialized = true;
          }
        }

        //测试状态要嘛是录音，要嘛是拼写；如果是拼写就调用拼写cd，否则用录音cd
        if (this.test.isTypingEn) {
          //可以设置点击发音按钮的时候，重置倒计时到设定的秒数
          //this.$refs.vocCountDown.countdown(this.initialEnTypeCountDownSetting);
          this.$refs.EnInputBox.focus();
        } else {
          // if (this.test.mode != 3)
          //   this.$refs.vocCountDown.countdown(this.initialCountDownSetting);
          // else {
          //   this.handleNextClick();
          // }
          if (this.test.mode == 3) this.handleNextClick();
        }
      }
    },

    handleAudioToText: function (response) {
      //把音频的文本设定成学生的答案
      this.testItem.studentAnswers[
        this.currentTestingVocIndex
      ].textConvertedByAudio = response.data.join("，");
      //把音频的base64str传入当前单词的base64str
      this.testItem.studentAnswers[this.currentTestingVocIndex].audioBase64str =
        response.base64Str;
      //赋值currentTestVoc之前判定isCorrect的值
      this.isAudioTextAnswerCorrect(response.data);

      //转成文本后，在这里提交答案，1秒后执行next
      //setTimeout(() => this.implementNextBtnClick(), 1000);
      this.implementNextBtnClick();
    },

    addMark: function (studentId, vocabulary) {
      this.$store
        .dispatch("Vocabulary/markVocabulary", {
          userId: studentId,
          vocabularyId: vocabulary.vocabularyId,
          count: vocabulary.count + 1,
          marked: true,
          updatedBy: -1,
        })
        .then(() => {})
        .catch((error) => console.log(error.toString()));
    },

    isSameAsPreMulti: function () {
      //先考虑考虑多义词的情况，如果multiMeaningIndex有值，且大于1，则进入多义词判断，如果和前面写得一样，就判错，直接return，不进去下面的代码块
      if (
        this.testItem.studentAnswers[this.currentTestingVocIndex]
          .multiMeaningIndex &&
        this.testItem.studentAnswers[this.currentTestingVocIndex]
          .multiMeaningIndex > 1
      ) {
        let multiMeaningIndex =
          this.testItem.studentAnswers[this.currentTestingVocIndex]
            .multiMeaningIndex;
        for (
          let i = this.currentTestingVocIndex - multiMeaningIndex + 1;
          i < this.currentTestingVocIndex;
          i++
        ) {
          if (
            this.testItem.studentAnswers[this.currentTestingVocIndex]
              .textConvertedByAudio ==
            this.testItem.studentAnswers[i].textConvertedByAudio
          ) {
            this.testItem.studentAnswers[
              this.currentTestingVocIndex
            ].isCorrect = false;

            return true;
          }
        }
      }

      //只有找到与前面多义词一致的答案的时候，return true，并且标记为not correct；
      //循环走完仍然没有return true，说明没找到一样的答案，return false
      return false;
    },

    //begin-从AudioTestItemResult平移并调整过的检查audio答案是否正确的函数
    //检查拿到的文本，答案是否正确
    isAudioTextAnswerCorrect: function (audioTextArray) {
      //如果答案为空，则算错
      if (!audioTextArray || audioTextArray.length == 0) {
        this.testItem.studentAnswers[
          this.currentTestingVocIndex
        ].isCorrect = false;
        //错误答案就标记一下当前单词
        this.addMark(
          this.testItem.studentId,
          this.testItem.studentAnswers[this.currentTestingVocIndex]
        );
        return;
      }

      if (this.isSameAsPreMulti()) {
        //和前面答案一样了，就标记一下
        this.addMark(
          this.testItem.studentId,
          this.testItem.studentAnswers[this.currentTestingVocIndex]
        );
      }

      //去除回答中可能的语气词
      let toneParticles = [
        "啊",
        "额",
        "嘛",
        "哦",
        "吗",
        "吧",
        "喔",
        "嘻",
        "呀",
        "诶",
        "唉",
        "呐",
        "嘿",
        "唷",
        "呜",
        "喂",
        "嘘",
        "嘎",
        "嗯",
        "咦",
      ];

      audioTextArray = audioTextArray.filter((str) => {
        return !toneParticles.some((p) => str.includes(p));
      });

      //处理需要匹配的答案数组
      let originalAudioText = audioTextArray.join("");
      audioTextArray.push(originalAudioText);
      //进入单词卡“释义”中处理中文文本，匹配答案;然后拼接到要匹配的答案中
      let cnOriginalTextArray = this.generateOriginalCnTextArray(
        this.testItem.studentAnswers[this.currentTestingVocIndex].chineseText
      );
      this.testItem.studentAnswers[
        this.currentTestingVocIndex
      ].correctedAnswers = [
        ...this.testItem.studentAnswers[
          this.currentTestingVocIndex
        ].correctedAnswers.map((item) => item && item.trim()),
        ...cnOriginalTextArray,
      ];

      //过滤掉null，undefined，和空字符串
      this.testItem.studentAnswers[
        this.currentTestingVocIndex
      ].correctedAnswers = this.testItem.studentAnswers[
        this.currentTestingVocIndex
      ].correctedAnswers.filter((item) => item != null && item != "");

      //如果学生答案在未处理的情况下就有corrected answer的匹配，则直接判定为正确（为了修复有标点符号而学生答案被一分为二，而不能识别的bug，比如答案：实施，组织，在corrected answer中有匹配，但是分为
      //两个答案分别是实施，和组织，就没有匹配了
      if (
        audioTextArray.some((word) =>
          this.testItem.studentAnswers[
            this.currentTestingVocIndex
          ].correctedAnswers.includes(word)
        )
      ) {
        this.testItem.studentAnswers[
          this.currentTestingVocIndex
        ].isCorrect = true;
        return;
      }

      //如果还是没有找到匹配的答案，那就把答案和学生的回答，都转成拼音，然后匹配
      let pinyinAudioTextArray = audioTextArray.map((item) =>
        pinyin(item, { style: pinyin.STYLE_NORMAL }).join("")
      );
      let pinyinAnswersArray = this.testItem.studentAnswers[
        this.currentTestingVocIndex
      ].correctedAnswers.map((item) =>
        pinyin(item, { style: pinyin.STYLE_NORMAL }).join("")
      );
      if (
        pinyinAudioTextArray.some((word) => pinyinAnswersArray.includes(word))
      ) {
        this.testItem.studentAnswers[
          this.currentTestingVocIndex
        ].isCorrect = true;
        return;
      }

      // if (audioTextArray.some((word) => cnOriginalTextArray.includes(word))) {
      //   this.testItem.studentAnswers[
      //     this.currentTestingVocIndex
      //   ].isCorrect = true;
      //   return;
      // }

      //如果以上的分支都没有return，则此时isCorrecct应该是false，那就追加这个单词的标记
      //此处的addMark函数和普通TestItem的函数不同；这里addMark是在函数里面count+1
      this.addMark(
        this.testItem.studentId,
        this.testItem.studentAnswers[this.currentTestingVocIndex]
      );
    },

    //新版Naive多了很多中文，比如 释义，例句，备注，需要在新版格式下，找到正确答案，并匹配
    generateOriginalCnTextArray: function (text) {
      let rawCnMeaningArray = [];
      if (text.includes("释义：")) {
        rawCnMeaningArray = this.retrieveCnMeaning(text);
      } else {
        rawCnMeaningArray.push(text);
      }

      //let cnTextArray = rawCnMeaning.split(/[,，.。、;；:：?？!！ ]/);
      let resultCnArray = [];
      for (let j = 0; j < rawCnMeaningArray.length; j++) {
        let cnTextArray = rawCnMeaningArray[j].split(/[,，.。、;；:：?？!！ ]/);
        for (let i = 0; i < cnTextArray.length; i++) {
          cnTextArray[i] = cnTextArray[i].trim();
          if (this.isChinese(cnTextArray[i])) {
            resultCnArray.push(cnTextArray[i].replace("…", ""));
            if (cnTextArray[i].includes("的"))
              resultCnArray.push(
                cnTextArray[i].replace("…", "").replace("的", "")
              );
            if (cnTextArray[i].includes("地"))
              resultCnArray.push(
                cnTextArray[i].replace("…", "").replace("地", "")
              );
          }
        }
      }

      return resultCnArray;
    },

    //从新版Naive中获取中文释义
    retrieveCnMeaning: function (text) {
      let substr = "释义：";
      let indices = [];
      for (const match of text.matchAll(new RegExp(substr, "g"))) {
        indices.push(match.index + "释义：".length);
      }
      let result = [];
      for (let i = 0; i < indices.length; i++) {
        const start = indices[i];
        const end = text.indexOf("<", start);
        result.push(text.substring(start, end));
      }
      return result;
    },
    //从新版Naive中获取中文释义

    isChinese: function (text) {
      //以前的regex必须完全匹配中文，包含标点就不算中文；换一个初步测试更合适的regex
      //let re = /^[\u4e00-\u9fa5]+$/;
      let re =
        /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/g;
      if (re.test(text)) return true;
      return false;
    },
    //end-从AudioTestItemResult平移并调整过的检查audio答案是否正确的函数

    postVocStudentTestVocabularyAnswer: function (postData) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("StudentTest/editVocStudentTestVocabularyAnswer", postData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            this.$message("词汇未提交，请检查网络是否稳定");
            reject(error);
          });
      });
    },

    //多义词标签，id是1，deleted用于判断是否有这个标签
    tagVoc: function (vocabularyId, studentId) {
      let postData = {
        vocabularyId: vocabularyId,
        userId: studentId,
        vocTagId: 1,
        deleted: false,
      };
      this.$store
        .dispatch("Vocabulary/tagVocabulary", postData)
        .then(() => {})
        .catch((error) => console.log(error.toString()));
    },

    // printText: function (text) {
    //   if (!text) return;
    //   //let textArray = text.split('');
    // },

    // //每0.1秒输出一个字符，直到输出完完整内容
    // printingInterval: function (str) {
    //   let index = 0;
    //   const intervalId = setInterval(() => {
    //     //console.log(str.substr(0, index + 1));
    //     this.displayingAudioText = str.substr(0, index + 1);
    //     index++;
    //     if (index === str.length) {
    //       clearInterval(intervalId);
    //     }
    //   }, 100);
    // },

    handleCountDownFinish: function () {
      //倒计时结束先进入下个单词；进入之前需要录音识别和答案提交
      //录音停止，提交答案、to be continued

      //进入下个单词
      this.handleNextClick();
    },

    enterTypeEn: function () {
      if (!this.test.isTypingEn) {
        //如果mode是包含拼写的，且isTypingEn是false，就不进入下一个单词，而是进入拼写倒计时，再来一次countdown
        this.test.isTypingEn = true;
        this.$refs.vocCountDown.countdown(
          this.initialEnTypeCountDownSetting,
          true
        );
        //显示拼写的inputbox... 获得焦点(nextTick保证在dom内容加载完成后执行focus)
        this.$nextTick(() => {
          this.$refs.EnInputBox.focus();
        });
      } else {
        this.processNextAudioItem();
        this.test.isTypingEn = false;
        // update english answer
        //reset currentEnType
        this.test.currentEnType = "";
      }
    },

    handleNextClick: function () {
      //根据当前RecordingCard的录音组件状态来判断是否直接进入下一个单词
      //如果正在录，则停止录制，从RecordingCard拿到文本之后，执行next，在handleAudioToText函数中
      //如果没有在录，则执行Next
      if (this.test.isRecording) {
        this.$refs.audioToTextRecorder.handleRecordingBtnClick();
      } else {
        this.implementNextBtnClick();
      }
    },

    implementNextBtnClick: function () {
      // if (
      //   this.currentTestingVocIndex <
      //   this.testItem.studentAnswers.length - 1
      // ) {
      //1是录音，2是录音+拼写，3是拼写
      if (this.test.mode == 1) {
        this.processNextAudioItem();
      } else if (this.test.mode == 2) {
        this.enterTypeEn();
      } else if (this.test.mode == 3) {
        this.enterTypeEn();
      }
      //} else {
      // //最后一个词也是先提交最后一个词的数据
      // //当前的词汇
      // let currentTestVoc =
      //   this.testItem.studentAnswers[this.currentTestingVocIndex];

      // //如果这个词是多义词的至少第二个意思开始，结合上一个多义词的意思，一起更新
      // //第一个意思的index是1，大于1说明至少是第二个意思
      // if (
      //   currentTestVoc.multiMeaningIndex &&
      //   currentTestVoc.multiMeaningIndex > 1
      // ) {
      //   //处理多义词的临时更新
      //   let preVoc =
      //     this.testItem.studentAnswers[this.currentTestingVocIndex - 1];
      //   //如果多义词的第一个意思有cache，就直接启用，否则再按正常逻辑走
      //   let multiVocFirst =
      //     this.testItem.studentAnswers[
      //       this.currentTestingVocIndex - currentTestVoc.multiMeaningIndex + 1
      //     ];
      //   if (
      //     multiVocFirst.cachedAudioBase64Str &&
      //     multiVocFirst.cachedAudioBase64Str.length > 0
      //   ) {
      //     currentTestVoc.textConvertedByAudio =
      //       multiVocFirst.cachedTextConvertedByAudio +
      //       "&" +
      //       currentTestVoc.textConvertedByAudio +
      //       "|" +
      //       currentTestVoc.isCorrect;
      //     currentTestVoc.audioBase64str =
      //       multiVocFirst.cachedAudioBase64Str +
      //       "&" +
      //       currentTestVoc.audioBase64str;
      //     //使用完缓存后，把缓存清空
      //     multiVocFirst.cachedAudioBase64Str = null;
      //     multiVocFirst.cachedTextConvertedByAudio = null;
      //   } else {
      //     //如果末尾有true or false，说明isCorrect的数据已经写入convertedText
      //     if (
      //       preVoc.textConvertedByAudio.endsWith("|true") ||
      //       preVoc.textConvertedByAudio.endsWith("|false")
      //     ) {
      //       currentTestVoc.textConvertedByAudio =
      //         preVoc.textConvertedByAudio +
      //         "&" +
      //         currentTestVoc.textConvertedByAudio +
      //         "|" +
      //         currentTestVoc.isCorrect;
      //     } else {
      //       currentTestVoc.textConvertedByAudio =
      //         preVoc.textConvertedByAudio +
      //         "|" +
      //         preVoc.isCorrect +
      //         "&" +
      //         currentTestVoc.textConvertedByAudio +
      //         "|" +
      //         currentTestVoc.isCorrect;
      //     }
      //     currentTestVoc.audioBase64str =
      //       preVoc.audioBase64str + "&" + currentTestVoc.audioBase64str;
      //   }
      // }
      // this.postVocStudentTestVocabularyAnswer({
      //   id: currentTestVoc.id,
      //   listeningCount: currentTestVoc.listeningCount,
      //   audioBase64str: currentTestVoc.audioBase64str,
      //   textConvertedByAudio: currentTestVoc.textConvertedByAudio,
      //   englishStudentAnswer: this.test.currentEnType,
      //   isCorrect: currentTestVoc.isCorrect,
      // });

      // if (this.test.mode == 1) {
      //   //更新测试状态，主要是finished
      //   this.submit();
      // } else if (this.test.mode == 2 || this.test.mode == 3) {
      //   if (!this.test.isTypingEn) {
      //     //如果mode是包含拼写的，且isTypingEn是false，就不进入下一个单词，而是进入拼写倒计时，再来一次countdown
      //     this.test.isTypingEn = true;
      //     this.$refs.vocCountDown.countdown(
      //       this.initialEnTypeCountDownSetting
      //     );
      //     //显示拼写的inputbox...
      //   } else {
      //     this.submit();
      //     this.test.isTypingEn = false;
      //   }
      // }
      //}
    },

    processNextAudioItem: function () {
      //进入下一个单词之前，提交当前单词的答案
      //当前的词汇
      let currentTestVoc =
        this.testItem.studentAnswers[this.currentTestingVocIndex];
      currentTestVoc.englishStudentAnswer = this.test.currentEnType;

      //如果这个词是多义词的至少第二个意思开始，结合上一个多义词的意思，一起更新
      //第一个意思的index是1，大于1说明至少是第二个意思
      if (
        currentTestVoc.multiMeaningIndex &&
        currentTestVoc.multiMeaningIndex > 1
      ) {
        //处理多义词的临时更新
        let preVoc =
          this.testItem.studentAnswers[this.currentTestingVocIndex - 1];
        //如果多义词的第一个意思有cache，就直接启用，否则再按正常逻辑走
        let multiVocFirst =
          this.testItem.studentAnswers[
            this.currentTestingVocIndex - currentTestVoc.multiMeaningIndex + 1
          ];
        if (
          multiVocFirst.cachedAudioBase64Str &&
          multiVocFirst.cachedAudioBase64Str.length > 0
        ) {
          currentTestVoc.textConvertedByAudio =
            multiVocFirst.cachedTextConvertedByAudio +
            "&" +
            currentTestVoc.textConvertedByAudio +
            "|" +
            currentTestVoc.isCorrect;
          currentTestVoc.audioBase64str =
            multiVocFirst.cachedAudioBase64Str +
            "&" +
            currentTestVoc.audioBase64str;
          //使用完缓存后，把缓存清空
          multiVocFirst.cachedAudioBase64Str = null;
          multiVocFirst.cachedTextConvertedByAudio = null;
        } else {
          //如果末尾有true or false，说明isCorrect的数据已经写入convertedText
          if (
            preVoc.textConvertedByAudio.endsWith("|true") ||
            preVoc.textConvertedByAudio.endsWith("|false")
          ) {
            currentTestVoc.textConvertedByAudio =
              preVoc.textConvertedByAudio +
              "&" +
              currentTestVoc.textConvertedByAudio +
              "|" +
              currentTestVoc.isCorrect;
          } else {
            currentTestVoc.textConvertedByAudio =
              preVoc.textConvertedByAudio +
              "|" +
              preVoc.isCorrect +
              "&" +
              currentTestVoc.textConvertedByAudio +
              "|" +
              currentTestVoc.isCorrect;
          }
          currentTestVoc.audioBase64str =
            preVoc.audioBase64str + "&" + currentTestVoc.audioBase64str;
        }

        //多义词更新的时候，如果答错了，给多义词标签
        //该标签应该不允许取消，因为如果全对就取消，可能会取消掉以前多义词的标签
        if (!currentTestVoc.isCorrect) {
          this.tagVoc(currentTestVoc.vocabularyId, this.testItem.studentId);
        }
      }

      this.postVocStudentTestVocabularyAnswer({
        id: currentTestVoc.id,
        listeningCount: currentTestVoc.listeningCount,
        audioBase64str: currentTestVoc.audioBase64str,
        textConvertedByAudio: currentTestVoc.textConvertedByAudio,
        englishStudentAnswer: this.test.currentEnType,
        isCorrect: currentTestVoc.isCorrect,
      });
      //englishStudentAnswer

      //如果不是最后一个词，就进入下一个单词，否则submit提交测试，测试结束

      if (
        this.currentTestingVocIndex <
        this.testItem.studentAnswers.length - 1
      ) {
        //进入下一个单词
        this.currentTestingVocIndex += 1;
        //先自动播放一次音频
        this.handleAudioBtnClick();

        if (this.test.mode == 1 || this.test.mode == 2) {
          //重置语音倒计时
          this.$refs.vocCountDown.countdown(this.initialCountDownSetting, true);
          //自动点击录制按钮
          this.$refs.audioToTextRecorder.handleRecordingBtnClick();
        } else {
          //重置拼写倒计时; 先重制成语音的倒计时0，以便跳过语音部分
          this.$refs.vocCountDown.countdown(this.initialCountDownSetting, true);
        }
      } else {
        if (this.test.mode == 1) {
          //更新测试状态，主要是finished
          this.submit();
        } else if (this.test.mode == 2 || this.test.mode == 3) {
          if (!this.test.isTypingEn) {
            //如果mode是包含拼写的，且isTypingEn是false，就不进入下一个单词，而是进入拼写倒计时，再来一次countdown
            this.test.isTypingEn = true;
            this.$refs.vocCountDown.countdown(
              this.initialEnTypeCountDownSetting,
              true
            );
            //显示拼写的inputbox...
          } else {
            this.submit();
            this.test.isTypingEn = false;
          }
        }
      }
    },

    submit: function () {
      //加入submitted，以便跳转的result页面可以知道是从前一个test页面submit过来的
      this.decodedQueryObject.submitted = true;
      this.finalUpdateTestStatus();
      // if (this.test.mode != 3) {
      //   //定义一个requests 数组来存发出的所有多义词更新请求
      //   let requests = [];
      //   //找出多义词，拼接学生写过的所有意思，用&，然后提交一次
      //   for (
      //     let answerLoopIndex = 0;
      //     answerLoopIndex < this.testItem.studentAnswers.length;
      //     answerLoopIndex++
      //   ) {
      //     let extraMeaningCount = this.extraMultimeaningCount(
      //       this.testItem.studentAnswers[answerLoopIndex]
      //     );
      //     if (extraMeaningCount >= 1) {
      //       this.testItem.studentAnswers[
      //         answerLoopIndex
      //       ].textConvertedByAudio +=
      //         "|" + this.testItem.studentAnswers[answerLoopIndex].isCorrect;

      //       for (let j = 0; j < extraMeaningCount; j++) {
      //         // 合并音频base64 string
      //         this.testItem.studentAnswers[answerLoopIndex].audioBase64str =
      //           this.testItem.studentAnswers[answerLoopIndex].audioBase64str +
      //           "&" +
      //           this.testItem.studentAnswers[answerLoopIndex + j + 1]
      //             .audioBase64str;

      //         // 合并音频转出的文本

      //         this.testItem.studentAnswers[
      //           answerLoopIndex
      //         ].textConvertedByAudio =
      //           this.testItem.studentAnswers[answerLoopIndex]
      //             .textConvertedByAudio +
      //           "&" +
      //           this.testItem.studentAnswers[answerLoopIndex + j + 1]
      //             .textConvertedByAudio +
      //           "|" +
      //           this.testItem.studentAnswers[answerLoopIndex + j + 1].isCorrect;
      //       }

      //       //如果到达多义词的最后一个分身，就update一下学生提交的答案
      //       //提交该单词学生的答案
      //       //当前的词汇
      //       let currentTestVoc = this.testItem.studentAnswers[answerLoopIndex];
      //       let request = this.postVocStudentTestVocabularyAnswer({
      //         id: currentTestVoc.id,
      //         listeningCount: currentTestVoc.listeningCount,
      //         audioBase64str: currentTestVoc.audioBase64str,
      //         textConvertedByAudio: currentTestVoc.textConvertedByAudio,
      //         englishStudentAnswer: currentTestVoc.englishStudentAnswer,
      //         isCorrect: currentTestVoc.isCorrect,
      //       }).then(() => {
      //         let requestIndex = requests.indexOf(request);
      //         if (requestIndex > -1) {
      //           requests.splice(requestIndex, 1);
      //         }

      //         if (requests.length === 0) {
      //           //等所有的多义词都拿到response之后，更新status，再调整到result页面，防止在多义词requests更新期间拿到不完整数据
      //           this.finalUpdateTestStatus();
      //         }
      //       });
      //       requests.push(request);

      //       answerLoopIndex += extraMeaningCount;
      //     }
      //   }
      // }
    },

    finalUpdateTestStatus: function () {
      //点击submit的时候，先提交一个overview，拿到overview id，然后再处理后续的student test status以及答案的校验
      this.addStudentOverview()
        .then(() => {
          this.$store
            .dispatch("StudentTest/updateStudentTestStatus", {
              id: this.testItem.id,
              studentId: this.testItem.studentId,
              name: this.testItem.name,
              type: this.testItem.type,
              origin: this.testItem.origin,
              nirvanaCount: 0,
              description: this.testItem.description || "",
              status: "finished",
              published: true,
            })
            .then((response) => {
              if (response.data != -1)
                //这里不需要getTestById了，因为进入result页面后，mounted方法会getTestById
                // this.getTestById(
                //   this.decodedQueryObject.id,
                //   this.decodedQueryObject.studentId
                // );
                this.submitted = true;
              this.OpenInCurrentPageWithCryption(
                "/audiotestitemresult",
                this.decodedQueryObject
              );
              //测试结束，传入当前测试testItem的对象，进入result页面
              // setTimeout(() => {
              //   this.OpenInCurrentPageWithCryption(
              //     "/audiotestitemresult",
              //     this.decodedQueryObject
              //   );
              // }, 3000);
              // console.log("进入结果页面")
              // this.OpenInCurrentPageWithCryption(
              //     "/audiotestitemresult",
              //     this.decodedQueryObject
              //   );
            })
            .catch((error) => this.$message(error.toString()));
        })
        .catch((error) => this.$message(error.toString()));
    },

    addStudentOverview: function () {
      return new Promise((resolve, reject) => {
        let postData = {
          studentId: this.testItem.studentId,
          vocTestId: this.testItem.id,
          learningMode: "语音测试",
          checkingMode: this.testItem.type.toString(),
          vocSetNames: this.testItem.origin,
        };

        this.$store
          .dispatch("StudentOverview/addVocStudentOverview", postData)
          .then((response) => {
            if (response.data != -1) {
              this.currentOverviewId = response.data;
              resolve(response);
            }
          })
          .catch((error) => {
            console.log(error.toString());
            reject(error);
          });
      });
    },

    addStudentOverviewDetail: function () {
      for (let i = 0; i < this.testItem.studentAnswers.length; i++) {
        this.upsertVocStudentOverviewDetail(
          this.currentOverviewId,
          this.testItem.studentAnswers[i].vocabularyId,
          this.testItem.studentAnswers[i].isCorrect
        );
      }
    },

    OpenInCurrentPageWithCryption: function (routeName, query) {
      query = JSON.stringify(query);
      query = window.encodeURIComponent(query);
      this.$router.push({
        path: routeName,
        query: { encodedString: window.btoa(query) },
      });
    },

    handleAudioTestKeyUp: function (event) {
      //回车，提前当前回答
      switch (event.keyCode) {
        case 13:
          //进入下个单词
          this.handleNextClick();
          break;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div#outsideContainer {
  font: bold 24pt sans;
  color: deepskyblue;
}

.test-progress {
  font: 500 18pt sans;
}

.card-wrapper {
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  margin-top: 10%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.card-wrapper > * {
  flex: 1 100%;
}

.card-content {
  border-radius: 5px 5px 0 0;
  margin-left: 19%;
  margin-right: 19%;
  background-color: white;
  box-shadow: 0 0.25rem 1rem 0 rgba(48, 53, 69, 0.08);
  height: 400px;
}

.card-items {
  margin-top: 1.5rem;
}

.next-btn {
  margin-top: 2rem;
}

.test-audio-icon {
  cursor: pointer;
  color: orange;
}

.test-item {
  margin-top: 2rem;
}

.count-down-text {
  font: bold 28pt sans;
}

.test-listening-count {
  font: 100 12pt sans;
}

.audio-text {
  color: rgb(189, 122, 246, 0.8);
  font: 600 20pt sans;
}
</style>
