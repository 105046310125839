<template>
  <div id="addManyVocDrawer">
    <el-drawer
      v-model="drawer"
      @open="handleOpen"
      @close="handleClose"
      direction="ttb"
      size="100%"
    >
      <el-container id="addManyVocContainer">
        <el-main>
          <el-row :gutter="20">
            <el-col :span="18">
              <el-input v-model="setName" placeholder="词汇集名称">
                <template #prepend>词汇集名称(*)</template>
              </el-input>
            </el-col>

            <el-col :offset="2" :span="4">
              <el-button
                type="primary"
                @click="handleAddSet"
                :disabled="addSetButtonDisabled"
              >
                创建集合
                <i class=""></i>
              </el-button>
            </el-col>
          </el-row>
          <el-divider />

          <voc-add-many-content
            @on-voc-list-updated="handleOnVocListUpdated($event)"
          />
        </el-main>
      </el-container>
    </el-drawer>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import VocAddManyContent from "./VocAddManyContent.vue";
export default {
  name: "VocAddManyDrawer",

  components: {
    VocAddManyContent,
  },

  watch: {
    modelValue: function () {
      this.drawer = this.modelValue;
    },
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      drawer: false,
      textarea: "",
      splitChar: " ",
      setName: "",
      vocList: [],
      addSetButtonDisabled: false,
    };
  },
  mounted: function () {},
  methods: {
    handleClose: function () {
      this.setName = ""; //reset name
      this.$emit("on-voc-add-many-drawer-closed");
    },

    //clear set vuex data when this drawer is open
    handleOpen: function () {
      console.log("drawer open");
      this.$store.commit("Set/mutateAddedSet", {
        setId: null,
        setName: null,
      });
    },

    handleOnVocListUpdated: function (vocList) {
      console.log(vocList);
      this.vocList = vocList;
    },

    handleAddSet: function () {
      this.$store
        .dispatch("Set/add", {
          userId: localStorage.getItem("targetId"),
          name: this.setName,
        })
        .then(() => {
          ElMessage({
            message: "添加集合.",
            type: "success",
          });
          this.addSetButtonDisabled = true;

          //this.addSetToSubject(response.data, this.addedSubject.subjectId);
        })
        .catch((error) => console.log(error));
    },

    // handleAddManyVoc: function () {
    //   // send the whole voc lists patch to upper component
    //   this.$emit("on-voc-add-many-process", this.vocList);
    // },
  },
};
</script>

<style scoped>
#addManyVocContainer {
  margin-left: 5%;
  margin-right: 5%;
}

.el-input__inner {
  outline: none;
  border: 0 none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
}

.el-drawer.ttb {
  overflow: auto !important;
}

p.introduction {
  color: deepskyblue;
  font-size: 24px;
}
</style>
