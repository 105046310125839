<template>
  <div>
    <el-drawer
      title="All Rise"
      v-model="studentListDrawer"
      direction="ltr"
      size="20%"
    >
      <el-table
        ref="studentListTable"
        :data="
          boundStudentList.filter(
            (data) =>
              !search ||
              data.targetName.toLowerCase().includes(search.toLowerCase())
          )
        "
        highlight-current-row
        @current-change="onStudentClick"
        style="width: 100%; margin-left: 5%"
      >
        <el-table-column prop="targetName">
          <template #header>
            <el-input v-model="search" size="small" placeholder="search" />
          </template>
        </el-table-column>
        <el-table-column label="中文名" prop="chineseName"></el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>

export default {
  name: "BoundStudentListDrawer",
  props: [],
  watch: {
    modelValue: function () {
      this.studentListDrawer = this.modelValue;
    },
  },
  data() {
    return {
      currentUserId: "",
      isAdminOrTeacher: null,
      studentListDrawer: false,
      sentenceIndex: 0,
      boundStudentList: [],
      search: "",
    };
  },
  mounted: function () {
    this.setPermission();
    this.currentUserId = localStorage.getItem("userId"); // get current user Id

    this.$store
      .dispatch("Admin/getRelationship", {
        userId: this.currentUserId,
      })
      .then((response) => {
        this.boundStudentList = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    openDrawer: function() {
      this.studentListDrawer = true;
    },

    onStudentClick: function (targetInfo) {
      if (targetInfo === null) return;
      localStorage.setItem("targetId", targetInfo.targetId);
      localStorage.setItem("targetCNName", targetInfo.chineseName);

      this.$emit("on-bound-student-click", targetInfo);
      this.studentListDrawer = false;

      this.$refs.studentListTable.setCurrentRow();
    },
    setPermission: function () {
      if (
        localStorage.getItem("userRole") === "Admin" ||
        localStorage.getItem("userRole") === "Teacher"
      )
        this.isAdminOrTeacher = true;
      else this.isAdminOrTeacher = false;
    },
  },
};
</script>

<style>
.el-drawer.rtl {
  overflow: auto !important;
}

.el-drawer.ltr {
  overflow: auto !important;
}

.allSentencesInsideDrawer {
  cursor: pointer;
  padding-left: 8px;
}

.boundStudentsInsideDrawer {
  padding-left: 10%;
  cursor: pointer;
}

.hightlightCurrentText {
  color: deepskyblue;
}

</style>
