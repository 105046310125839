let EDITORINIT = {
  height: 300,
  menubar: false,
  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code help wordcount",
  ],
  toolbar:
    "undo redo | formatselect | bold italic | \
    forecolor backcolor emoticons | removeformat | help",
};

export default {
  EDITORINIT,
};
