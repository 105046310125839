<!-- 在plan下同时添加detail和tasks所需要的组建 -->
<!-- 主要用于定制detail的名字 -->
<template>
  <div>
    <el-dialog
      width="50%"
      v-model="dialogFormVisible"
      title="创建计划实施和任务"
      @open="handleOpen"
      @close="handleClose"
    >
      <!-- start - plan detail form -->
      <el-form-item label="计划实施名称">
        <el-input
          v-model="planDetailForm.name"
          placeholder="请输入计划名称"
          @keydown.stop
          disabled
        >
        </el-input>
      </el-form-item>

      <el-form-item label="天数">
        <el-input-number
          v-model="planDetailForm.totalDays"
          size="small"
          @keydown.stop
          placeholder="请输入该计划总天数"
        />
      </el-form-item>

      <div style="text-align: left">
        <span>描述</span>
      </div>
      <quill-editor v-model:value="planDetailForm.description" />

      <el-form-item label="跨度">
        <el-date-picker
          v-model="planDetailForm.daterange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="状态">
        <el-select v-model="planDetailForm.status" placeholder="请选择">
          <el-option
            v-for="item in planDetailForm.statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- end - plan detail form -->

      <el-divider
        ><el-icon><star-filled /></el-icon
      ></el-divider>

      <el-form-item label="任务名称">
        <el-input v-model="form.name" placeholder="请输入任务名称"> </el-input>
      </el-form-item>

      <el-form-item label="背诵日期">
        <el-date-picker
          v-model="form.proceedDate"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item v-if="projects" label="项目">
        <el-select
          v-model="form.project"
          placeholder="请选择"
          @change="handleProjectSelect"
        >
          <el-option
            v-for="item in projects"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
            <span>{{ item.name }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="projects && form.project" label="科目">
        <el-select
          @change="handleSubjectChange"
          v-model="form.subjectObject"
          multiple
          placeholder="请选择"
        >
          <el-option
            v-for="item in projects.find((item) => item.name == form.project)
              .vocSubjects"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <span>{{ item.name }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="集合">
        <el-input
          v-model="form.setName"
          placeholder="选择科目后选择集合"
          disabled
        >
        </el-input>
      </el-form-item>

      <el-form-item label="步骤(注意选择顺序)">
        <div class="tags-check-group">
          <el-checkbox-group
            v-model="form.checkedSetProcesses"
            @change="handleCheckedProcessChange"
          >
            <el-checkbox
              v-for="(process, index) in form.setProcessOptions"
              :key="index"
              :label="process.id"
              >{{ process.name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </el-form-item>

      <el-form-item label="范围" v-if="form.showRange">
        <el-row>
          <el-col :span="11">
            <el-input-number v-model="form.from" size="small" />
          </el-col>
          <el-col :span="2"> - </el-col>
          <el-col :span="11">
            <el-input-number v-model="form.to" size="small" />
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="任务类型">
        <el-radio-group
          v-model="form.studyType"
          @change="handleStudyTypeRadioGroupChange($event)"
        >
          <el-radio :label="1">盲筛</el-radio>
          <el-radio :label="2">背诵</el-radio>
          <el-radio :label="3">星标</el-radio>
          <el-radio :label="4">复习</el-radio>
          <el-radio :label="5">测试</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="轮数">
        <el-input-number
          v-model="form.roundNum"
          size="small"
          @keydown.stop
          @change="handleRoundNumberChange"
          placeholder="请输入该计划的轮数"
        />
      </el-form-item>

      <el-form-item label="模式">
        <el-radio-group
          v-model="form.answer"
          @change="handleRadioGroupChange($event)"
        >
          <el-radio :label="1">英翻中</el-radio>
          <el-radio :label="4">英翻中(仅音频)</el-radio>
          <el-radio :label="2">中翻英</el-radio>
          <el-radio :label="3">显示中英文</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="通过标准">
        <el-input-number v-model="form.criterion" size="small" />
      </el-form-item>

      <div style="text-align: left">
        <span>评价</span>
      </div>
      <quill-editor v-model:value="form.evaluation" />

      <el-form-item label="切分任务">
        <el-switch
          v-model="splitTask.splitted"
          active-color="#13ce66"
          inactive-color="gray"
        />
      </el-form-item>

      <el-form-item v-if="splitTask.splitted" label="按集合数量切分">
        <el-input-number
          v-model="splitTask.span"
          size="small"
          @change="handleSplitTaskNumberChange"
        />
      </el-form-item>

      <el-form-item v-if="splitTask.splitted" label="按任务数量切分">
        <el-input-number v-model="splitTask.rangeNumber" size="small" />
      </el-form-item>

      <el-form-item v-if="splitTask.rangeNumber > 0" label="词汇数量">
        <el-input-number
          v-model="splitTask.rangeSpan"
          size="small"
          @change="handleSplitTaskRangeNumberChange"
        />
      </el-form-item>

      <el-form-item v-if="splitTask.splitted" label="已切分任务">
        <el-input
          v-for="(task, index) in splitTask.tasks"
          :key="task"
          v-model="splitTask.tasks[index]"
          placeholder="选择科目后选择集合"
          disabled
        >
        </el-input>
      </el-form-item>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onCreatePlanDetailAndTaskCancel()"
            >Cancel</el-button
          >
          <el-button type="primary" @click="onCreatePlanDetailAndTaskConfirm()"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 点击其他subject之后出现sets列表 -->
    <el-dialog v-model="dialogSetListVisible">
      <el-row>
        <el-col :span="12">
          <el-input
            v-model="setNameSearchString"
            placeholder="此处输入集合名称来过滤集合"
          ></el-input>
        </el-col>
        <el-col :offset="5" :span="2">
          <el-input v-model.number="form.setRangeFrom"></el-input>
        </el-col>
        <el-col :span="1"> </el-col>
        <el-col :span="2">
          <el-input v-model.number="form.setRangeTo"></el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="handleSetRange">Range</el-button>
        </el-col>
      </el-row>
      <el-checkbox
        v-model="checkAllSets"
        :indeterminate="isIndeterminate"
        @change="handleCheckAllChange"
        >Check all</el-checkbox
      >
      <div class="set-check-box-group">
        <el-checkbox-group
          v-model="checkedSets"
          @change="handleCheckedSetsChange"
        >
          <el-checkbox
            v-for="set in setList.filter(
              (data) =>
                !setNameSearchString ||
                data.name
                  .toLowerCase()
                  .includes(setNameSearchString.toLowerCase())
            )"
            :key="set.name"
            :label="set.id"
            >{{ set.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogSetListVisible = false">Cancel</el-button>
          <el-button type="primary" @click="handleSetListDialogConfirm"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 点击其他subject之后出现sets列表 -->
  </div>
</template>

<script>
import { SETPROCESSOPTIONS } from "../../../setting/setProcess.js";
export default {
  name: "CreatePlanDetailAndTaskForm",
  components: {},

  props: ["modelValue", "studentPlanId"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  mounted: function () {
    //mounted的时候直接拿projects，因为这个窗口肯定要用到
    this.getProjects();
  },

  data() {
    return {
      dialogFormVisible: false,
      description: "",
      projects: null, //获得当前的教材，只读
      setList: [],
      dialogSetListVisible: false,
      setNameSearchString: "",
      checkAllSets: false,
      checkedSets: [],
      isIndeterminate: true,

      planDetailForm: {
        studentPlanId: 0,
        name: "",
        description: "",
        evaluation: "",
        daterange: null,
        startDate: null,
        finishDate: null,
        totalDays: 0,
        status: "开始",
        statusOptions: [
          {
            value: 1,
            label: "开始",
          },
          {
            value: 2,
            label: "进行中",
          },
          {
            value: 3,
            label: "结束",
          },
        ],
        followUp: "",
        isEditing: false,
      },

      form: {
        studentPlanDetailId: 0,
        name: "",
        proceedDate: null,
        project: "",
        selectedProject: null,
        subjectObject: "", // 这个实际上是选中的所有subject对象
        subjectName: "", //储存选中的科目名字，传出去备用
        setName: "",
        setIds: [],
        setProcessOptions: [],
        setProcesses: [],
        setRangeFrom: 0,
        setRangeTo: 0,
        checkedSetProcesses: [],
        showRange: false,
        from: 0,
        to: 0,
        showAnswerMode: false,
        answer: 4, //进入单词卡后的显示模式
        studyType: 0,
        reviewTurn: 0,
        examMode: 0,
        criterion: 90,
        state: 0,
        evaluation: "",
        comment: "",
        roundNum: 0,
      },

      splitTask: {
        splitted: false,
        span: 0,
        rangeNumber: 0,
        rangeSpan: 0,
        tasks: [],
      },
    };
  },

  methods: {
    //获得当前的教材projects，里面包含每个project的subjects；vocSubjects数组
    getProjects: function () {
      this.$store
        .dispatch("Project/getProject", {
          userId: localStorage.getItem("targetId"),
          type: 0,
        })
        .then((response) => {
          this.projects = response.data;
        });
    },

    onCreatePlanDetailAndTaskCancel: function () {
      this.dialogFormVisible = false;
      this.$emit("on-plan-form-detail-and-task-cancel");
    },
    onCreatePlanDetailAndTaskConfirm: function () {
      //如果一些必填项没有填，就提示
      if (
        !(
          this.planDetailForm.name &&
          this.form.project &&
          this.form.subjectName &&
          this.form.setIds.length > 0
        )
      ) {
        this.$message("请检查必填项~");
        return;
      }
      //先创建detail
      this.planDetailForm.startDate = this.planDetailForm.daterange[0];
      this.planDetailForm.finishDate = this.planDetailForm.daterange[1];
      this.planDetailForm.studentPlanId = this.studentPlanId;
      let postData = this.planDetailForm;
      postData.createdBy = localStorage.getItem("userId");
      this.$store
        .dispatch("StudentPlan/addOrEditStudentPlanDetail", postData)
        .then((response) => {
          if (response.data > 0) {
            //返回成功
            postData.id = response.data;
            this.$emit("on-plan-form-detail-confirm", postData);
            this.$message("已添加plan detail～");
            //再创建task
            this.createTask();
          } else {
            this.$message("Something Wrong~");
          }
        });
    },

    createTask: function () {
      //如果没有切分任务，就直接生成，否则用createSplittedTasks
      if (!this.splitTask.splitted) {
        let postData = this.form;
        postData.studentPlanDetailId = this.planDetailForm.id;
        postData.teacherId = localStorage.getItem("userId");
        postData.createdBy = localStorage.getItem("userId");
        postData.subject = this.form.subjectName;
        postData.setIds = this.form.setIds.join(",");
        postData.setProcesses = this.form.checkedSetProcesses.join(",");
        postData.selectedProject = null; // 这个对象太重了，可能引发400 bad request
        postData.examMode = this.form.answer;
        this.$store
          .dispatch("StudentPlan/addOrEditStudentPlanDetailTask", postData)
          .then((response) => {
            if (response.data > 0) {
              postData.id = response.data;
              this.$emit("on-plan-form-detail-and-task-confirm", {
                formData: postData,
                formDetail: this.planDetailForm,
              });

              this.dialogFormVisible = false;
            }
          });
      } else {
        if (this.splitTask.span > 0) {
          this.createSplittedTasks();
        } else {
          if (this.splitTask.rangeNumber > 0) this.createSplittedTasksByRange();
        }
      }
    },

    createSplittedTasks: function () {
      //增加获得response时候的计数
      //let taskPostedCount = 0;
      this.wrappedPostSplittedTasks();
    },

    wrappedPostSplittedTasks: function () {
      let _localThis = this;
      (function postSplittedTasks(index) {
        if (index < _localThis.splitTask.tasks.length) {
          let postData = {};
          postData = JSON.parse(JSON.stringify(_localThis.form));
          //let postData = this.form;
          postData.studentPlanDetailId = _localThis.planDetailForm.id;
          postData.teacherId = localStorage.getItem("userId");
          postData.createdBy = localStorage.getItem("userId");
          postData.subject = _localThis.form.subjectName;
          //postData.setIds = this.form.setIds.join(",");
          postData.setProcesses = _localThis.form.checkedSetProcesses.join(",");
          postData.selectedProject = null; // 这个对象太重了，可能引发400 bad request
          postData.examMode = _localThis.form.answer;
          postData.setIds = _localThis.splitTask.tasks[index].join(",");
          postData.setName = _localThis.setList
            .filter((item) => postData.setIds.includes(item.id))
            .map((item) => item.name)
            .join("-");

          _localThis.$store
            .dispatch("StudentPlan/addOrEditStudentPlanDetailTask", postData)
            .then((response) => {
              if (response.data > 0) {
                postData.id = response.data;
                _localThis.$emit("on-task-response", postData);
                postSplittedTasks(index + 1);
              }
            });
        } else {
          // No more alternative sources, reject the promise
          // _localThis.$emit("all-tasks-posted");
          _localThis.dialogFormVisible = false;
          //全部post结束后，分发事件
          _localThis.$emit("on-plan-form-detail-and-task-confirm");
        }
      })(0);
    },

    createSplittedTasksByRange: function () {
      let _localThis = this;
      (function postSplittedTasksByRange(index) {
        if (index < _localThis.splitTask.tasks.length) {
          let postData = {};
          postData = JSON.parse(JSON.stringify(_localThis.form));
          postData.studentPlanDetailId = _localThis.planDetailForm.id;
          postData.teacherId = localStorage.getItem("userId");
          postData.createdBy = localStorage.getItem("userId");
          postData.subject = _localThis.form.subjectName;
          //postData.setIds = this.form.setIds.join(",");
          postData.setProcesses = _localThis.form.checkedSetProcesses.join(",");
          postData.selectedProject = null; // 这个对象太重了，可能引发400 bad request
          postData.examMode = _localThis.form.answer;
          //缓存form的name
          let cachedName = _localThis.form.name;
          //重置range的from 和 to
          postData.from = 0;
          postData.to = 0;

          postData.setIds = _localThis.splitTask.tasks[index].join(",");
          postData.setName = _localThis.setList
            .filter((item) => postData.setIds.includes(item.id))
            .map((item) => item.name)
            .join("-");

          //处理range的from和to
          postData.from = 1 + index * _localThis.splitTask.rangeSpan;
          postData.to =
            _localThis.splitTask.rangeSpan +
            index * _localThis.splitTask.rangeSpan;
          postData.name = cachedName + postData.from + "-" + postData.to;

          _localThis.$store
            .dispatch("StudentPlan/addOrEditStudentPlanDetailTask", postData)
            .then((response) => {
              if (response.data > 0) {
                postData.id = response.data;
                _localThis.$emit("on-task-response", postData);
                postSplittedTasksByRange(index + 1);
              }
            });
        } else {
          // No more alternative sources, reject the promise
          // _localThis.$emit("all-tasks-posted");
          _localThis.dialogFormVisible = false;
          //全部post结束后，分发事件
          _localThis.$emit("on-plan-form-detail-and-task-confirm");
        }
      })(0);
    },

    handleOpen: function () {
      //初始化procedure
      this.form = {
        studentPlanDetailId: 0,
        name: "",
        proceedDate: null,
        project: "",
        selectedProject: null,
        subjectObject: "", // 这个实际上是选中的所有subject对象
        subjectName: "", //储存选中的科目名字，传出去备用
        setName: "",
        setIds: [],
        setProcessOptions: [],
        setProcesses: [],
        setRangeFrom: 0,
        setRangeTo: 0,
        checkedSetProcesses: [],
        showRange: false,
        from: 0,
        to: 0,
        showAnswerMode: false,
        answer: 4, //进入单词卡后的显示模式
        studyType: 0,
        reviewTurn: 0,
        examMode: 0,
        criterion: 90,
        state: 0,
        evaluation: "",
        comment: "",
      };
      this.form.proceedDate = new Date();
      //初始化预设的set processes，从setting文件夹的js文件里面
      this.form.setProcessOptions = SETPROCESSOPTIONS;

      //初始化planDetailForm
      this.planDetailForm.name = "";
      //打开form的时候，把property传进来的planid放到form里面
      this.planDetailForm.studentPlanId = this.studentPlanId;

      this.planDetailForm.daterange = [new Date(), new Date()];
    },

    handleClose: function () {
      // console.log('aaaa')
      this.$emit("on-plan-form-detail-and-task-close");
    },

    handleProjectSelect: function (val) {
      this.form.selectedProject = this.projects.find(
        (item) => item.name == val
      );
    },

    handleSubjectChange: function () {
      if (!this.form.subjectObject || this.form.subjectObject.length <= 0)
        return;

      //循环拿到每个subjectId的sets，然后叠加
      this.setList = [];

      //清空之前选择的sets
      this.clearCheckedSets();
      this.form.subjectName = "";
      for (let i = 0; i < this.form.subjectObject.length; i++) {
        //组合subjectName
        this.form.subjectName += this.form.selectedProject.vocSubjects.find(
          (item) => item.id == this.form.subjectObject[i]
        ).name;
        this.getSetsBySubjectId(this.form.subjectObject[i]);
      }
      //获得subject的名字
      this.generatePlanDetailName();

      this.dialogSetListVisible = true;
    },

    handleRoundNumberChange: function () {
      if (this.form.roundNum > 0) {
        this.generatePlanDetailName();
      }
    },

    getSetsBySubjectId: function (subjectId) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("Set/getSetsBySubjectId", {
            subjectId: subjectId,
          })
          .then((response) => {
            this.setList = [...this.setList, ...response.data];
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    handleCheckAllChange: function (event) {
      let tempCheckedSets = [];
      let tempCheckedAllSetList = this.setList.filter(
        (data) =>
          !this.setNameSearchString ||
          data.name
            .toLowerCase()
            .includes(this.setNameSearchString.toLowerCase())
      );
      if (event) {
        for (let i = 0; i < tempCheckedAllSetList.length; i++) {
          tempCheckedSets.push(tempCheckedAllSetList[i].id);
        }
      }

      this.checkedSets = event ? tempCheckedSets : [];
      this.isIndeterminate = false;
    },

    handleCheckedSetsChange: function (event) {
      const checkedCount = event.length;
      this.checkAllSets = checkedCount === this.setList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.setList.length;
    },

    clearCheckedSets: function () {
      this.checkAllSets = false;
      this.isIndeterminate = true;
      this.checkedSets = [];
    },

    handleSetRange: function () {
      let tempCheckedSets = [];
      let tempCheckedAllSetList = this.setList.filter(
        (data) =>
          !this.form.setName ||
          data.name.toLowerCase().includes(this.form.setName.toLowerCase())
      );

      //如果最终数值大于length，则设置为length
      this.form.setRangeTo =
        this.form.setRangeTo > this.setList.length
          ? this.setList.length
          : this.form.setRangeTo;
      for (let i = this.form.setRangeFrom - 1; i < this.form.setRangeTo; i++) {
        if (tempCheckedAllSetList[i] && tempCheckedAllSetList[i].id)
          tempCheckedSets.push(tempCheckedAllSetList[i].id);
      }

      this.checkedSets = tempCheckedSets;
      this.isIndeterminate = false;
    },

    handleSetListDialogConfirm: function () {
      this.dialogSetListVisible = false;

      this.form.setName = this.setList
        .filter((item) => {
          return this.checkedSets.includes(item.id);
        })
        .map((item) => {
          return item.name;
        })
        .join("-");
      //}
      this.form.setIds = this.checkedSets;
    },

    handleSplitTaskNumberChange: function () {
      //保证span大于1
      if(this.splitTask.span < 1) return;
      this.splitTask.tasks = this.splitArray(
        this.checkedSets,
        this.splitTask.span
      );
    },

    handleSplitTaskRangeNumberChange: function () {
      //不可以同时设定set的span和词汇数量的rangeSpan，所以如果设定了rangeSpan，就把span变成0
      if (this.splitTask.rangeSpan > 0 && this.splitTask.rangeNumber > 0) {
        this.splitTask.span = 0;
        this.splitTask.tasks = [];
        for (let i = 0; i < this.splitTask.rangeNumber; i++) {
          this.splitTask.tasks.push(this.checkedSets);
        }
      }
    },

    splitArray: function (arr, range) {
      var result = [];
      for (var i = 0; i < arr.length; i += range) {
        result.push(arr.slice(i, i + range));
      }
      return result;
    },

    handleCheckedProcessChange: function () {
      this.form.showRange = this.form.checkedSetProcesses.includes(5)
        ? true
        : false;
    },

    handleRadioGroupChange: function () {
      //this.$emit("on-answer-radio-group-change", val);
    },

    generatePlanDetailName: function () {
      let studyTypeStringMapper = ["盲筛", "背诵", "星标", "复习", "测试"];
      this.planDetailForm.name =
        (this.form.subjectName ? this.form.subjectName : "") +
        (studyTypeStringMapper[this.form.studyType - 1]
          ? studyTypeStringMapper[this.form.studyType - 1]
          : "") +
        (this.form.roundNum > 0 ? this.form.roundNum : "");
    },

    handleStudyTypeRadioGroupChange: function () {
      this.generatePlanDetailName();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cursor {
  cursor: pointer;
}
</style>
