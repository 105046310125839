// const listeningBaseUrl =
//   window.location.protocol == "http:"
//     ? "http://vocabulary.andover.com.cn:9000"
//     : "https://vocabulary.andover.com.cn:9001";
// const vocBaseUrl =
//   window.location.protocol == "http:"
//     ? "http://vocabulary.andover.com.cn:9000"
//     : "https://vocabulary.andover.com.cn:9001";

//   const listeningBaseUrl =
//   window.location.protocol == "http:"
//     ? "http://vocabulary.andover.com.cn:9000"
//     : "https://api.vocabulary.andover.com.cn";
// const vocBaseUrl =
//   window.location.protocol == "http:"
//     ? "http://vocabulary.andover.com.cn:9000"
//     : "https://api.vocabulary.andover.com.cn";

const listeningBaseUrl =
  window.location.protocol == "http:"
    ? "http://api.public.vocabulary.andover.com.cn:9000"
    : "https://api.public.vocabulary.andover.com.cn";
const vocBaseUrl =
  window.location.protocol == "http:"
    ? "http://api.public.vocabulary.andover.com.cn:9000"
    : "https://api.public.vocabulary.andover.com.cn";


const loginUrl = listeningBaseUrl + "/api/account";
const getAllTeachersAndAdmins =
  listeningBaseUrl + "/account/getAllTeachersAndAdmins";
const ListeningGetAllStudentsByCriteria =
  listeningBaseUrl + "/account/GetAllStudentsByCriteria";
const ListeningGetArticlesByTitle =
  vocBaseUrl + "/api/Article/GetArticlesByTitle";
const ListeningChangePassword = vocBaseUrl + "/Account/ChangePassword";
//const ListeningGetSentencesByArticleId = "http://listening.andover.com.cn:5000" + "/api/Sentence";
const projectUrl = vocBaseUrl + "/VocProject";
const subjectUrl = vocBaseUrl + "/VocSubject";
const setUrl = vocBaseUrl + "/VocSet";
const setToSubjectUrl = vocBaseUrl + "/AddVocSetToVocSubject";
const subjectSetUrl = vocBaseUrl + "/VocSubjectSet";
const getSetsBySubjectIdUrl = vocBaseUrl + "/GetVocSetBySubjectId";
const getUserUnorganizedSetsUrl = vocBaseUrl + "/GetUserUnorganizedSets";
const vocUrl = vocBaseUrl + "/Vocabulary";
const batchVocUrl = vocBaseUrl + "/Batchly";
const markedVocabularyUrl = vocBaseUrl + "/MarkedVocabulary";
const markedVocabulariesBySingleSetUrl =
  vocBaseUrl + "/GetMarkedVocabulariesBySetId";
const VocabularyTagUrl = vocBaseUrl + "/VocabularyTag";
const YoudaoPronunciationUrl = "http://dict.youdao.com/dictvoice?type=0&audio=";
const ListeningRelationshipManagement =
  listeningBaseUrl + "/api/RelationshipManagement";
const ListeningRelationship = listeningBaseUrl + "/api/Relationship";
const ImageUploadUrl = vocBaseUrl + "/UploadImage";
const StudentViewingHistoryUrl = vocBaseUrl + "/VocStudentViewingHistory";
const StudentTestUrl = vocBaseUrl + "/VocStudentTest";
const AddVocabularyAnswersUrl =
  vocBaseUrl + "/AddVocStudentTestVocabularyAnswers";
const GetAllTestByStudentIdUrl = vocBaseUrl + "/GetAllTestByStudentId";
const GetAllTestsByUserIdUrl = vocBaseUrl + "/GetAllTestsByUserId";
const GetTestByIdUrl = vocBaseUrl + "/GetTestById";
const EditVocStudentTestVocabularyAnswer =
  vocBaseUrl + "/EditVocStudentTestVocabularyAnswer";
const CorrectStudentAudioAnswer = vocBaseUrl + "/CorrectStudentAudioAnswer";
const VocTestCorrectedAnswerUrl = vocBaseUrl + "/VocTestCorrectedAnswer";
const VocCommentUrl = vocBaseUrl + "/VocabularyComment";
const VocStudentMark = vocBaseUrl + "/StudentMarkedVocabulary";
const CreateDuplicatedTestUrl = vocBaseUrl + "/CreateDuplicatedTest";
const AssignmentUrl = vocBaseUrl + "/VocAssignment";
const AssignSetsToStudentUrl = vocBaseUrl + "/AssignSetsToStudent";
const PostVocAssignmentSetSummaryUrl =
  vocBaseUrl + "/AddVocAssignmentSetSummary";
const GetVocQuestionUrl = vocBaseUrl + "/VocQuestion";
const PostVocStudentAssignmentAnswerUrl =
  vocBaseUrl + "/AddVocStudentAssignmentAnswer";
const GetVocNirvana = vocBaseUrl + "/GetVocNirvana";
const PostVocNirvanaSummary = vocBaseUrl + "/VocNirvanaSummary";
const AddVocStudentOverview = vocBaseUrl + "/AddVocStudentOverview";
const GetVocStudentPagedOverviews = vocBaseUrl + "/GetPagedVocStudentOverviews";
const UpsertVocStudentOverviewDetail =
  vocBaseUrl + "/UpsertVocStudentOverviewDetail";
const GetVocStudentOverviews = vocBaseUrl + "/GetVocStudentOverviews";
const GetAudioText = vocBaseUrl + "/AudioToText";
const GetSetMarkCountList = vocBaseUrl + "/GetStudentMarkedCount";
//StudentPlan start
const AddOrEditStudentPlan = vocBaseUrl + "/AddOrEditStudentPlan";
const DeleteStudentPlan = vocBaseUrl + "/DeleteStudentPlan";
const GetStudentPlansByUserId = vocBaseUrl + "/GetStudentPlansByUserId";
const AddOrEditStudentPlanDetail = vocBaseUrl + "/AddOrEditStudentPlanDetail";
const DeleteStudentPlanDetail = vocBaseUrl + "/DeleteStudentPlanDetail";
const AddOrEditStudentPlanDetailTask = vocBaseUrl + "/AddStudentPlanDetailTask";
const DeleteStudentPlanDetailTask = vocBaseUrl + "/DeleteStudentPlanDetailTask";
const AddOrEditStudentPlanDaily = vocBaseUrl + "/AddOrEditStudentPlanDaily";
const GetStudentPlanDailiesByStudentPlanId =
  vocBaseUrl + "/GetStudentPlanDailiesByStudentPlanId";
const DeleteStudentPlanDaily = vocBaseUrl + "/DeleteStudentPlanDaily";
const AddStudentPlanDailyTask = vocBaseUrl + "/AddStudentPlanDailyTask";
const DeleteStudentPlanDailyTask =
  vocBaseUrl + "/StudentPlan/DeleteStudentPlanDailyTask";
const AddOrEditStudentPlanDetailTaskResult =
  vocBaseUrl + "/AddOrEditStudentPlanDetailTaskResult";
const DeleteStudentPlanDetailTaskResult =
  vocBaseUrl + "/DeleteStudentPlanDetailTaskResult";
const GetStudentPlanDetailTaskResultsByStudentPlanDetailTaskId =
  vocBaseUrl +
  "/StudentPlan/GetStudentPlanDetailTaskResultsByStudentPlanDetailTaskId";
const UpdateStudentPlanDetailTaskTestId =
  vocBaseUrl + "/UpdateStudentPlanDetailTaskTestId";
const UpdateStudentPlanDetailTaskIsExaminingAsStudent =
  vocBaseUrl + "/UpdateStudentPlanDetailTaskIsExaminingAsStudent";
//StudentPlan end
const GetStudentPlanDetailTaskById =
  vocBaseUrl + "/GetStudentPlanDetailTaskById";
const GetGradesByName = vocBaseUrl + "/api/Grade/GetGradesByName";
const GetStudentsInGrade = vocBaseUrl + "/api/Grade/GetStudentsInGrade";

export default {
  loginUrl,
  projectUrl,
  subjectUrl,
  setUrl,
  setToSubjectUrl,
  subjectSetUrl,
  getSetsBySubjectIdUrl,
  vocUrl,
  batchVocUrl,
  getUserUnorganizedSetsUrl,
  markedVocabularyUrl,
  VocabularyTagUrl,
  YoudaoPronunciationUrl,
  ListeningRelationshipManagement,
  ListeningRelationship,
  ImageUploadUrl,
  getAllTeachersAndAdmins,
  StudentViewingHistoryUrl,
  StudentTestUrl,
  AddVocabularyAnswersUrl,
  GetAllTestByStudentIdUrl,
  GetTestByIdUrl,
  EditVocStudentTestVocabularyAnswer,
  VocTestCorrectedAnswerUrl,
  VocCommentUrl,
  VocStudentMark,
  CreateDuplicatedTestUrl,
  GetAllTestsByUserIdUrl,
  AssignmentUrl,
  AssignSetsToStudentUrl,
  PostVocAssignmentSetSummaryUrl,
  GetVocQuestionUrl,
  PostVocStudentAssignmentAnswerUrl,
  GetVocNirvana,
  PostVocNirvanaSummary,
  AddVocStudentOverview,
  UpsertVocStudentOverviewDetail,
  GetVocStudentOverviews,
  GetAudioText,
  ListeningGetAllStudentsByCriteria,
  GetSetMarkCountList,
  ListeningGetArticlesByTitle,
  CorrectStudentAudioAnswer,
  markedVocabulariesBySingleSetUrl,
  GetVocStudentPagedOverviews,
  AddOrEditStudentPlan,
  GetStudentPlansByUserId,
  DeleteStudentPlan,
  AddOrEditStudentPlanDetail,
  DeleteStudentPlanDetail,
  AddOrEditStudentPlanDetailTask,
  DeleteStudentPlanDetailTask,
  AddOrEditStudentPlanDaily,
  DeleteStudentPlanDaily,
  GetStudentPlanDailiesByStudentPlanId,
  AddStudentPlanDailyTask,
  DeleteStudentPlanDailyTask,
  AddOrEditStudentPlanDetailTaskResult,
  DeleteStudentPlanDetailTaskResult,
  GetStudentPlanDetailTaskResultsByStudentPlanDetailTaskId,
  UpdateStudentPlanDetailTaskTestId,
  UpdateStudentPlanDetailTaskIsExaminingAsStudent,
  GetStudentPlanDetailTaskById,
  ListeningChangePassword,
  GetGradesByName,
  GetStudentsInGrade,
};
