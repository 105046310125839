<template>
  <div>
    <div class="header-content">
      <h2>Nirvana</h2>
      <p v-if="nirvanaStatus == 'finished'">
        {{
          decodedQueryObject.correctCount +
          "/" +
          nirvanaAnsweredQuestions.length
        }}
      </p>
      <p v-else>
        {{ "Total: " + nirvanaQuestions.length }}
      </p>
    </div>
    <div class="question-content">
      <div
        v-for="(question, indexQ) in nirvanaQuestions"
        :key="indexQ"
        class="question-item"
      >
        <div class="question-body">
          <p style="text-align: left">
            <span v-html="question.questionBody"> </span>
          </p>
        </div>
        <div class="options-content">
          <div
            v-for="(option, index) in question.vocQuestionOptions"
            :key="index"
            style="margin-top: 10px"
          >
            <el-radio-group
              v-model="question.studentQuestionOptionId"
              @change="
                radioSelected(question.studentQuestionOptionId, question)
              "
              :disabled="nirvanaStatus == 'finished'"
            >
              <div>
                <p>
                  <el-radio :label="option.id"
                    ><span v-html="option.optionBody"> </span
                  ></el-radio>
                </p>
              </div>
            </el-radio-group>
          </div>
        </div>

        <div v-if="nirvanaStatus == 'finished'" class="result-container">
          <div v-if="question.isCorrect" class="answer-correct">
            <el-icon :size="16" color="deepskyblue" style="align: center"
              ><CircleCheckFilled
            /></el-icon>
          </div>
          <div v-else class="answer-incorrect">
            <span>
              <el-icon :size="16" color="red" style="align: center"
                ><CircleClose
              /></el-icon>
            </span>
            <span style="color: deepskyblue; margin-left: 20px">{{
              question.answerMessage
            }}</span>
          </div>
        </div>
      </div>

      <el-button
        v-if="nirvanaStatus != 'finished'"
        class="mt-4"
        style="width: 100%; margin-top: 10px"
        @click="submit()"
        >提交</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "StudentVocNirvanaItem",

  data() {
    return {
      nirvanaQuestions: [],
      decodedQueryObject: null,
      nirvanaStatus: "",
      nirvanaAnsweredQuestions: [],
    };
  },

  beforeMount: function () {
    // decode query string into object
    let queryString = window.atob(this.$route.query.encodedString);
    queryString = window.decodeURIComponent(queryString);
    this.decodedQueryObject = JSON.parse(queryString);
    console.log(this.decodedQueryObject);
    console.log(this.$route);
    //this.vocCardItem.vocItem = this.vocItem;
    //this.vocCardItem.currentVocIndex = this.currentVocIndex;
    //this.vocCardItem.vocCount = this.vocCount;
    //this.vocCardItem.currentVocMarkedCount = this.vocItem.MarkedCount;
  },

  mounted: function () {
    this.getNirvanaQuestion();
    //this.initializeAssignmentSetStatus();
  },

  methods: {
    getNirvanaQuestion: function () {
      console.log(this.decodedQueryObject.studentId);
      this.$store
        .dispatch("VIP/getVocNirvana", {
          studentId: this.decodedQueryObject.studentId,
        })
        .then((response) => {
          this.nirvanaQuestions = response.data;
          this.processQuestionBody();
          console.log(this.nirvanaQuestions);
          if (this.nirvanaStatus == "finished") {
            this.calculateNirvanaCorrectCount();
            this.cookCorrectAnswerMessage();
            console.log(this.nirvanaQuestions);
          }
        })
        .catch((error) => console.log(error));
    },

    // initializeAssignmentSetStatus: function () {
    //   if (this.decodedQueryObject.setRow.vocAssignmentSetSummary == null) {
    //     this.nirvanaStatus = "pending";
    //   } else {
    //     this.nirvanaStatus = this.decodedQueryObject.setRow.vocAssignmentSetSummary
    //       .status
    //       ? this.decodedQueryObject.setRow.vocAssignmentSetSummary.status
    //       : "pending";
    //   }
    // },

    processQuestionBody: function () {
      for (let i = 0; i < this.nirvanaQuestions.length; i++) {
        //添加一个studentQuestionOptionId属性，存学生选择的答案
        this.nirvanaQuestions[i].studentQuestionOptionId = "";
        if (this.nirvanaQuestions[i].questionBody.split(" ").length > 1) {
          this.nirvanaQuestions[i].questionBody =
            "The phrase " +
            '"' +
            this.nirvanaQuestions[i].questionBody +
            '" ' +
            "in the passage is closest in meaning to";
        } else {
          this.nirvanaQuestions[i].questionBody =
            "The word " +
            '"' +
            this.nirvanaQuestions[i].questionBody +
            '" ' +
            "in the passage is closest in meaning to";
        }
      }
    },

    radioSelected: function (optionId, question) {
      console.log(question);
      this.countAnswerQuestion(question);
      this.$store
        .dispatch("VIP/PostVocStudentAssignmentAnswer", {
          assignmentId: question.assignmentId,
          questionId: question.id,
          questionOptionId: optionId,
          isNirvana: true,
          studentId: this.decodedQueryObject.studentId,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => console.log(error));
    },

    countAnswerQuestion: function (question) {
      if (!this.nirvanaAnsweredQuestions.includes(question))
        this.nirvanaAnsweredQuestions.push(question);
      console.log(this.nirvanaAnsweredQuestions);
    },

    submit: function () {
      this.calculateNirvanaCorrectCount();
      this.nirvanaStatus = "finished";
      this.$store.dispatch("VIP/postVocNirvanaSummary", {
        id: this.decodedQueryObject.nirvanaId,
        userId: this.decodedQueryObject.studentId,
        correctCount: this.decodedQueryObject.correctCount,
        totalCount: this.nirvanaAnsweredQuestions.length,
      });
    },

    calculateNirvanaCorrectCount: function () {
      let correctCount = 0;
      for (let i = 0; i < this.nirvanaQuestions.length; i++) {
        if (this.isAnswerCorrect(i)) {
          this.nirvanaQuestions[i].isCorrect = true;
          correctCount += 1;
        } else {
          this.nirvanaQuestions[i].isCorrect = false;
        }
      }
      this.decodedQueryObject.correctCount = correctCount;
      return correctCount;
    },

    // calculateCorrectCount: function () {
    //   let correctCount = 0;
    //   for (let i = 0; i < this.nirvanaQuestions.length; i++) {
    //     if (this.isAnswerCorrect(i)) {
    //       this.nirvanaQuestions[i].isCorrect = true;
    //       correctCount += 1;
    //     } else {
    //       this.nirvanaQuestions[i].isCorrect = false;
    //     }
    //   }
    //   this.decodedQueryObject.setRow.correctCount = correctCount;
    //   return correctCount;
    // },

    isAnswerCorrect: function (index) {
      if (
        this.nirvanaQuestions[index].studentQuestionOptionId &&
        this.nirvanaQuestions[index].vocQuestionOptions.find(
          (selectedOption) =>
            selectedOption.id ==
            this.nirvanaQuestions[index].studentQuestionOptionId
        ).isAnswer
      ) {
        return true;
      } else {
        return false;
      }
    },

    cookCorrectAnswerMessage: function () {
      for (let i = 0; i < this.nirvanaQuestions.length; i++) {
        let answerIndex = this.nirvanaQuestions[i].vocQuestionOptions.findIndex(
          (selectedOption) => selectedOption.isAnswer
        );
        this.nirvanaQuestions[i].answerMessage =
          "正确答案： " +
          this.parseIndexToAnswer(answerIndex) +
          " " +
          this.nirvanaQuestions[i].vocQuestionOptions[answerIndex].optionBody;
      }
    },

    parseIndexToAnswer: function (index) {
      switch (index) {
        case 0:
          return "A";
        case 1:
          return "B";
        case 2:
          return "C";
        case 3:
          return "D";
        default:
          return "";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header-content {
  position: fixed;
  z-index: 100;
  top: 0px;
  width: 100%;
  text-align: center;
  background-color: #2a7049;
  color: white;
}

.question-content {
  margin-top: 100px;
  margin-left: 30%;
  margin-right: 30%;
  text-align: center;
  line-height: 18px;
  font-family: “Helvetica Neue”, Helvetica, Arial, sans-serif;
}

span {
  font-family: “Helvetica Neue”, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 550;
  color: black;
}

.question-body,
.options-content {
  margin-left: 20px;
  overflow-y: auto;
}
.options-content {
  text-align: left;
}

.question-item {
  border: 1px solid rgb(233, 233, 233);
  border-radius: 10px;
  margin-bottom: 10px;
}

.result-container {
  border-top: 1px solid rgb(233, 233, 233);
}

.answer-correct,
.answer-incorrect {
  margin: 5px 0;
}
</style>
