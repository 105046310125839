<template>
  <div>
    <el-dialog
      width="30%"
      v-model="dialogFormVisible"
      title="Customized Filter"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-form :model="form">
        <el-form-item label="范围1">
          <el-row>
            <el-col :span="5">
              <el-input
                v-model.number="form.startRange1"
                class="input-from"
                size="small"
              ></el-input>
            </el-col>
            <el-col :span="1"> - </el-col>
            <el-col :span="5">
              <el-input
                v-model.number="form.endRange1"
                class="input-from"
                size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item label="标记 : 未标记">
          <el-row>
            <el-col :span="5">
              <el-input
                placeholder="星标词选取数量"
                v-model.number="form.staredCount1"
                class="input-from"
                size="small"
              ></el-input>
            </el-col>
            <el-col :span="1"> : </el-col>
            <el-col :span="5">
              <el-input
                placeholder="非星标词选取数量"
                v-model.number="form.unstaredCount1"
                class="input-from"
                size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item label="范围2">
          <el-row>
            <el-col :span="5">
              <el-input
                v-model.number="form.startRange2"
                class="input-from"
                size="small"
              ></el-input>
            </el-col>
            <el-col :span="1"> - </el-col>
            <el-col :span="5">
              <el-input
                v-model.number="form.endRange2"
                class="input-from"
                size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item label="标记 : 未标记">
          <el-row>
            <el-col :span="5">
              <el-input
                placeholder="星标词选取数量"
                v-model.number="form.staredCount2"
                class="input-from"
                size="small"
              ></el-input>
            </el-col>
            <el-col :span="1"> : </el-col>
            <el-col :span="5">
              <el-input
                placeholder="非星标词选取数量"
                v-model.number="form.unstaredCount2"
                class="input-from"
                size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onCustomizedFilterCancel()">Cancel</el-button>
          <el-button type="primary" @click="onCustomizedFilterConfirm()"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CustomizedFilterForm",

  props: ["modelValue"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  data() {
    return {
      dialogFormVisible: false,

      form: {
        startRange1: 0,
        endRange1: 0,
        staredCount1: 0,
        unstaredCount1: 0,
        startRange2: 0,
        endRange2: 0,
        staredCount2: 0,
        unstaredCount2: 0,
      },
    };
  },

  // mounted: function() {
  //   this.form.allTags = Tags;
  //   console.log(this.form.allTags)
  // },

  methods: {
    onCustomizedFilterCancel: function () {
      this.dialogFormVisible = false;
      this.$emit("on-customized-filter-form-cancel");
    },
    onCustomizedFilterConfirm: function () {
      this.dialogFormVisible = false;
      this.$emit("on-customized-filter-form-confirm", this.form);
    },

    // handleMarkCountMinusClick: function () {
    //   if (this.form.markCountFilter > 0) this.form.markCountFilter -= 1;
    // },

    // handleMarkCountAddClick: function () {
    //   if (this.form.markCountFilter < 5) this.form.markCountFilter += 1;
    // },

    // handleCheckedTagChange: function(value) {

    // },

    handleOpen: function () {
      // initialize range index
      this.form.startRange1 = 0;
      this.form.endRange1 = 0;
      this.form.staredCount1 = 0;
      this.form.unstaredCount1 = 0;
      this.form.startRange2 = 0;
      this.form.endRange2 = 0;
      this.form.staredCount2 = 0;
      this.form.unstaredCount2 = 0;
    },

    handleClose: function () {
      // console.log('aaaa')
      this.$emit("on-customized-filter-form-close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cursor {
  cursor: pointer;
}

</style>
