import axios from "axios";
import url from "../urls";

export default {
  //put
  add(context, data) {
    console.log(data)
    return new Promise((resolve, reject) => {
      let sUrl = url.subjectUrl;
      axios
        .put(sUrl, data)
        .then((response) => {
          console.log(response)
          context.commit("mutateAddedSubject", {
            subjectId: response.data,
            subjectName: data.name
          });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //get
  get(context, data) {
    return new Promise((resolve, reject) => {
      let sUrl = url.subjectUrl;

      axios
        .get(sUrl, {
          params: {
            assignmentId: data.assignmentId,
          },
        })
        .then((response) => {
          console.log("question got");
          context.commit("mutateCompletionQuestions", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //post
  edit(context, data) {
    return new Promise((resolve, reject) => {
      let sUrl = url.subjectUrl;
      axios
        .post(sUrl, data)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //delete
  delete(context, data) {
    return new Promise((resolve, reject) => {
      let sUrl = url.subjectUrl;
      axios
        .delete(sUrl, {params: {
          subjectId: data.id
        }})
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
