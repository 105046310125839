<template>
  <div>
    <div>{{ decodedQueryObject.studentName }}</div>
    <div class="overview-list">
      <el-table
        v-loading="loading"
        element-loading-text="I am Enigma~"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        ref="allOverviewTable"
        :data="overviewList"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        @row-click="
          (row, column, event) => {
            handleOverviewListRowClick(row, column, event);
          }
        "
      >
        <el-table-column prop="vocProjectName" label="Project">
        </el-table-column>
        <el-table-column prop="vocSubjectName" label="Subject">
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-popover
              effect="light"
              trigger="hover"
              placement="top"
              width="auto"
            >
              <template #default>
                <div>sets: {{ scope.row.vocSetNames }}</div>
              </template>
              <template #reference>
                {{
                  scope.row.vocSetNames.length > 16
                    ? scope.row.vocSetNames.substring(0, 16) + "..."
                    : scope.row.vocSetNames
                }}
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="learningMode"
          label="Learning Mode"
          :filters="[
            { text: '学生自学', value: '学生自学' },
            { text: '老师检查', value: '老师检查' },
            { text: '学生测试', value: '学生测试' },
          ]"
          :filter-method="filterLearningMode"
          filter-placement="bottom-end"
        >
        </el-table-column>
        <el-table-column
          prop="checkingMode"
          label="Checking Mode"
          :filters="[
            { text: '英翻中', value: '1' },
            { text: '中翻英', value: '2' },
            { text: '显示中英文', value: '3' },
            { text: '英翻中（仅音频）', value: '4' },
          ]"
          :filter-method="filterCheckingMode"
          filter-placement="bottom-end"
        >
          <template #default="scope">
            <span> {{ mapCheckingMode(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          property="vocStudentOverViewDetails"
          label="Correct Rate"
          sortable
          width="180"
        >
          <template #default="scope">
            {{ calculateCorrectRate(scope.row.vocStudentOverViewDetails) }}
          </template>
        </el-table-column>
        <el-table-column prop="totalCount" label="Total"> </el-table-column>

        <el-table-column
          property="vocStudentOverViewDetails"
          label="incorrects"
          width="180"
        >
          <template #default="scope">
            <span
              @click.stop="handleIncorrectColumnClick(scope.row)"
              style="cursor: pointer"
            >
              {{
                scope.row.vocStudentOverViewDetails
                  .filter((item) => !item.isCorrect)
                  .map((item) => item.englishText)
                  .join(", ")
              }}</span
            >
          </template>
        </el-table-column>

        <el-table-column
          property="createdDate"
          label="Created Date"
          sortable
          width="180"
          column-key="createdDate"
        >
          <template #default="scope">
            {{ scope.row.createdDate.substring(5, 16) }}
          </template>
        </el-table-column>
        <el-table-column
          property="updatedDate"
          label="Updated Date"
          sortable
          width="180"
          column-key="updatedDate"
        >
          <template #default="scope">
            {{ scope.row.updatedDate.substring(5, 16) }}
          </template>
        </el-table-column>
        <!-- <el-table-column
        prop="type"
        label="类型"
        width="150"
        :filters="[
          { text: '听写英文', value: 1 },
          { text: '听写中英', value: 2 },
          { text: '听英写中', value: 3 },
          { text: '看英写中', value: 4 },
        ]"
        :filter-method="filterType"
        filter-placement="bottom-end"
      >
        <template #default="scope">
          {{ testTypeString(scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column>
        <template #header>
          <el-input
            v-model="searchOrigin"
            size="small"
            placeholder="Type to search origin"
          />
        </template>
        <template #default="scope">
          <el-popover
            effect="light"
            trigger="hover"
            placement="top"
            width="auto"
          >
            <template #default>
              <div>origin: {{ scope.row.origin }}</div>
            </template>
            <template #reference>
              {{ scope.row.origin.substring(0, 20) + "..." }}
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="描述"> </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        width="100"
        :filters="[
          { text: 'standing', value: 'standing' },
          { text: 'pending', value: 'pending' },
          { text: 'finished', value: 'finished' },
        ]"
        :filter-method="filterStatus"
        filter-placement="bottom-end"
      >
        <template #default="scope">
          <el-tag
            :type="scope.row.status === 'finished' ? '' : 'success'"
            disable-transitions
            >{{ scope.row.status }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column align="right" v-if="userRole != 'Student'">
        <template #default="scope">
          <el-button
            size="small"
            type="success"
            @click.stop="handlePublish(scope.row)"
            v-show="scope.row.published == false"
            >Publish</el-button
          >
          <el-button
            size="small"
            type="success"
            @click.stop="handleDuplicate(scope.row)"
            >Dolly</el-button
          >
          <el-button
            size="small"
            type="danger"
            @click.stop="handleDelete(scope.$index, scope.row)"
            >Delete</el-button
          >
        </template>
      </el-table-column> -->
      </el-table>
    </div>

    <el-button class="mt-4" style="width: 100%" @click="getMoreOverviews()"
      >More</el-button
    >
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
export default {
  name: "index",

  data() {
    return {
      overviewList: [],
      loading: false,
      page: 1,
    };
  },

  beforeMount: function () {
    // decode query string into object
    let queryString = window.atob(this.$route.query.encodedString);
    queryString = window.decodeURIComponent(queryString);
    this.decodedQueryObject = JSON.parse(queryString);
    //this.vocCardItem.vocItem = this.vocItem;
    //this.vocCardItem.currentVocIndex = this.currentVocIndex;
    //this.vocCardItem.vocCount = this.vocCount;
    //this.vocCardItem.currentVocMarkedCount = this.vocItem.MarkedCount;
  },

  mounted: function () {
    this.getStudentOverviews();
  },

  methods: {
    getStudentOverviews: function () {
      //这里的TOEFL和学生测试都内置hard code是因为目前只需要这些数据； 学生测试作为中文可能无法匹配，导致无效
      //这里learningMode参数现在删掉了，换成subjectName，不给subjectName的情况下，拿整个project下的内容
      //这里的projectName不一定要hard code，也可以根据具体的名字去拿到不同project下的enigma
      let params = {
        studentId: this.decodedQueryObject.studentId,
        projectName: "TOEFL",
        pageNum: this.page,
      };

      this.loading = true;
      this.$store
        .dispatch("StudentOverview/getStudentOverviews", params)
        .then((response) => {
          console.log(response.data);
          this.overviewList = [...this.overviewList, ...response.data];
          this.loading = false;
          // this.overviewList = response.data.filter(
          //   (item) =>
          //     item.vocProjectName == "TOEFL" &&
          //     item.vocStudentOverViewDetails.filter(
          //       (item) => item.isCorrect == false
          //     ).length > 0
          // );
        })
        .catch((error) => {
          console.log(error.toString());
          this.loading = false;
        });
    },

    getMoreOverviews: function () {
      this.page += 1;
      this.getStudentOverviews();
    },

    // openAsNewPageWithCryption: function (routeName, query) {
    //   query = JSON.stringify(query);
    //   query = window.encodeURIComponent(query);
    //   let newpage = this.$router.resolve({
    //     path: routeName,
    //     query: { encodedString: window.btoa(query) },
    //   });
    //   window.open(newpage.href, "_blank");
    // },

    calculateCorrectRate: function (overviewDetail) {
      if (!overviewDetail) return;
      let totalCount = overviewDetail.length;
      let correctCount = overviewDetail.filter((item) => {
        return item.isCorrect == true;
      }).length;
      return correctCount + "/" + totalCount;
    },

    //handleOverviewListRowClick: function (row, column, event, payload) {
    handleOverviewListRowClick: function () {
      // to be done
    },

    handleIncorrectColumnClick: function (row) {
      this.copyIncorrectsToClip(row);
      console.log(row);
    },

    //每次标记或取消标记后，自动复制当前的错词到剪切板
    copyIncorrectsToClip: function (row) {
      //把current marked voc里面的所有单词的englishText都以一定的格式复制到剪切板以便直接复制
      let result = "";
      //获得targetCNName
      result += this.decodedQueryObject.studentName + ": ";
      //获得当前科目名字
      result += row.vocSubjectName + ": ";
      //获得当前学习集名字
      result += row.vocSetNames + ": ";
      //获得当前错词数量
      result +=
        "(+" +
        this.calculateCorrectRate(row.vocStudentOverViewDetails) +
        ") : ";
      //拼接当前错词EnglishText，map每个voc到englishText，然后join
      result += row.vocStudentOverViewDetails
        .filter((item) => !item.isCorrect)
        .map((item) => item.englishText)
        .join(", ");
      let copyIncorrectsInput = document.createElement("input");
      copyIncorrectsInput.setAttribute("value", result);
      document.body.appendChild(copyIncorrectsInput);
      copyIncorrectsInput.select();
      try {
        let copied = document.execCommand("copy");

        if (copied) {
          ElMessage({
            message: result,
            type: "success",
          });
          document.body.removeChild(copyIncorrectsInput);
        }
      } catch {
        document.body.removeChild(copyIncorrectsInput);
      }
    },

    filterLearningMode: function (val, row) {
      return row.learningMode == val;
    },

    filterCheckingMode: function (val, row) {
      return row.checkingMode == val;
    },

    mapCheckingMode: function (val) {
      switch (val) {
        case "1":
          return "英翻中";
        case "2":
          return "中翻英";
        case "3":
          return "显示中英文";
        case "4":
          return "英翻中（仅音频）";
      }
    },

    clearFilter: function () {
      this.$refs.allOverviewTable.clearFilter();
      this.searchOrigin = "";
    },

    //tableRowClassName: function ({ row, rowIndex }) {
    tableRowClassName: function ({ row }) {
      if (row.status == "pending") {
        return "warning-row";
      } else if (row.status == "finished") {
        return "success-row";
      }
      return "";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// .overview-list {
//   margin-left: 10%;
// }
</style>
