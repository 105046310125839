<template>
  <div>
    <el-dialog
      title="时间过滤器"
      v-model="dialogFormVisible"
      @close="onFormClose()"
      @open="onFormOpen()"
    >
      <el-form :model="form">
        <el-date-picker
          v-model="form.dateRange"
          type="datetimerange"
          unlink-panels
          range-separator="To"
          start-placeholder="Start datetime"
          end-placeholder="End datetime"
          :shortcuts="shortcuts"
          size="small"
        />
      </el-form>
      <div style="margin-top: 20px">
        <span
          >TIP: 时间过滤器用于选出当前集合中，某个时间段（startDate - endDate）内操作过的单词（不管是加标记还是减标记）<br />
          起始日期和结束日期的具体时间（小时：分钟：秒）现在可选<br />
          “最近一天”的算法是，已当前时间为基础，往前推24小时，取出区间，比如现在是上午10:00，最近一天会选择昨天10:00到今天10:00的24小时；其他左侧快捷时间段选择同理；<br />
          直接点击时间区域，可直接获得当前系统时间</span
        >
      </div>
      <template #footer>
        <el-button @click="onFormCancel()">取 消</el-button>
        <el-button type="primary" @click="onFormConfirm()">确 定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
//import axios from "axios";

export default {
  name: "DateFilterForm",
  props: ["modelValue"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  data() {
    return {
      dialogFormVisible: false,
      form: {
        startDate: null,
        endDate: null,
        dateRange: "",
      },

      shortcuts: [
        {
          text: "最近一天",
          value() {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            return [start, end];
          },
        },
        {
          text: "最近一周",
          value() {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "最近一个月",
          value() {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "最近三个月",
          value() {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
      ],
    };
  },

  computed: {},

  mounted: function () {},

  methods: {
    onFormCancel: function () {
      this.dialogFormVisible = false;
      this.$emit("on-date-filter-form-cancel");
    },
    onFormConfirm: function () {
      this.dialogFormVisible = false;
      this.$emit("on-date-filter-form-confirm", this.form.dateRange);
    },
    onFormOpen: function () {},
    onFormClose: function () {
      this.$emit("on-date-filter-form-close");
    },
  },
};
</script>

<style scoped>
</style>
