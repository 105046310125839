import axios from "axios";
import url from "../urls";

export default {
  //put
  addAssignment(context, data) {
    return new Promise((resolve, reject) => {
      let assignmentUrl = url.AssignmentUrl;
      axios
        .put(assignmentUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //get 
  getAssignmentList(context, data) {
    return new Promise((resolve, reject) => {
      let assignmentUrl = url.AssignmentUrl;

      axios
        .get(assignmentUrl, {
          params: data,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //get voc question by set id
  getVocQuestion(context, data) {
    return new Promise((resolve, reject) => {
      let questionUrl = url.GetVocQuestionUrl;

      axios
        .get(questionUrl, {
          params: data,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //get voc nirvana by student id
  getVocNirvana(context, data) {
    return new Promise((resolve, reject) => {
      let getVocNirvana = url.GetVocNirvana;

      axios
        .get(getVocNirvana, {
          params: data,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  edit(context, data) {
    return new Promise((resolve, reject) => {
      let editUrl = url.projectUrl;
      axios
        .post(editUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  postVocNirvanaSummary(context, data) {
    return new Promise((resolve, reject) => {
      let postUrl = url.PostVocNirvanaSummary;
      axios
        .post(postUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post question answer
  PostVocStudentAssignmentAnswer(context, data) {
    return new Promise((resolve, reject) => {
      let postVocStudentAssignmentAnswerUrl = url.PostVocStudentAssignmentAnswerUrl;
      axios
        .post(postVocStudentAssignmentAnswerUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  postVocAssignmentSetSummary(context, data) {
    return new Promise((resolve, reject) => {
      let setSummaryUrl = url.PostVocAssignmentSetSummaryUrl;
      axios
        .post(setSummaryUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  assignSetsToStudent(context, data) {
    return new Promise((resolve, reject) => {
      let assignSetsToStudentUrl = url.AssignSetsToStudentUrl;
      axios
        .post(assignSetsToStudentUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //delete
  delete(context, data) {
    return new Promise((resolve, reject) => {
      let deleteUrl = url.AssignmentUrl;
      axios
        .delete(deleteUrl, { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
