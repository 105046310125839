<template>
  <div>
    <el-dialog
      width="30%"
      v-model="dialogFormVisible"
      title="把所有任务转成测试"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-form-item label="自动发布">
        <el-switch
          v-model="form.publishSwitch"
          active-color="#13ce66"
          inactive-color="gray"
        />
      </el-form-item>

      <el-form-item label="测试名称">
        <el-input
          v-model="form.testName"
          placeholder="请输入测试名称"
          @keydown.stop
          disabled
        >
        </el-input>
      </el-form-item>

      <el-form-item label="测试描述">
        <el-input
          v-model="form.testDescription"
          placeholder="请输入测试描述"
          @keydown.stop
        >
        </el-input>
      </el-form-item>

      <el-form :model="form">
        <el-form-item label="测试模式">
          <el-radio-group
            v-model="form.testMode"
            @change="handleRadioGroupChange($event)"
          >
            <el-radio :label="1">听写英文</el-radio>
            <el-radio :label="2">听写中英</el-radio>
            <el-radio :label="3">听英写中</el-radio>
            <el-radio :label="4">看英写中</el-radio>
            <el-radio :label="5">语音测试</el-radio>
            <el-radio :label="6">看中写英</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <el-form-item label="测试类别">
        <el-select v-model="form.category" placeholder="请选择">
          <el-option
            v-for="item in form.categoryOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="播放次数">
        <el-input-number
          v-model="form.playCount"
          size="small"
          @keydown.stop
          placeholder="请输入播放次数"
        />
      </el-form-item>

      <el-form-item label="中文计时">
        <el-input-number
          v-model="form.testCountDown"
          :disabled="[1, 6].includes(form.testMode)"
          size="small"
          @keydown.stop
          placeholder="请输入每个中文倒计时"
        />
      </el-form-item>

      <el-form-item label="拼写计时">
        <el-input-number
          v-model="form.enTypeCountDown"
          :disabled="[3, 4].includes(form.testMode)"
          size="small"
          @keydown.stop
          placeholder="请输入每个单词倒计时"
        />
      </el-form-item>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onConvertAllDetailTaskToTestFormCancel()"
            >Cancel</el-button
          >
          <el-button
            v-if="showGetWords"
            type="primary"
            @click="onGetWordsConfirm()"
            >Get Words</el-button
          >
          <el-button
            type="primary"
            v-if="showConvertAllConfirm"
            @click="onConvertAllDetailTaskToTestFormConfirm()"
            >Convert</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ConvertAllDetailTaskToTestForm",

  props: ["modelValue", "planDetail"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  data() {
    return {
      dialogFormVisible: false,
      vocList: null,
      localPlanDetail: null,
      showConvertAllConfirm: false,
      showGetWords: true,

      form: {
        testName: "",
        testDescription: "",
        testMode: 1,
        publishSwitch: false,
        playCount: 2,
        testCountDown: 0,
        enTypeCountDown: 5,
        category: "",
        categoryOptions: [
          {
            value: "词测",
            label: "词测",
          },
          {
            value: "默写",
            label: "默写",
          },
          {
            value: "常规词汇机筛",
            label: "常规词汇机筛",
          },
          {
            value: "星标词检查",
            label: "星标词检查",
          },
        ],
      },
    };
  },

  // mounted: function () {
  //   this.localPlanDetail = JSON.parse(JSON.stringify(this.planDetail));
  // },

  methods: {
    // getVocabularies: function () {
    //   let params = {
    //     userId: localStorage.getItem("targetId"),
    //     setIds: this.task.setIds.split(",").map(Number),
    //   };

    //   this.$store
    //     .dispatch("Vocabulary/getMarkedVocabularies", params)
    //     .then((response) => {
    //       if (response.data.length >= 1) {
    //         this.vocList = response.data;

    //         let setProcesses =
    //           this.task.setProcesses
    //             .split(",")
    //             .map(Number);
    //         for (let i = 0; i < setProcesses.length; i++) {
    //           this.autoSetProcess(setProcesses[i]);
    //         }
    //       }
    //     })
    //     .catch((error) => console.log(error.toString()));
    // },

    autoSetProcess: function (processCode, task) {
      switch (processCode) {
        case 1:
          task.vocList = this.randomArray(task.vocList);
          return task;
        case 2:
          task.vocList = task.vocList.filter((item) => item.marked == true);
          return task;
        case 3:
          //this.filterVocListWithNoHeart();
          task.vocList = task.vocList.filter((item) => {
            return item.marked == false;
          });
          return task;
        case 4:
          return this.filterVocByMarkedCount(task, 6);
        case 5:
          return this.filterVocListByRange(task);
        case 6:
          break;
      }
    },

    filterVocListByRange: function (task) {
      if (
        task.from == null ||
        task.from == "" ||
        task.to == null ||
        task.to == ""
      ) {
        //console.log("both from and to should not be null");
        return;
      } else {
        if (task.from > task.to) {
          let temp = task.from;
          task.from = task.to;
          task.to = temp;
        }
        task.vocList = task.vocList.slice(task.from - 1, task.to);
        return task;
      }
    },

    filterVocByMarkedCount: function (task, count) {
      task.vocList = task.vocList.filter(
        (item) => item.marked == true && item.count >= count
      );
      return task;
    },

    randomArray: function (arrayList) {
      if (arrayList.length === 0 || arrayList.length === null) return;
      // 继续优化，让random的元素和第i个元素换位置；这样就不需要临时变量resultArray了
      for (var i = 0; i < arrayList.length; i++) {
        var randomIndex = Math.floor(Math.random() * arrayList.length);
        var tempItem = arrayList[randomIndex];
        arrayList[randomIndex] = arrayList[i];
        arrayList[i] = tempItem;
      }
      return arrayList;
    },

    onConvertAllDetailTaskToTestFormCancel: function () {
      this.dialogFormVisible = false;
      this.$emit("on-convert-all-task-to-test-form-cancel");
    },
    onGetWordsConfirm: function () {
      this.$message("正在获取所有所需的单词，请耐心等待～");
      this.showGetWords = false;
      let _localThis = this;
      (function getVocabulariesOneByOne(index) {
        if (index < _localThis.localPlanDetail.studentPlanDetailTasks.length) {
          let params = {
            userId: localStorage.getItem("targetId"),
            setIds: _localThis.localPlanDetail.studentPlanDetailTasks[
              index
            ].setIds
              .split(",")
              .map(Number),
          };

          _localThis.$store
            .dispatch("Vocabulary/getMarkedVocabularies", params)
            .then((response) => {
              if (response.data.length >= 1) {
                _localThis.localPlanDetail.studentPlanDetailTasks[
                  index
                ].vocList = response.data;

                let setProcesses =
                  _localThis.localPlanDetail.studentPlanDetailTasks[
                    index
                  ].setProcesses
                    .split(",")
                    .map(Number);
                for (let i = 0; i < setProcesses.length; i++) {
                  _localThis.localPlanDetail.studentPlanDetailTasks[index] =
                    _localThis.autoSetProcess(
                      setProcesses[i],
                      _localThis.localPlanDetail.studentPlanDetailTasks[index]
                    );
                }

                // let localTask = JSON.parse(
                //   JSON.stringify(
                //     _localThis.localPlanDetail.studentPlanDetailTasks[index]
                //   )
                // );
                //处理完单词后，转成测试
                //_localThis.convertOneTaskToTest(localTask);
                getVocabulariesOneByOne(index + 1);
              }
            })
            .catch((error) => console.log(error.toString()));
        } else {
          //全部转化完成
          //_localThis.$emit("on-test-mode-form-confirm", _localThis.form);
          //_localThis.dialogFormVisible = false;
          _localThis.showConvertAllConfirm = true;
          console.log(_localThis.localPlanDetail.studentPlanDetailTasks);
        }
      })(0);
    },

    onConvertAllDetailTaskToTestFormConfirm: function () {
      let _localThis = this;
      console.log(_localThis.localPlanDetail.studentPlanDetailTasks);
      (function convertTaskToTestOneByOne(index) {
        if (index < _localThis.localPlanDetail.studentPlanDetailTasks.length) {
          if (
            _localThis.localPlanDetail.studentPlanDetailTasks[index]
              .vocStudentTestId
          ) {
            _localThis.$message("已经是一个测试～");
            convertTaskToTestOneByOne(index + 1);
            return;
          }
          if (
            _localThis.localPlanDetail.studentPlanDetailTasks[index].vocList
              .length > 300 ||
            _localThis.localPlanDetail.studentPlanDetailTasks[index].vocList
              .length <= 0
          ) {
            _localThis.$message("测试词汇数量不符合规范");
            convertTaskToTestOneByOne(index + 1);
            return;
          }

          let postData = {
            studentId: localStorage.getItem("targetId"),
            name:
              _localThis.localPlanDetail.studentPlanDetailTasks[index].setName
                .length > 20
                ? _localThis.localPlanDetail.studentPlanDetailTasks[
                    index
                  ].setName.substring(0, 15) +
                  "..." +
                  _localThis.localPlanDetail.studentPlanDetailTasks[
                    index
                  ].setName.substring(
                    _localThis.localPlanDetail.studentPlanDetailTasks[index]
                      .setName.length - 15
                  )
                : _localThis.localPlanDetail.studentPlanDetailTasks[index]
                    .setName,
            type: _localThis.form.testMode,
            description: _localThis.form.testDescription,
            origin:
              _localThis.localPlanDetail.studentPlanDetailTasks[index].project +
              "-" +
              _localThis.localPlanDetail.studentPlanDetailTasks[index].subject +
              "-" +
              _localThis.localPlanDetail.studentPlanDetailTasks[index].setName,
            status: "standing",
            nirvanaCount: 0,
            createdBy: localStorage.getItem("userId"),
            published: _localThis.form.publishSwitch,
            countDown: _localThis.form.testCountDown,
            enAnswerCountDown: _localThis.form.enTypeCountDown,
            category: _localThis.form.category,
          };
          _localThis.$store
            .dispatch("StudentTest/createEditStudentTest", postData)
            .then((response) => {
              _localThis.addStudentVocabularyAnswers(
                response.data,
                _localThis.localPlanDetail.studentPlanDetailTasks[index]
              );
              //拿到testId之后，把testId绑定到对应的task
              _localThis.bindTestToTask(
                _localThis.localPlanDetail.studentPlanDetailTasks[index].id,
                response.data,
                _localThis.form.testMode
              );
            })
            .catch((error) => _localThis.$message(error.toString()));
          convertTaskToTestOneByOne(index + 1);
        } else {
          //全部转化完成
          _localThis.$emit(
            "on-convert-all-task-to-test-form-confirm",
            _localThis.form
          );
          _localThis.dialogFormVisible = false;
          _localThis.showGetWords = true;
        }
      })(0);
    },

    bindTestToTask: function (taskId, testId, testType) {
      this.$store
        .dispatch("StudentPlan/updateStudentPlanDetailTaskTestId", {
          taskId: taskId,
          testId: testId,
          testType: testType,
        })
        .then(() => {
          this.$emit("on-task-test-bound", {
            taskId: taskId,
            testId: testId,
            testType: testType,
          });
          this.$message({
            type: "success",
            message: "测试已绑定",
          });
        });
    },

    addStudentVocabularyAnswers: function (testId, task) {
      let postData = task.vocList.map((item) => {
        return {
          vocStudentTestId: testId,
          vocabularyId: item.vocabularyId,
          listeningCount: this.form.playCount,
        };
      });
      this.$store
        .dispatch("StudentTest/addVocabularyAnswers", postData)
        .then((response) => {
          if (response.data) {
            this.$message({
              type: "success",
              message: "测试已生成",
            });
          }
        })
        .catch((error) => this.$message(error.toString()));
    },

    handleRadioGroupChange: function (val) {
      console.log(val);
      switch (val) {
        case 1:
          this.form.testCountDown = 0;
          this.form.enTypeCountDown = 12;
          return;
        case 2:
          this.form.testCountDown = 12;
          this.form.enTypeCountDown = 12;
          return;
        case 3:
          this.form.testCountDown = 12;
          this.form.enTypeCountDown = 0;
          return;
        case 4:
          this.form.testCountDown = 12;
          this.form.enTypeCountDown = 0;
          return;
        case 5:
          this.form.testCountDown = 12;
          this.form.enTypeCountDown = 12;
          return;
        case 6:
          this.form.testCountDown = 0;
          this.form.enTypeCountDown = 12;
          return;
      }
    },

    // handleCheckedTagChange: function(value) {

    // },

    handleOpen: function () {
      //先重置vocList
      this.vocList = null;
      // initialize range index
      this.form.startIndex = null;
      this.form.endIndex = null;
      // reset tags
      this.form.checkedTags = [];
      //console.log(this.task);
      //this.getVocabularies();
      //初始化本地的planDetail
      this.localPlanDetail = this.planDetail;
    },

    handleClose: function () {
      // console.log('aaaa')
      this.$emit("on-convert-all-task-to-test-form-close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mark-heart {
  color: deepskyblue;
}

.cursor {
  cursor: pointer;
}

.tags-check-group {
}
</style>
