<template>
  <div>
    <el-dialog
      width="30%"
      v-model="dialogFormVisible"
      title="生成测试"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-form-item label="选择学生">
        <el-row>
          <el-col :span="12">
            <remote-search-student-inputbox
              @on-remote-student-selected="handleRemoteStudentSelected"
            />
          </el-col>
          <el-col :span="12">
            <remote-search-grade-inputbox
              @get-students-by-grade="handleRemoteGradeSelected"
            />
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="已选学生">
        <span
          v-for="(item, index) in form.selectedStudents"
          :key="index"
          class="selected-students cursor"
          @click="handleSelectedStudentClick(item)"
        >
          <span>{{ item.chineseName }}</span>
        </span>
      </el-form-item>

      <el-form-item label="自动发布">
        <el-switch
          v-model="form.publishSwitch"
          active-color="#13ce66"
          inactive-color="gray"
        />
      </el-form-item>

      <el-form-item label="测试名称">
        <el-input
          v-model="form.testName"
          placeholder="请输入测试名称"
          @keydown.stop
        >
        </el-input>
      </el-form-item>

      <el-form-item label="测试描述">
        <el-input
          v-model="form.testDescription"
          placeholder="请输入测试描述"
          @keydown.stop
        >
        </el-input>
      </el-form-item>

      <el-form :model="form">
        <el-form-item label="测试模式">
          <el-radio-group
            v-model="form.testMode"
            @change="handleRadioGroupChange($event)"
          >
            <el-radio :label="1">听写英文</el-radio>
            <el-radio :label="2">听写中英</el-radio>
            <el-radio :label="3">听英写中</el-radio>
            <el-radio :label="4">看英写中</el-radio>
            <el-radio :label="5">语音测试</el-radio>
            <el-radio :label="6">看中写英</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <el-form-item label="测试类别">
        <el-select v-model="form.category" placeholder="请选择">
          <el-option
            v-for="item in form.categoryOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="播放次数">
        <el-input-number
          v-model="form.playCount"
          size="small"
          @keydown.stop
          placeholder="请输入播放次数"
        />
      </el-form-item>

      <el-form-item label="中文计时">
        <el-input-number
          v-model="form.testCountDown"
          :disabled="[1, 6].includes(form.testMode)"
          size="small"
          @keydown.stop
          placeholder="请输入每个中文倒计时"
        />
      </el-form-item>

      <el-form-item label="拼写计时">
        <el-input-number
          v-model="form.enTypeCountDown"
          :disabled="[3, 4].includes(form.testMode)"
          size="small"
          @keydown.stop
          placeholder="请输入每个单词倒计时"
        />
      </el-form-item>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onCreateTestFormCancel()">Cancel</el-button>
          <el-button type="primary" @click="onCreateTestFormConfirm()"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import RemoteSearchStudentInputbox from "../../components/admin/RemoteSearchStudentInputbox.vue";
import RemoteSearchGradeInputbox from "../../components/admin/RemoteSearchGradeInputbox.vue";
export default {
  components: { RemoteSearchStudentInputbox, RemoteSearchGradeInputbox },
  name: "CreateTestForm",

  props: ["modelValue", "vocList", "studentId", "origin", "userId"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  data() {
    return {
      dialogFormVisible: false,

      form: {
        selectedStudents: [],
        testName: "",
        testDescription: "",
        testMode: 1,
        publishSwitch: false,
        playCount: 2,
        testCountDown: 0,
        enTypeCountDown: 12,
        category: "",
        categoryOptions: [
          {
            value: "词测",
            label: "词测",
          },
          {
            value: "默写",
            label: "默写",
          },
          {
            value: "常规词汇机筛",
            label: "常规词汇机筛",
          },
          {
            value: "星标词检查",
            label: "星标词检查",
          },
        ],
      },
    };
  },

  // mounted: function() {
  //   console.log(this.vocList)
  //   console.log(this.studentId)
  //   console.log(this.origin)
  // },

  methods: {
    onCreateTestFormCancel: function () {
      this.dialogFormVisible = false;
      this.$emit("on-test-mode-form-cancel");
    },
    onCreateTestFormConfirm: function () {
      if (this.form.testName == "" || this.vocList.length <= 0) {
        this.$message("测试名称和单词集不能为空");
        return;
      }
      if (this.vocList.length > 300) {
        this.$message("测试词汇数量不能超过300");
        return;
      }

      //如果选择过学生（基于学生或班级），那么就先用学生组post测试，否则直接获取当前绑定学生来post测试
      if (this.form.selectedStudents && this.form.selectedStudents.length > 1) {
        for (let i = 0; i < this.form.selectedStudents.length; i++) {
          this.postTestByStudentId(this.form.selectedStudents[i].id);
        }
      } else {
        this.postTestByStudentId(this.studentId);
      }

      this.$emit("on-test-mode-form-confirm", this.form);
      this.dialogFormVisible = false;
    },

    postTestByStudentId: function (studentId) {
      let postData = {
        studentId: studentId,
        name: this.form.testName,
        type: this.form.testMode,
        origin: this.origin,
        description: this.form.testDescription,
        status: "standing",
        nirvanaCount: 0,
        createdBy: this.userId,
        published: this.form.publishSwitch,
        countDown: this.form.testCountDown,
        enAnswerCountDown: this.form.enTypeCountDown,
        category: this.form.category,
      };
      this.$store
        .dispatch("StudentTest/createEditStudentTest", postData)
        .then((response) => {
          this.addStudentVocabularyAnswers(response.data);
        })
        .catch((error) => this.$message(error.toString()));
    },

    addStudentVocabularyAnswers: function (testId) {
      let postData = this.vocList.map((item) => {
        return {
          vocStudentTestId: testId,
          vocabularyId: item.vocabularyId,
          listeningCount: this.form.playCount,
        };
      });
      this.$store
        .dispatch("StudentTest/addVocabularyAnswers", postData)
        .then((response) => {
          if (response.data) {
            this.$message({
              type: "success",
              message: "测试已生成",
            });
          }
        })
        .catch((error) => this.$message(error.toString()));
    },

    handleRemoteStudentSelected: function (student) {
      //调整一下student的数据结构，以便适配班级学生
      student.id = student.userId;

      this.form.selectedStudents.push(student);
    },

    handleRemoteGradeSelected: function (studentsInGrade) {
      this.form.selectedStudents = [
        ...this.form.selectedStudents,
        ...studentsInGrade,
      ];
    },

    handleSelectedStudentClick: function (val) {
      this.form.selectedStudents.splice(
        this.form.selectedStudents.findIndex((item) => item.id === val.id),
        1
      );
    },

    handleRadioGroupChange: function (val) {
      console.log(val);
      switch (val) {
        case 1:
          this.form.testCountDown = 0;
          this.form.enTypeCountDown = 12;
          return;
        case 2:
          this.form.testCountDown = 12;
          this.form.enTypeCountDown = 12;
          return;
        case 3:
          this.form.testCountDown = 12;
          this.form.enTypeCountDown = 0;
          return;
        case 4:
          this.form.testCountDown = 12;
          this.form.enTypeCountDown = 0;
          return;
        case 5:
          this.form.testCountDown = 12;
          this.form.enTypeCountDown = 12;
          return;
        case 6:
          this.form.testCountDown = 0;
          this.form.enTypeCountDown = 12;
          return;
      }
    },

    // handleCheckedTagChange: function(value) {

    // },

    handleOpen: function () {
      // initialize range index
      this.form.startIndex = null;
      this.form.endIndex = null;
      // reset tags
      this.form.checkedTags = [];
    },

    handleClose: function () {
      // console.log('aaaa')
      this.$emit("on-tool-config-form-close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mark-heart {
  color: deepskyblue;
}

.cursor {
  cursor: pointer;
}

.tags-check-group {
}

.selected-students {
  margin-right: 1rem;
}
</style>
