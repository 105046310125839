import axios from "axios";
import url from "../urls";

// 给listening 的非login请求需要再发request之前将withCredentials设置成false
// httpsAgent用于防止到listening的https backend请求被拒绝；因为listening的https请求没有证书
const service = axios.create({
  withCredentials: false,
});

export default {
  login(context, userInfo) {
    return new Promise((resolve, reject) => {
      localStorage.clear();
      //context.commit("mutateUserInfo", userInfo);
      let loginUrl = url.loginUrl;
      service
        .get(loginUrl, {
          params: {
            username: userInfo.username,
            password: userInfo.password,
          },
        })
        .then((response) => {
          if (response.status == "200") {
            localStorage.setItem("userId", response.data.userId);
            localStorage.setItem("userName", response.data.userName);
            localStorage.setItem("userCNName", response.data.chineseName);
            localStorage.setItem("targetId", response.data.userId);
            localStorage.setItem("userRole", response.data.roleName);
            localStorage.setItem("enabled", response.data.enabled);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem(
              "changePassword",
              userInfo.password === "123456" ? true : false
            );
            resolve(response);
          }
        })
        .catch((error) => reject(error));
    });
  },

  changePassword(context, data) {
    return new Promise((resolve, reject) => {
      let murl = url.ListeningChangePassword;
      axios
        .post(murl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
