let SETPROCESSOPTIONS = [
  {
    id: 1,
    name: "随机",
  },{
    id: 2,
    name: "星标",
  },
  {
    id: 3,
    name: "空心",
  },{
    id: 4,
    name: "黑色",
  },
  {
    id: 5,
    name: "范围",
    from: 0,
    to: 0
  },
  {
    id: 6,
    name: "标签",
  },
];

export {
  SETPROCESSOPTIONS,
};
