import axios from "axios";
import url from "../urls";

// const service = axios.create({
//   headers : {
//     Authorization: "Bearer " + localStorage.getItem("token"),
//     "Content-Type": "application/json"
//   }
// })

export default {
  //put
  add(context, data) {
    return new Promise((resolve, reject) => {
      let ssUrl = url.subjectSetUrl;
      axios
        .put(ssUrl, data)
        .then((response) => {
          // context.commit("mutateAddedProject", {
          //   projectId: 5,
          //   projectName: data.name,
          // });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //get
  get(context, data) {
    return new Promise((resolve, reject) => {
      let ssUrl = url.subjectSetUrl;
      axios
        .get(ssUrl, {
          params: {
            assignmentId: data.assignmentId,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //post
  edit(context, data) {
    return new Promise((resolve, reject) => {
      let ssUrl = url.subjectSetUrl;
      axios
        .post(ssUrl, data)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //delete
  delete(context, data) {
    return new Promise((resolve, reject) => {
      let ssUrl = url.subjectSetUrl;
      axios
        .delete(ssUrl, {data: data})
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
