<template>
  <div>
    <shared-header />
    <div class="assign-to-student">
      <div class="tool-bar">
        <el-button
          @click="handleOnBoundStudent"
          type="primary"
          round
          size="small"
        >
          选择学生
        </el-button>
        <el-button
          type="primary"
          @click="handlePublishAssignment"
          round
          size="small"
          >发布作业</el-button
        >

        <el-input
          v-model="assignmentName"
          size="default"
          placeholder="输入作业名"
          style="width: 300px; margin-left: 10px"
        />

        <el-button
          type="primary"
          @click="handleCheckAssignment"
          round
          size="small"
          style="margin-left: 10px"
          >查看作业</el-button
        >
      </div>

      <el-divider></el-divider>
      <bound-student-list-drawer
        ref="boundStudentListDrawer"
        @on-bound-student-click="handleOnBoundStudentClick"
      />

      <el-row
        style="border: 1px solid #b9b9b9; border-radius: 5px; padding: 10px"
      >
        <el-col :span="14">
          <el-row>
            <el-col :span="12">
              <el-input
                v-model="setNameSearchString"
                placeholder="此处输入集合名称来过滤集合"
              ></el-input>
            </el-col>
            <el-col :offset="5" :span="2">
              <el-input v-model.number="setRangeFrom"></el-input>
            </el-col>
            <el-col :span="1"> </el-col>
            <el-col :span="2">
              <el-input v-model.number="setRangeTo"></el-input>
            </el-col>
            <el-col :span="2">
              <el-button type="primary" @click="handleSetRange"
                >Range</el-button
              >
            </el-col>
          </el-row>

          <el-checkbox
            v-model="checkAllSets"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
            >Check all</el-checkbox
          >
          <div class="set-check-box-group">
            <el-checkbox-group
              v-model="checkedSets"
              @change="handleCheckedSetsChange"
            >
              <el-checkbox
                v-for="(set, index) in setList.filter(
                  (data) =>
                    !setNameSearchString ||
                    data.name
                      .toLowerCase()
                      .includes(setNameSearchString.toLowerCase())
                )"
                :key="set.name"
                :label="set.id"
                >{{ set.name
                }}<span
                  class="set-name-group"
                  @click="handleDeleteSetClick(set, index, setList)"
                ></span
              ></el-checkbox>
            </el-checkbox-group>
          </div>
        </el-col>
        <el-col :offset="4" :span="4">
          <el-table :data="assignedStudents" border style="width: 100%">
            <el-table-column label="Student Name" prop="chineseName">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="120">
              <template #default="scope">
                <el-button
                  @click.stop="deleteStudentRow(scope.$index, assignedStudents)"
                  type="text"
                  size="small"
                >
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import SharedHeader from "../../shared/SharedHeader.vue";
import BoundStudentListDrawer from "../../admin/BoundStudentListDrawer.vue";

export default {
  name: "AssignToStudent",

  components: {
    SharedHeader,
    BoundStudentListDrawer,
  },

  computed: {},

  mounted: function () {
    this.getSetsBySubjectId(72);
  },

  data() {
    return {
      studentDrawer: false,
      filterString: "",
      userNameFilter: "",
      setNameSearchString: "",
      setList: [],
      checkAllSets: false,
      isIndeterminate: true,
      checkedSets: [],
      setRangeFrom: 1,
      setRangeTo: 1,
      assignmentList: [],
      assignedStudents: [],
      assignmentName: "",
    };
  },

  methods: {
    openAsNewPage: function (routeName, query) {
      let newpage = this.$router.resolve({
        path: routeName,
        query: query,
      });
      window.open(newpage.href, "_blank");
    },

    handleOnBoundStudent: function () {
      this.$refs.boundStudentListDrawer.openDrawer();
    },

    handleOnBoundStudentClick: function (studentRow) {
      if (this.assignedStudents.indexOf(studentRow) == -1)
        this.assignedStudents.push(studentRow);
    },

    deleteStudentRow(index, rows) {
      rows.splice(index, 1);
    },

    getSetsBySubjectId: function (subjectId) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("Set/getSetsBySubjectId", {
            subjectId: subjectId,
          })
          .then((response) => {
            this.setList = response.data;
            this.getTpoSetsBySubjectId().then(
              (res) => {this.setList = this.setList.concat(res.data)
              this.setList.push({
                id: 528,
                name: "词频1-50",
                userid: 853,
                deleted: false,
              })}
            );
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getTpoSetsBySubjectId: function () {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("Set/getSetsBySubjectId", {
            subjectId: 71,
          })
          .then((response) => {
            //拿到ufo和tpo的set list之后，在手动hard code加入词频1-50的set id和名字，因为现在系统中没有完整的词频，只有第一个set的1-50； 这个set的id是528

            console.log(response)
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    handleCheckAllChange: function (event) {
      let tempCheckedSets = [];
      let tempCheckedAllSetList = this.setList.filter(
        (data) =>
          !this.setNameSearchString ||
          data.name
            .toLowerCase()
            .includes(this.setNameSearchString.toLowerCase())
      );
      if (event) {
        for (let i = 0; i < tempCheckedAllSetList.length; i++) {
          tempCheckedSets.push(tempCheckedAllSetList[i].id);
        }
      }

      this.checkedSets = event ? tempCheckedSets : [];
      this.isIndeterminate = false;
    },

    handleSetRange: function () {
      let tempCheckedSets = [];
      let tempCheckedAllSetList = this.setList.filter(
        (data) =>
          !this.setNameSearchString ||
          data.name
            .toLowerCase()
            .includes(this.setNameSearchString.toLowerCase())
      );

      //如果最终数值大于length，则设置为length
      this.setRangeTo =
        this.setRangeTo > this.setList.length
          ? this.setList.length
          : this.setRangeTo;
      for (let i = this.setRangeFrom - 1; i < this.setRangeTo; i++) {
        if (tempCheckedAllSetList[i] && tempCheckedAllSetList[i].id)
          tempCheckedSets.push(tempCheckedAllSetList[i].id);
      }

      this.checkedSets = tempCheckedSets;
      this.isIndeterminate = false;
    },

    handleCheckedSetsChange: function (event) {
      const checkedCount = event.length;
      this.checkAllSets = checkedCount === this.setList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.setList.length;
    },

    clearCheckedSets: function () {
      this.checkAllSets = false;
      this.isIndeterminate = true;
      this.checkedSets = [];
    },

    handlePublishAssignment: function () {
      if (
        this.assignmentName &&
        this.checkedSets.length > 0 &&
        this.assignedStudents.length > 0
      ) {
        for (let i = 0; i < this.assignedStudents.length; i++) {
          let data = {
            teacherId: localStorage.getItem("userId"),
            studentId: this.assignedStudents[i].targetId,
            name: this.assignmentName,
          };
          this.$store
            .dispatch("VIP/addAssignment", data)
            .then((response) => {
              console.log(this.checkedSets);
              //返回的response是新建的assignment id
              this.$store
                .dispatch("VIP/assignSetsToStudent", {
                  vocAssignmentId: response.data,
                  setIds: this.checkedSets,
                })
                .then((response) => {
                  if (response.data > 0) {
                    this.$message({
                      type: "success",
                      message: "布置完成",
                    });
                  } else {
                    this.$message("布置失败");
                  }
                })
                .catch((error) => this.$message(error.toString()));
            })
            .catch((error) => this.$message(error.toString()));
        }
      } else {
        this.$message("请确认 作业名，作业内容，以及学生列表 均已填写");
      }
    },

    handleStoreAssignmentConfirmed: function () {
      let cookedStoredAssignment = this.cookAssignmentListForStoration();
      console.log(cookedStoredAssignment);
      if (
        cookedStoredAssignment == undefined ||
        cookedStoredAssignment.length <= 0
      ) {
        this.$message("请选择至少一个作业...");
        this.storedAssignmentNameDialog = false;
        return;
      }
      if (cookedStoredAssignment[0].assignmentName == "") {
        this.$message("请在弹出框中输入作业名...");
        return;
      }
      this.$store
        .dispatch(
          "assignmentExam/addTeacherStoredAssignments",
          cookedStoredAssignment
        )
        .then((response) => {
          if (response.data == false) {
            this.$message("作业名已存在，请重新输入...");
          } else {
            this.$message("保存成功");
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.storedAssignmentNameDialog = false;
    },

    handleCheckAssignment: function () {
      this.openAsNewPage("/checkstudentassignment", {});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.assign-to-student {
  margin-top: 20px;
}
.set-check-box-group {
  text-align: left;
}
</style>