<template>
  <div class="cd">
    <span>{{ countingTime }}</span>
  </div>
</template>

<script>
export default {
  name: "VocCountDown",

  computed: {},

  data() {
    return {
      countingTime: 0,
      cdInterval: null,
      paused: false,
    };
  },

  watch: {},

  created: function () {},

  mounted: function () {},

  methods: {
    countdown: function (countingTime, activate) {
      if (activate) {
        this.countingTime = countingTime;
        //调用这个函数的时候，如果intervalId是存在的，就先清空，然后进入一个新的倒计时
        if (this.cdInterval) clearInterval(this.cdInterval);
        this.cdInterval = setInterval(() => {
          //console.log(str.substr(0, index + 1));
          if (!this.paused) {
            this.countingTime--;
          }

          if (this.countingTime <= 0) {
            clearInterval(this.cdInterval);
            this.$emit("on-count-down-finished");
          }
        }, 1000);
      } else {
        //activate传入false的时候，直接清除计时
        if (this.cdInterval) clearInterval(this.cdInterval);
      }
    },

    togglePause: function (pause) {
      this.paused = pause;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cd {
  height: 20px;
}
</style>
