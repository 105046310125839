<template>
  <div v-if="isAdminOrTeacher">
    <shared-header
      ref="sharedHeader"
      @on-bound-student="handleOnBoundStudent()"
    />
    <bound-student-list-drawer
      ref="boundStudentListDrawer"
      @on-bound-student-click="handleOnBoundStudentClick"
    />
    <el-row>
      <el-col :span="8" :offset="6">
        <el-input
          v-model="input"
          placeholder="请输入学生姓名拼音或中文名"
        ></el-input>
      </el-col>
      <el-col :span="4">
        <el-button @click="searchStudents()">查询</el-button>
      </el-col>
    </el-row>

    <el-table
      :data="studentInfo"
      v-loading="loading"
      element-loading-text="我很努力在加载啦..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-table-column label="UserName" prop="targetName"></el-table-column>
      <el-table-column label="CNName" prop="chineseName"></el-table-column>
      <el-table-column label="Bind/Unbind">
        <template #default="scope">
          <!-- <el-button @click="bind(scope.row)">绑定</el-button>
          <el-button @click="unbind(scope.row)">解绑</el-button>-->
          <el-switch
            v-model="scope.row.Bound"
            @change="bindSwitch($event, scope.row.sourceId, scope.row.targetId)"
            active-color="#13ce66"
            inactive-color="gray"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button @click="resetStudentPassword(scope.row.targetId)"
            >重制密码</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- <el-row>
            <el-col :offset="4" :span="8">
                {{studentInfo.studentName}}
            </el-col>
            <el-col :span="2">
                <el-button>绑定</el-button>
            </el-col>
            <el-col :span="2">
                <el-button>解绑</el-button>
            </el-col>
    </el-row>-->
  </div>
</template>

<script>
import SharedHeader from "../shared/SharedHeader.vue";
import { ElMessage } from "element-plus";
import BoundStudentListDrawer from "./BoundStudentListDrawer.vue";
export default {
  name: "TeacherBindStudent",
  components: {
    SharedHeader,
    BoundStudentListDrawer,
  },
  data() {
    return {
      loading: false,
      input: "",
      value: true,
      studentInfo: null,
      isAdminOrTeacher: false,
    };
  },
  mounted: function () {
    this.getUserPermission();
    this.loading = true;
    document.addEventListener("keydown", this.handleKeyDown);
    var that = this;

    this.$store
      .dispatch("Admin/get", {
        userId: localStorage.getItem("userId"),
      })
      .then(function (response) {
        that.loading = false;
        that.studentInfo = response.data;
      })
      .catch(function (error) {
        that.loading = false;
        console.log(error);
      });
  },
  methods: {
    getUserPermission: function () {
      var userRole = localStorage.getItem("userRole");
      if (userRole === "Admin" || userRole === "Teacher") {
        this.isAdminOrTeacher = true;
      } else {
        this.isAdminOrTeacher = false;
      }
    },

    handleOnBoundStudent: function () {
      this.$refs.boundStudentListDrawer.openDrawer();
    },

    handleOnBoundStudentClick: function () {
      this.$refs.sharedHeader.updateBoundStudentChineseName();
    },

    bind: function (val) {
      ElMessage({
        message: "已绑定学生 " + val.TargetName,
        type: "success",
      });
      console.log(val.TargetName);
    },
    unbind: function (val) {
      ElMessage({
        message: "已绑定学生 " + val.TargetName,
        type: "success",
      });
      console.log(val.TargetName);
    },
    bindSwitch: function (event, sourceId, targetId) {
      let data = {
        sourceId: sourceId,
        targetId: targetId,
        enabled: event,
      };

      this.$store
        .dispatch("Admin/bindStudent", data)
        .then(() => {
          if (event === true) {
            ElMessage({
              message: "已绑定",
              type: "success",
            });
          } else {
            ElMessage("解除绑定");
          }
        })
        .catch((error) => console.log(error.toString()));
    },

    resetStudentPassword: function (targetId) {
      this.$store
        .dispatch("Login/changePassword", {
          userId: targetId,
          newPassword: "123456",
        })
        .then((response) => {
          if (response.data > 0) {
            this.$message({
              type: "success",
              message: "该学生密码已重制～",
            });
          } else {
            this.$message("糟糕，重制失败");
          }
        })
        .catch((error) => this.$message(error.toString()));
    },

    searchStudents: function () {
      this.loading = true;
      var _localThis = this;
      let data = {
        userId: localStorage.getItem("userId"),
        searchPattern: this.input,
      };
      this.$store
        .dispatch("Admin/get", data)
        .then(function (response) {
          _localThis.loading = false;
          _localThis.studentInfo = response.data;
        })
        .catch(function (error) {
          _localThis.loading = false;
          console.log(error);
        });
    },

    handleKeyDown: function (event) {
      if (event.keyCode === 13) {
        this.searchStudents();
      }
    },
  },

  beforeUnmount: function () {
    document.removeEventListener("keydown", this.handleKeyDown); // remove global key down event before leaving login page
  },
};
</script>

<style scoped>
.el-table {
  margin-left: 10%;
  width: 80%;
}
</style>
