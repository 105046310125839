<template>
  <div style="background-color: rgb(252, 252, 252)">
    <div class="main-content" style="background-color: white">
      <div>
        <div class="test-item">
          <p class="div-title">测试详情</p>
          <div class="test-item-details">
            <p>学生姓名：{{ decodedQueryObject.targetCNName }}</p>
            <p>测试类型：{{ testTypeString }}</p>
            <p>测试名称：{{ testItem.name }}</p>
            <p>测试来源：{{ testItem.origin }}</p>
            <p>测试描述：{{ testItem.description || "无" }}</p>
            <p v-if="testItem.countDown != 0">
              中文答对：{{
                result.cnCorrect + "/" + result.vocCount
              }}
              正确率：{{
                Math.round((result.cnCorrect / result.vocCount) * 10000) / 100 +
                "%"
              }}
            </p>
            <p v-if="testItem.enAnswerCountDown != 0">
              英文答对：{{
                result.enCorrect + "/" + result.vocCount
              }}
              正确率：{{
                Math.round((result.enCorrect / result.vocCount) * 10000) / 100 +
                "%"
              }}
            </p>
          </div>
        </div>
        <el-divider />
      </div>

      <div class="test-table">
        <el-table
          ref="singleTableRef"
          :data="testItem.studentAnswers"
          highlight-current-row
          style="width: 100%"
          @current-change="handleCurrentChange"
        >
          <el-table-column type="index" width="50" />

          <el-table-column property="englishText" label="英文">
            <template #default="scope">
              <div
                @click="
                  handleEnglishTextColumnClick($event, scope.row.englishText)
                "
              >
                {{ scope.row.englishText }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="testItem.enAnswerCountDown != 0"
            property="englishStudentAnswer"
            label="拼写"
          >
            <template #default="scope">
              <div class="english-answer-item">
                <el-input
                  v-model="scope.row.englishStudentAnswer"
                  :disabled="true"
                ></el-input>

                <audio
                  :id="'Audio' + scope.$index"
                  ref="audiostobeload"
                  :src="
                    '/vocabulary/audios/listeningAudios/vocAudios/' +
                    scope.row.englishText +
                    '.mp3'
                  "
                ></audio>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="testItem.enAnswerCountDown != 0"
            property="enAnswerResult"
            sortable
            label="英文正误"
            width="120"
          >
            <template #default="scope">
              <div
                v-if="
                  scope.row.englishStudentAnswer &&
                  scope.row.englishStudentAnswer.trim().toLowerCase() ==
                    scope.row.englishText.trim().toLowerCase()
                "
                class="english-answer-item-result-correct"
              >
                <el-icon :size="16" color="deepskyblue" style="align: center"
                  ><CircleCheckFilled
                /></el-icon>
              </div>
              <div v-else class="english-answer-item-result-incorrect">
                <el-icon :size="16" color="red" style="align: center"
                  ><CircleClose
                /></el-icon>
              </div>
            </template>
          </el-table-column>

          <el-table-column property="chineseText" label="释义">
            <template #default="scope">
              <div class="chinese-answer-item">
                <span v-html="scope.row.chineseText" />
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="testItem.countDown != 0"
            property="textConvertedByAudio"
            label="中文答案"
          >
            <template #default="scope">
              <div class="chinese-answer-item">
                {{ scope.row.textConvertedByAudio }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="testItem.countDown != 0"
            property="isCorrect"
            label="中文正误"
            width="100"
          >
            <template #default="scope">
              <div
                v-if="scope.row.isCorrect"
                class="english-answer-item-result-correct"
              >
                <el-icon
                  :size="16"
                  color="deepskyblue"
                  style="align: center; cursor: pointer"
                  @click="
                    handlecnAnswerResultClick(
                      scope.$index,
                      false,
                      testItem.studentId
                    )
                  "
                  ><CircleCheckFilled
                /></el-icon>
              </div>
              <div v-else class="english-answer-item-result-incorrect">
                <el-icon
                  :size="16"
                  color="red"
                  style="align: center; cursor: pointer"
                  @click="
                    handlecnAnswerResultClick(
                      scope.$index,
                      true,
                      testItem.studentId
                    )
                  "
                  ><CircleClose
                /></el-icon>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="testItem.countDown != 0"
            property="audioBase64str"
            label="答案音频"
            width="300"
          >
            <template #default="scope">
              <audio controls>
                <source
                  :src="`data:audio/wav;base64,${scope.row.audioBase64str}`"
                  type="audio/wav"
                />
              </audio>
            </template>
          </el-table-column>
          <el-table-column property="listeningCount" label="left" width="50">
          </el-table-column>
        </el-table>
      </div>
      <div>
        <audio ref="vocaudio"></audio>
      </div>
    </div>
  </div>
</template>

<script>
// import pinyin from "pinyin";
export default {
  name: "AudioTestItemResult",

  components: {},

  computed: {
    testTypeString() {
      switch (this.testItem.type) {
        case 1:
          return "听写英文";
        case 2:
          return "听写中英";
        case 3:
          return "听英写中";
        case 4:
          return "看英写中";
        case 5:
          return "语音测试";
        default:
          return "";
      }
    },
  },

  data() {
    return {
      testItem: {},
      currentRow: {},
      showCreateTestForm: false,
      result: {
        haveEnResult: false,
        haveCnResult: false,
        enCorrect: 0,
        cnCorrect: 0,
        bothCorrect: 0,
        vocCount: 0,
      },
      nirvanaVocList: [],
      currentOverviewId: null,
      userRole: "",
    };
  },

  beforeMount: function () {
    // decode query string into object
    let queryString = window.atob(this.$route.query.encodedString);
    queryString = window.decodeURIComponent(queryString);
    this.decodedQueryObject = JSON.parse(queryString);
    console.log(this.decodedQueryObject);
  },

  mounted: function () {
    //初始化用户角色
    this.userRole = localStorage.getItem("userRole");
    this.getTestById(
      this.decodedQueryObject.id,
      this.decodedQueryObject.studentId
    );

    // console.log(
    //   pinyin("你好", {
    //     style: pinyin.STYLE_NORMAL,
    //   }).join('')
    // );
  },

  methods: {
    getTestById: function (testId, studentId) {
      this.$store
        .dispatch("StudentTest/getTestById", {
          id: testId,
          userId: studentId,
        })
        .then((response) => {
          //测试页面可能是从task任务进的，这时候decodedQueryObject不完整，需要补齐
          //如果query里面有task就说明是task过来的，所以初始化
          if (this.decodedQueryObject.task) {
            this.initializeDecodedQueryObject(response.data);
          }

          this.testItem = response.data;

          //在处理多义词之前，加入每个词测试的耗时（多义词当成一个词）
          this.addTimeConsumed();

          // 处理多义词
          this.processMultimeaningVoc();
          //有了isCorrect之后，就不用每次拿的时候去process student answers了
          //this.processStudentAnswers();
          //生成一个answer result久可以；这个函数在processStudentAnswers内部调用，所以取消process之后，在这里要补上
          this.generateAnswerResult();

          console.log(this.decodedQueryObject.submitted);
          console.log(this.navigationType);
          // 这里需要把decodedQueryObject一些需要的值赋值一下，因为现在test页面可能是有task redirect过来的，不包含decodedQueryObject里面的内容
          //如果是test页面submit过来的，就需要提交一次result，对应相应的task，提交task result
          //navigationType 0说明是跳转，防止result页面刷新的时候重复提交结果
          //这里navigationType是null了，所以，暂时取消navigationType，仅用submitted来判断是否是从测试页面提交到result页面
          //if (this.decodedQueryObject.submitted && this.navigationType === 0) {
          if (this.decodedQueryObject.submitted) {
            //提交task result;用this.result 数据
            this.addPlanDetailTaskResult();
          }
        })
        .catch((error) => {
          this.$message(error.toString());
        });
    },

    addPlanDetailTaskResult: function () {
      let totalTimeConsumed = 0;
      for (let i = 1; i < this.testItem.studentAnswers.length; i++) {
        totalTimeConsumed =
          totalTimeConsumed + this.testItem.studentAnswers[i].timeConsumed > 0
            ? this.testItem.studentAnswers[i].timeConsumed
            : 0;
      }
      let postData = {
        studentPlanDetailTaskId: this.decodedQueryObject.task.id,
        incorrectCount: this.result.haveCnResult
          ? this.result.vocCount - this.result.cnCorrect
          : this.result.vocCount - this.result.enCorrect,
        checkedCount: this.result.vocCount,
        incorrects: "", //哪些词错误，以及助教写的文本
        generatedResult:
          (this.result.haveCnResult
            ? "中文：-" +
              (this.result.vocCount - this.result.cnCorrect) +
              "/" +
              this.result.vocCount
            : "") +
          (this.result.haveEnResult
            ? "英文：-" +
              (this.result.vocCount - this.result.enCorrect) +
              "/" +
              this.result.vocCount
            : ""), //系统copy生产的检查结果
        timeConsumed: totalTimeConsumed / 60, //:precision="2"
        evaluation: "",
        passed:
          (this.result.cnCorrect / this.result.vocCount) * 100 >
          this.decodedQueryObject.task.criterion
            ? true
            : false,
      };

      this.$store
        .dispatch("StudentPlan/addOrEditStudentPlanDetailTaskResult", postData)
        .then(() => {
          this.$message({
            type: "success",
            message: "任务结果已提交～",
          });
        });
    },

    initializeDecodedQueryObject: function (testData) {
      let task = this.decodedQueryObject.task;
      let submitted = this.decodedQueryObject.submitted;
      //testItem也就是testData先整体赋值给decodedQueryObject，然后调整
      this.decodedQueryObject = testData;
      this.decodedQueryObject.task = task || null;
      this.decodedQueryObject.submitted = submitted || null;
      this.decodedQueryObject.targetCNName =
        localStorage.getItem("targetCNName") ||
        localStorage.getItem("userCNName");
    },

    addTimeConsumed: function () {
      //现在的testItem里面的studentAnswers还没有处理成多义词
      for (let i = 1; i < this.testItem.studentAnswers.length; i++) {
        const timeDiff =
          new Date(this.testItem.studentAnswers[i].updatedDate).getTime() -
          new Date(this.testItem.studentAnswers[i - 1].updatedDate).getTime();
        // Convert milliseconds to seconds
        const timeDiffInSeconds = Math.floor(timeDiff / 1000);
        this.testItem.studentAnswers[i].timeConsumed = timeDiffInSeconds;
      }
    },

    processMultimeaningVoc: function () {
      for (let i = 0; i < this.testItem.studentAnswers.length; i++) {
        let extraMeaningCount = this.extraMultimeaningCount(
          this.testItem.studentAnswers[i]
        );
        if (extraMeaningCount > 0) {
          // 添加一个多义词的index标记
          this.testItem.studentAnswers[i].multiMeaningIndex = 1;
          let multiStudentAnswersArray = [];
          let multiAudioBase64str = [];
          if (
            this.testItem.studentAnswers[i].textConvertedByAudio &&
            this.testItem.studentAnswers[i].textConvertedByAudio.length > 0 &&
            this.testItem.studentAnswers[i].textConvertedByAudio.includes("&")
          ) {
            multiStudentAnswersArray = this.testItem.studentAnswers[
              i
            ].textConvertedByAudio
              .replace("null", "")
              .replace("undefined", "")
              .split("&");
            let multiFirstAnswerArray = multiStudentAnswersArray[0].split("|");
            this.testItem.studentAnswers[i].textConvertedByAudio =
              multiFirstAnswerArray[0];
            this.testItem.studentAnswers[i].isCorrect =
              multiFirstAnswerArray[1] == "true" ? true : false;

            //结果页面在拿答案的时候需要比测试页面多一个多义词每个词的逐个判断isCorrect
            //因为多义词答案合并以后，isCorrect是bool类型，无法直接用字符串合并和拆分
            //用拿到的convertedText重新判断一次所否正确，并更新isCorrect
            //this.processCnAnswer(i);
          }
          if (
            this.testItem.studentAnswers[i].audioBase64str &&
            this.testItem.studentAnswers[i].audioBase64str.length > 0 &&
            this.testItem.studentAnswers[i].audioBase64str.includes("&")
          ) {
            multiAudioBase64str =
              this.testItem.studentAnswers[i].audioBase64str.split("&");
            this.testItem.studentAnswers[i].audioBase64str =
              multiAudioBase64str[0] ? multiAudioBase64str[0] : "";
          }
          for (let j = 0; j < extraMeaningCount; j++) {
            this.testItem.studentAnswers.splice(
              i + j,
              0,
              JSON.parse(JSON.stringify(this.testItem.studentAnswers[i + j]))
            );
            this.testItem.studentAnswers[i + 1 + j].multiMeaningIndex += 1;
            let multiLoopedAnswerArray = multiStudentAnswersArray[1 + j]
              ? multiStudentAnswersArray[1 + j].split("|")
              : "";
            this.testItem.studentAnswers[i + 1 + j].textConvertedByAudio =
              multiLoopedAnswerArray[0] ? multiLoopedAnswerArray[0] : "";
            this.testItem.studentAnswers[i + 1 + j].isCorrect =
              multiLoopedAnswerArray[1] == "true" ? true : false;
            this.testItem.studentAnswers[i + 1 + j].audioBase64str =
              multiAudioBase64str[1 + j] ? multiAudioBase64str[1 + j] : "";
            //在多义词的循环里也更新一下cnAnswer并更新isCorrect
            //this.processCnAnswer(i + 1 + j);
          }
        }
        //新增了几个单词，所以遍历的index要跳过这几个新加的单词
        i += extraMeaningCount;
      }
    },

    extraMultimeaningCount: function (voc) {
      let multiMarker = ["②", "③", "④", "⑤", "⑥", "⑦", "⑧", "⑨"];
      // let lastMultiMeaningNumber = "";
      let extraMeaningCount = 0;
      for (let i = 0; i < multiMarker.length; i++) {
        if (voc.chineseText.includes(multiMarker[i])) {
          // lastMultiMeaningNumber = multiMarker[i];
          extraMeaningCount = i + 1;
        }
      }
      return extraMeaningCount;
    },

    addMark: function (studentId, vocabulary) {
      this.$store
        .dispatch("Vocabulary/markVocabulary", {
          userId: studentId,
          vocabularyId: vocabulary.vocabularyId,
          count: vocabulary.count + 1,
          marked: true,
          updatedBy: -1,
        })
        .then(() => {})
        .catch((error) => console.log(error.toString()));
    },

    cancelMark: function (studentId, vocabulary) {
      //不太确定这里为什么有这个return的逻辑
      //先取消这个if逻辑，不确定会不会有额外的bug，先观察一下
      //if (!vocabulary.marked || vocabulary.count <= 0) return;

      this.$store
        .dispatch("Vocabulary/markVocabulary", {
          userId: studentId,
          vocabularyId: vocabulary.vocabularyId,
          count: vocabulary.count - 1,
          marked: false,
          updatedBy: -1,
        })
        .then(() => {
          console.log("cancel mark");
        })
        .catch((error) => console.log(error.toString()));
    },

    minusMark: function (studentId, vocabulary) {
      if (!vocabulary.marked || vocabulary.count <= 0) return;
      if (vocabulary.count > 1) {
        this.$store
          .dispatch("Vocabulary/markVocabulary", {
            userId: studentId,
            vocabularyId: vocabulary.vocabularyId,
            count: vocabulary.count - 1,
            marked: true,
            updatedBy: -1,
          })
          .then(() => {
            console.log("minus mark");
          })
          .catch((error) => console.log(error.toString()));
      } else {
        this.$store
          .dispatch("Vocabulary/markVocabulary", {
            userId: studentId,
            vocabularyId: vocabulary.vocabularyId,
            count: vocabulary.count - 1,
            marked: false,
            updatedBy: localStorage.getItem("userId") || studentId,
          })
          .then(() => {})
          .catch((error) => console.log(error.toString()));
      }
    },

    tagVoc: function (vocabularyId, studentId) {
      let postData = {
        vocabularyId: vocabularyId,
        userId: studentId,
        vocTagId: 1,
        deleted: false,
      };
      this.$store
        .dispatch("Vocabulary/tagVocabulary", postData)
        .then(() => {})
        .catch((error) => console.log(error.toString()));
    },

    // processStudentAnswers: function () {
    //   for (let i = 0; i < this.testItem.studentAnswers.length; i++) {
    //     if (!this.testItem.studentAnswers[i].textConvertedByAudio) {
    //       //this.testItem.studentAnswers[i].enAnswerResult = false;
    //       this.testItem.studentAnswers[i].isCorrect = false;
    //       //this.testItem.studentAnswers[i].bothResult = false;
    //       continue;
    //     }
    //     this.processAnswerByTestType(i);
    //   }
    //   this.generateAnswerResult();
    // },

    // processAnswerByTestType: function (answerLoopIndex) {
    //   //this.result.haveEnResult = false;
    //   this.result.haveCnResult = true;
    //   this.processCnAnswer(answerLoopIndex);
    //   //if (this.testItem.studentAnswers[answerLoopIndex].cnAnswerResult)
    //   //  this.testItem.studentAnswers[answerLoopIndex].bothResult = true;
    // },

    isSameAsPreMulti: function (answerLoopIndex) {
      //先考虑考虑多义词的情况，如果multiMeaningIndex有值，且大于1，则进入多义词判断，如果和前面写得一样，就判错，直接return，不进去下面的代码块
      if (
        this.testItem.studentAnswers[answerLoopIndex].multiMeaningIndex &&
        this.testItem.studentAnswers[answerLoopIndex].multiMeaningIndex > 1
      ) {
        let multiMeaningIndex =
          this.testItem.studentAnswers[answerLoopIndex].multiMeaningIndex;
        for (
          let i = answerLoopIndex - multiMeaningIndex + 1;
          i < answerLoopIndex;
          i++
        ) {
          if (
            this.testItem.studentAnswers[answerLoopIndex]
              .textConvertedByAudio ==
            this.testItem.studentAnswers[i].textConvertedByAudio
          ) {
            this.testItem.studentAnswers[answerLoopIndex].isCorrect = false;

            return true;
          }
        }
      }

      //只有找到与前面多义词一致的答案的时候，return true，并且标记为not correct；
      //循环走完仍然没有return true，说明没找到一样的答案，return false
      return false;
    },

    // processCnAnswer: function (answerLoopIndex) {
    //   this.testItem.studentAnswers[answerLoopIndex].isCorrect = false;
    //   //如果答案为空，则算错
    //   if (!this.testItem.studentAnswers[answerLoopIndex].textConvertedByAudio) {
    //     this.testItem.studentAnswers[answerLoopIndex].isCorrect = false;
    //     return;
    //   }

    //   if (this.isSameAsPreMulti(answerLoopIndex)) return;
    //   //如果学生答案在未处理的情况下就有corrected answer的匹配，则直接判定为正确（为了修复有标点符号而学生答案被一分为二，而不能识别的bug，比如答案：实施，组织，在corrected answer中有匹配，但是分为
    //   //两个答案分别是实施，和组织，就没有匹配了
    //   if (
    //     this.testItem.studentAnswers[answerLoopIndex].correctedAnswers
    //       .map((item) => item && item.trim())
    //       .includes(
    //         this.testItem.studentAnswers[
    //           answerLoopIndex
    //         ].textConvertedByAudio.trim()
    //       )
    //   ) {
    //     this.testItem.studentAnswers[answerLoopIndex].isCorrect = true;
    //     return;
    //   }
    //   //先找corrected answer中的答案做匹配，如果找到匹配的，则为正确，然后return
    //   let stuAnswerArr = this.testItem.studentAnswers[
    //     answerLoopIndex
    //   ].textConvertedByAudio
    //     .trim()
    //     .split(/[,，.。、;；:：?？!！ ]/);
    //   for (let i = 0; i < stuAnswerArr.length; i++) {
    //     if (
    //       this.testItem.studentAnswers[answerLoopIndex].correctedAnswers
    //         .map((item) => item && item.trim())
    //         .includes(stuAnswerArr[i])
    //     ) {
    //       this.testItem.studentAnswers[answerLoopIndex].isCorrect = true;
    //       return;
    //     }
    //   }

    //   //如果在corrected answers中没有找到匹配的，则进入单词卡“释义”中处理中文文本，匹配答案
    //   let cnOriginalTextArray = this.generateOriginalCnTextArray(
    //     this.testItem.studentAnswers[answerLoopIndex].chineseText
    //   );
    //   for (let i = 0; i < cnOriginalTextArray.length; i++) {
    //     let tempOriginalText = cnOriginalTextArray[i].trim();

    //     for (let j = 0; j < stuAnswerArr.length; j++) {
    //       let tempStudentCnAnswer = stuAnswerArr[j];
    //       if (tempOriginalText == tempStudentCnAnswer) {
    //         this.testItem.studentAnswers[answerLoopIndex].isCorrect = true;
    //         return;
    //       }
    //     }
    //   }
    // },

    handlecnAnswerResultClick: function (index, isRight, studentId) {
      if (localStorage.getItem("userRole") == "Student") return;
      let extraMeaningCount = this.extraMultimeaningCount(
        this.testItem.studentAnswers[index]
      );
      this.testItem.studentAnswers[index].isCorrect = isRight;
      if (extraMeaningCount <= 0) {
        //update 本地的result div box里的内容
        isRight ? (this.result.cnCorrect += 1) : (this.result.cnCorrect -= 1);
        if (!isRight) {
          this.addMark(studentId, this.testItem.studentAnswers[index]);
        } else {
          this.cancelMark(studentId, this.testItem.studentAnswers[index]);
        }
        //本地修改对错后，把isRight的值更新到本条学生answer里面
        let postData = {
          studentTestVocabularyAnswerId: this.testItem.studentAnswers[index].id,
          isCorrect: isRight,
        };
        console.log(postData);
        this.$store
          .dispatch("StudentTest/correctStudentAudioAnswer", postData)
          .then(() => {})
          .catch((error) => {
            this.$message(error.toString());
          });
      } else {
        //处理多义词正确与否的更新
        let extraMeaningStartIndex =
          index - this.testItem.studentAnswers[index].extraMeaningIndex;

        let tempMultiAnswers =
          this.testItem.studentAnswers[extraMeaningStartIndex]
            .textConvertedByAudio +
          "|" +
          this.testItem.studentAnswers[extraMeaningStartIndex].isCorrect;
        let tempMultiAudioBase64Str =
          this.testItem.studentAnswers[extraMeaningStartIndex].audioBase64str;

        for (let j = 0; j < extraMeaningCount; j++) {
          // 合并音频转出的文本
          tempMultiAnswers =
            tempMultiAnswers +
            "&" +
            this.testItem.studentAnswers[extraMeaningStartIndex + j + 1]
              .textConvertedByAudio +
            "|" +
            this.testItem.studentAnswers[extraMeaningStartIndex + j + 1]
              .isCorrect;

          tempMultiAudioBase64Str =
            tempMultiAudioBase64Str +
            "&" +
            this.testItem.studentAnswers[extraMeaningStartIndex + j + 1]
              .audioBase64str;
        }

        //更新当前单词的答案
        //当前的词汇
        let multiStartVoc =
          this.testItem.studentAnswers[extraMeaningStartIndex];

        //更新一下多义词整体最终的对错
        let isMultiCorrect = true;
        if (isRight) {
          //如果是改对的情况，就看看最终是不是全对，如果是就cnCorrect +1

          for (let i = 0; i < extraMeaningCount + 1; i++) {
            if (
              !this.testItem.studentAnswers[extraMeaningStartIndex + i]
                .isCorrect
            ) {
              isMultiCorrect = false;
            }
          }
          if (isMultiCorrect) {
            this.result.cnCorrect += 1;
          }
        } else {
          //如果多义词被判断为错，就标记“一词多义”
          this.tagVoc(
            this.testItem.studentAnswers[index].vocabularyId,
            studentId
          );
          let falseCount = 0;
          isMultiCorrect = false;
          for (let i = 0; i < extraMeaningCount + 1; i++) {
            if (
              !this.testItem.studentAnswers[extraMeaningStartIndex + i]
                .isCorrect
            ) {
              falseCount += 1;
            }
          }
          if (falseCount == 1) {
            this.result.cnCorrect -= 1;
          }
        }

        this.postVocStudentTestVocabularyAnswer({
          id: multiStartVoc.id,
          listeningCount: multiStartVoc.listeningCount,
          audioBase64str: tempMultiAudioBase64Str,
          textConvertedByAudio: tempMultiAnswers,
          englishStudentAnswer: multiStartVoc.englishStudentAnswer,
          isCorrect: isMultiCorrect,
        });
      }
    },

    postVocStudentTestVocabularyAnswer: function (postData) {
      this.$store
        .dispatch("StudentTest/editVocStudentTestVocabularyAnswer", postData)
        .then()
        .catch(() => {
          this.$message("词汇未提交，请检查网络是否稳定");
        });
    },

    //新版Naive多了很多中文，比如 释义，例句，备注，需要在新版格式下，找到正确答案，并匹配
    generateOriginalCnTextArray: function (text) {
      let rawCnMeaningArray = [];
      if (text.includes("释义：")) {
        rawCnMeaningArray = this.retrieveCnMeaning(text);
      } else {
        rawCnMeaningArray.push(text);
      }

      //let cnTextArray = rawCnMeaning.split(/[,，.。、;；:：?？!！ ]/);
      let resultCnArray = [];
      for (let j = 0; j < rawCnMeaningArray.length; j++) {
        let cnTextArray = rawCnMeaningArray[j].split(/[,，.。、;；:：?？!！ ]/);
        for (let i = 0; i < cnTextArray.length; i++) {
          if (this.isChinese(cnTextArray[i])) {
            resultCnArray.push(cnTextArray[i].replace("…", ""));
            if (cnTextArray[i].includes("的"))
              resultCnArray.push(
                cnTextArray[i].replace("…", "").replace("的", "")
              );
            if (cnTextArray[i].includes("地"))
              resultCnArray.push(
                cnTextArray[i].replace("…", "").replace("地", "")
              );
          }
        }
      }

      return resultCnArray;
    },

    //从新版Naive中获取中文释义
    retrieveCnMeaning: function (text) {
      let substr = "释义：";
      let indices = [];
      for (const match of text.matchAll(new RegExp(substr, "g"))) {
        indices.push(match.index + "释义：".length);
      }
      let result = [];
      for (let i = 0; i < indices.length; i++) {
        const start = indices[i];
        const end = text.indexOf("<", start);
        result.push(text.substring(start, end));
      }
      return result;
    },
    //从新版Naive中获取中文释义

    isChinese: function (text) {
      //以前的regex必须完全匹配中文，包含标点就不算中文；换一个初步测试更合适的regex
      //let re = /^[\u4e00-\u9fa5]+$/;
      let re =
        /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/g;
      if (re.test(text)) return true;
      return false;
    },

    generateAnswerResult: function () {
      let allExtrameaningCount = 0;

      for (let i = 0; i < this.testItem.studentAnswers.length; i++) {
        let extraMeaningCount = 0;
        extraMeaningCount = this.extraMultimeaningCount(
          this.testItem.studentAnswers[i]
        );
        if (extraMeaningCount <= 0) {
          if (this.testItem.studentAnswers[i].isCorrect)
            this.result.cnCorrect += 1;
          if (
            this.testItem.studentAnswers[i].englishStudentAnswer &&
            this.testItem.studentAnswers[i].englishStudentAnswer
              .trim()
              .toLowerCase() ==
              this.testItem.studentAnswers[i].englishText.trim().toLowerCase()
          )
            this.result.enCorrect += 1;
        } else {
          let multiCnIsCorrect = true;
          let multiEnIsCorrect = true;
          for (let j = 0; j < extraMeaningCount + 1; j++) {
            //加入extrameaningIndex用于更新isCorrect的时候定位具体是第几个多义词
            this.testItem.studentAnswers[i + j].extraMeaningIndex = j;
            if (!this.testItem.studentAnswers[i + j].isCorrect) {
              multiCnIsCorrect = false;
            }
            if (
              !(
                this.testItem.studentAnswers[i].englishStudentAnswer &&
                this.testItem.studentAnswers[i].englishStudentAnswer
                  .trim()
                  .toLowerCase() ==
                  this.testItem.studentAnswers[i].englishText
                    .trim()
                    .toLowerCase()
              )
            )
              multiEnIsCorrect = false;
          }
          if (multiCnIsCorrect) this.result.cnCorrect += 1;
          if (multiEnIsCorrect) this.result.enCorrect += 1;
          //多义词总量更新
          allExtrameaningCount += extraMeaningCount;
          i += extraMeaningCount;
        }
      }

      //set total number of voc
      this.result.vocCount =
        this.testItem.studentAnswers.length - allExtrameaningCount;
    },

    handleCurrentChange: function (val) {
      this.currentRow = val;
    },

    handleEnglishTextColumnClick: function (event, englishText) {
      this.$playAudio(englishText, this.$refs.vocaudio);
    },

    randomArray: function (arrayList) {
      if (arrayList.length === 0 || arrayList.length === null) return;
      var arrayListLength = arrayList.length;
      for (var i = 0; i < arrayListLength; i++) {
        var randomIndex = Math.floor(Math.random() * arrayList.length);
        var tempItem = arrayList[randomIndex];
        arrayList[randomIndex] = arrayList[0];
        arrayList[0] = tempItem;
      }
      return arrayList;
    },

    NirvanaVocTest: function () {
      this.showCreateTestForm = true;
      this.nirvanaVocList = this.testItem.studentAnswers.filter(
        (item) => item.bothResult != true
      );
      this.nirvanaVocList = this.randomArray(this.nirvanaVocList);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bg-image {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
}
.main-content {
  margin-left: 3%;
  margin-right: 3%;
}

.english-answer-item-result-correct {
  display: flex;
  justify-content: center;
}

.english-answer-item-result-incorrect {
  display: flex;
  justify-content: center;
}

.test-result,
.test-item {
  // margin-top: 60px;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 1em;

  background-image: url("../../../assets/logo.jpg");
  background-size: 20%;

  background-position: 50% 50%;
  background-repeat: no-repeat;
}

p {
  font-family: "FangSong", "Times New Roman", Times, serif;
}

.test-result-details,
.test-item-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}

.div-title {
  font-weight: bold;
  font-size: 24px;
  color: deepskyblue;
}

// .test-header {
//   position: fixed;
//   left: 20%;
//   top: 0;
//   width: 60%;
//   height: 50px;
//   text-align: center;
//   z-index: 9999;
// }
</style>
