<template>
  <div>
    <el-dialog
      width="40%"
      v-model="dialogFormVisible"
      title="创建日常"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-form-item label="日期">
        <el-date-picker v-model="form.date" type="date" placeholder="选择日期">
        </el-date-picker>
      </el-form-item>

      <div style="text-align: left">
        <span>评价</span>
      </div>
      <quill-editor v-model:value="form.evaluation" />

      <div style="text-align: left">
        <span>备注</span>
      </div>
      <quill-editor v-model:value="form.comment" />

      <el-form-item label="阶段汇报">
        <el-switch
          v-model="form.isMilestone"
          active-color="#13ce66"
          inactive-color="gray"
        />
      </el-form-item>

      <div style="text-align: left" v-if="form.isMilestone">
        <span>阶段性总结</span>
      </div>
      <quill-editor
        v-if="form.isMilestone"
        v-model:value="form.milestoneComment"
      />

      <el-form-item label="批量生成">
        <el-switch
          v-model="form.isCreatingMultipleDailies"
          active-color="#13ce66"
          inactive-color="gray"
        />
      </el-form-item>

      <el-form-item v-if="form.isCreatingMultipleDailies" label="平均天数">
        <el-input-number
          v-model="form.perDays"
          size="small"
          :min="1"
          :step="1"
          @keydown.stop
          placeholder="请输入平均的的天数"
        />
      </el-form-item>

      <el-form-item v-if="form.isCreatingMultipleDailies" label="生成数量">
        <el-input-number
          v-model="form.autoDailyCount"
          size="small"
          :min="1"
          :step="1"
          @keydown.stop
          placeholder="请输入生成的daily数量"
        />
      </el-form-item>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onCreatePlanFormDailyCancel()">Cancel</el-button>
          <el-button type="primary" @click="onCreatePlanFormDailyConfirm()"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CreatePlanDailyForm",
  components: {},

  props: ["modelValue", "studentPlanId", "allDaily", "edittingPlanDaily"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  mounted: function () {},

  data() {
    return {
      dialogFormVisible: false,
      description: "",

      form: {
        id: 0,
        studentPlanId: 0,
        date: null,
        evaluation: "",
        comment: "",
        isMilestone: false,
        milestoneComment: "",
        isCreatingMultipleDailies: false,
        perDays: 1,
        autoDailyCount: 1,
      },
    };
  },

  methods: {
    onCreatePlanFormDailyCancel: function () {
      this.dialogFormVisible = false;
      this.$emit("on-plan-form-daily-cancel");
    },
    onCreatePlanFormDailyConfirm: function () {
      let postData = this.form;
      postData.isEditing = this.edittingPlanDaily ? true : false;
      //系统存时间的时候会少8小时，所以手动 +8，保持系统时间正确
      let tempDate = new Date(postData.date);
      tempDate.setHours(tempDate.getHours() + 8);
      postData.date = tempDate;
      //如果要批量生成，就走multiple的函数
      if (this.form.isCreatingMultipleDailies) {
        this.createMultipleDailies(postData);
      } else {
        //如果日期已经存在，或者this.edittingPlanDaily没东西（不是编辑），那就不允许添加
        if (this.isDailyExist(postData.date) && !this.edittingPlanDaily) {
          this.$message("日期已存在，请勿重复添加～");
        } else {
          this.$store
            .dispatch("StudentPlan/addOrEditStudentPlanDaily", postData)
            .then((response) => {
              if (response.data > 0) {
                this.form.id = response.data;
                this.$emit("on-plan-form-daily-confirm", this.form);
                this.dialogFormVisible = false;
              }
            });
        }
      }
    },

    createMultipleDailies: function (postData) {
      if (!postData.date) return;
      if (this.form.perDays < 1) return;
      let firstDaily = new Date(postData.date);
      //增加获得response时候的计数
      let dailyPostedCount = 0;
      for (let i = 0; i < this.form.autoDailyCount; i++) {
        postData.date = new Date(
          firstDaily.setDate(
            firstDaily.getDate() + (i ? 1 : 0) * this.form.perDays
          )
        );
        //如果日期已经存在，或者this.edittingPlanDaily没东西（不是编辑），那就不允许添加
        if (this.isDailyExist(postData.date)) {
          this.$message("日期已存在，请勿重复添加～");
          //如果daily重复导致无法添加，那也算添加了一个daily
          dailyPostedCount += 1;
        } else {
          this.$store
            .dispatch("StudentPlan/addOrEditStudentPlanDaily", postData)
            .then((response) => {
              if (response.data > 0) {
                this.form.id = response.data;
                dailyPostedCount += 1;
                if (dailyPostedCount == this.form.autoDailyCount) {
                  //说明所有response都拿到了
                  this.$emit("all-dailies-posted");
                  this.dialogFormVisible = false;
                }
              }
            });
        }
      }
    },

    isDailyExist: function (date) {
      let dateOfAllDaily = this.allDaily.map(
        (item) => new Date(item.date).toISOString().split("T")[0]
      );
      let dateOfDate = date.toISOString().split("T")[0];
      return dateOfAllDaily.some((item) => item == dateOfDate) ? true : false;
    },

    handleOpen: function () {
      if (this.edittingPlanDaily) {
        this.form.id = this.edittingPlanDaily.id;
        this.form.date = this.edittingPlanDaily.date;
        this.form.evaluation = this.edittingPlanDaily.evaluation;
        this.form.comment = this.edittingPlanDaily.comment;
        this.form.isMilestone = this.edittingPlanDaily.isMilestone;
        this.form.milestoneComment = this.edittingPlanDaily.milestoneComment;
        this.form.studentPlanId = this.edittingPlanDaily.studentPlanId;
      } else {
        this.form = {
          id: 0,
          studentPlanId: 0,
          date: new Date(),
          evaluation: "",
          comment: "",
          isMilestone: false,
          milestoneComment: "",
        };
        //打开form的时候，把property传进来的planid放到form里面
        this.form.studentPlanId = this.studentPlanId;
      }
    },

    handleClose: function () {
      this.$emit("on-plan-form-daily-close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mark-heart {
  color: deepskyblue;
}

.cursor {
  cursor: pointer;
}

.tags-check-group {
}
</style>
