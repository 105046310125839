let TASKGENERATOR = [
  {
    id: 1,
    project: "TOEFL",
    subject: "Evian",
    studyType: 1,
    autoTaskName: "基础Evian任务",
    setProcesses: [1],
    examMode: 4,
    answer: 4, //进入单词卡后的显示模式
    criterion: 90,
    proceedDate: new Date(),
    taskList: [
      {
        name: "Evian List1-6",
        setName: "Evian List1-6",
        setIds: [425, 447, 477, 478, 479, 480],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Evian List7-9",
        setName: "Evian List7-9",
        setIds: [487, 488, 489],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Evian List10-12",
        setName: "Evian List10-12",
        setIds: [490, 492, 493],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Evian List13-15",
        setName: "Evian List13-15",
        setIds: [494, 495, 496],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Evian List16-18",
        setName: "Evian List16-18",
        setIds: [497, 498, 499],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Evian List19-20",
        setName: "Evian List19-20",
        setIds: [500, 501],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
    ],
  },
  {
    id: 2,
    project: "TOEFL",
    subject: "Naive",
    studyType: 1,
    autoTaskName: "Naive任务",
    setProcesses: [1],
    examMode: 4,
    answer: 4, //进入单词卡后的显示模式
    criterion: 90,
    proceedDate: new Date(),
    taskList: [
      {
        name: "Naive List1-2",
        setName: "Naive List1-2",
        setIds: [245, 250],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List3-4",
        setName: "Naive List3-4",
        setIds: [255, 271],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List5-6",
        setName: "Naive List5-6",
        setIds: [288, 290],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List7-8",
        setName: "Naive List7-8",
        setIds: [345, 358],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List9-10",
        setName: "Naive List9-10",
        setIds: [360, 362],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List11-12",
        setName: "Naive List11-12",
        setIds: [363, 366],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List13-14",
        setName: "Naive List13-14",
        setIds: [373, 374],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List15-16",
        setName: "Naive List15-16",
        setIds: [375, 376],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List17-18",
        setName: "Naive List17-18",
        setIds: [378, 379],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List19-20",
        setName: "Naive List19-20",
        setIds: [381, 382],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List21-22",
        setName: "Naive List21-22",
        setIds: [383, 384],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List23-24",
        setName: "Naive List23-24",
        setIds: [385, 386],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List25-26",
        setName: "Naive List25-26",
        setIds: [388, 396],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List27-28",
        setName: "Naive List27-28",
        setIds: [398, 399],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List29-30",
        setName: "Naive List29-30",
        setIds: [400, 401],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List31-32",
        setName: "Naive List31-32",
        setIds: [402, 404],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List33-34",
        setName: "Naive List33-34",
        setIds: [405, 406],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Naive List35-36",
        setName: "Naive List35-36",
        setIds: [408, 409],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
    ],
  },
  {
    id: 3,
    project: "TOEFL",
    subject: "New Happy",
    studyType: 1,
    autoTaskName: "New Happy任务",
    setProcesses: [1],
    examMode: 4,
    answer: 4, //进入单词卡后的显示模式
    criterion: 90,
    proceedDate: new Date(),
    taskList: [
      {
        name: "New Happy List1-2",
        setName: "New Happy List1-2",
        setIds: [18641, 18642],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "New Happy List3-4",
        setName: "New Happy List3-4",
        setIds: [18643, 18644],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "New Happy List5-6",
        setName: "New Happy List5-6",
        setIds: [18645, 18646],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "New Happy List7-8",
        setName: "New Happy List7-8",
        setIds: [18657, 18658],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "New Happy List9-10",
        setName: "New Happy List9-10",
        setIds: [18668, 18686],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "New Happy List11-12",
        setName: "New Happy List11-12",
        setIds: [18687, 18688],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "New Happy List13-14",
        setName: "New Happy List13-14",
        setIds: [18696, 18707],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "New Happy List15-16",
        setName: "New Happy List15-16",
        setIds: [18711, 18713],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "New Happy List17-18",
        setName: "New Happy List17-18",
        setIds: [18721, 18722],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "New Happy List19-20",
        setName: "New Happy List19-20",
        setIds: [18723, 18724],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "New Happy List21-22",
        setName: "New Happy List21-22",
        setIds: [18725, 18726],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "New Happy List23-24",
        setName: "New Happy List23-24",
        setIds: [18733, 18737],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
    ],
  },
  {
    id: 4,
    project: "TOEFL",
    subject: "Golden",
    studyType: 1,
    autoTaskName: "Golden任务",
    setProcesses: [1],
    examMode: 4,
    answer: 4, //进入单词卡后的显示模式
    criterion: 90,
    proceedDate: new Date(),
    taskList: [
      {
        name: "Golden List1-2",
        setName: "Golden List1-2",
        setIds: [18768, 18814],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Golden List3-4",
        setName: "Golden List3-4",
        setIds: [18815, 18816],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Golden List5-6",
        setName: "Golden List5-6",
        setIds: [18853, 18906],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Golden List7-8",
        setName: "Golden List7-8",
        setIds: [18910, 18938],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Golden List9-10",
        setName: "Golden List9-10",
        setIds: [18947, 18966],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Golden List11-12",
        setName: "Golden List11-12",
        setIds: [19013, 19014],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Golden List13-14",
        setName: "Golden List13-14",
        setIds: [19044, 19053],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Golden List15-16",
        setName: "Golden List15-16",
        setIds: [19065, 19066],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Golden List17-18",
        setName: "Golden List17-18",
        setIds: [19090, 19107],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Golden List19-20",
        setName: "Golden List19-20",
        setIds: [19108, 19115],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Golden List21-22",
        setName: "Golden List21-22",
        setIds: [19195, 19196],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "Golden List23-24",
        setName: "Golden List23-24",
        setIds: [19197, 19198],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
    ],
  },
  {
    id: 5,
    project: "TOEFL",
    subject: "新版机经词频",
    studyType: 1,
    autoTaskName: "新版机经词频任务100",
    setProcesses: [1],
    examMode: 1,
    answer: 1, //进入单词卡后的显示模式
    criterion: 90,
    proceedDate: new Date(),
    taskList: [
      {
        name: "机经1-100",
        setName: "机经1-100",
        setIds: [528, 535],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经101-200",
        setName: "机经101-200",
        setIds: [536, 537],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经201-300",
        setName: "机经201-300",
        setIds: [538, 539],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经301-400",
        setName: "机经301-400",
        setIds: [540, 541],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经401-500",
        setName: "机经401-500",
        setIds: [542, 543],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经501-600",
        setName: "机经501-600",
        setIds: [545, 546],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经601-700",
        setName: "机经601-700",
        setIds: [547, 549],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经701-800",
        setName: "机经701-800",
        setIds: [550, 551],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经801-900",
        setName: "机经801-900",
        setIds: [552, 553],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经901-1022",
        setName: "机经901-1022",
        setIds: [554, 557],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
    ],
  },
  {
    id: 6,
    project: "TOEFL",
    subject: "新版机经词频",
    studyType: 1,
    autoTaskName: "新版机经词频任务50",
    setProcesses: [1],
    examMode: 1,
    answer: 1, //进入单词卡后的显示模式
    criterion: 90,
    proceedDate: new Date(),
    taskList: [
      {
        name: "机经1-50",
        setName: "机经1-50",
        setIds: [528],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经51-100",
        setName: "机经51-100",
        setIds: [535],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经101-150",
        setName: "机经101-150",
        setIds: [536],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经151-200",
        setName: "机经151-200",
        setIds: [537],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经201-250",
        setName: "机经201-250",
        setIds: [538],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经251-300",
        setName: "机经251-300",
        setIds: [539],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经301-350",
        setName: "机经301-350",
        setIds: [540],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经351-400",
        setName: "机经351-400",
        setIds: [541],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经401-450",
        setName: "机经401-450",
        setIds: [542],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经451-500",
        setName: "机经451-500",
        setIds: [543],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经501-550",
        setName: "机经501-550",
        setIds: [545],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经551-600",
        setName: "机经551-600",
        setIds: [546],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经601-650",
        setName: "机经601-650",
        setIds: [547],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经651-700",
        setName: "机经651-700",
        setIds: [549],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经701-750",
        setName: "机经701-750",
        setIds: [550],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经751-800",
        setName: "机经751-800",
        setIds: [551],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经801-850",
        setName: "机经801-850",
        setIds: [552],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经851-900",
        setName: "机经851-900",
        setIds: [553],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经901-950",
        setName: "机经901-950",
        setIds: [554],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
      {
        name: "机经951-1022",
        setName: "机经951-1022",
        setIds: [557],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
      },
    ],
  },
  {
    id: 7,
    project: "TOEFL",
    subject: "新版机经词频",
    studyType: 1,
    autoTaskName: "新版机经词频任务25",
    setProcesses: [5, 1],
    examMode: 1,
    answer: 1, //进入单词卡后的显示模式
    criterion: 90,
    proceedDate: new Date(),
    taskList: [
      {
        name: "机经1-25",
        setName: "机经1-25",
        setIds: [528],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经26-50",
        setName: "机经26-50",
        setIds: [528],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经51-75",
        setName: "机经51-75",
        setIds: [535],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经76-100",
        setName: "机经76-100",
        setIds: [535],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经101-125",
        setName: "机经101-125",
        setIds: [536],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经126-150",
        setName: "机经126-150",
        setIds: [536],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经151-175",
        setName: "机经151-175",
        setIds: [537],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经176-200",
        setName: "机经176-200",
        setIds: [537],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经201-225",
        setName: "机经201-225",
        setIds: [538],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经226-250",
        setName: "机经226-250",
        setIds: [538],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经251-275",
        setName: "机经251-275",
        setIds: [539],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经276-300",
        setName: "机经276-300",
        setIds: [539],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经301-325",
        setName: "机经301-325",
        setIds: [540],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经326-350",
        setName: "机经326-350",
        setIds: [540],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经351-375",
        setName: "机经351-375",
        setIds: [541],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经376-400",
        setName: "机经376-400",
        setIds: [541],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经401-425",
        setName: "机经401-425",
        setIds: [542],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经426-450",
        setName: "机经426-450",
        setIds: [542],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经451-475",
        setName: "机经451-475",
        setIds: [543],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经476-500",
        setName: "机经476-500",
        setIds: [543],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经501-525",
        setName: "机经501-525",
        setIds: [545],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经526-550",
        setName: "机经526-550",
        setIds: [545],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经551-575",
        setName: "机经551-575",
        setIds: [546],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经576-600",
        setName: "机经576-600",
        setIds: [546],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经601-625",
        setName: "机经601-625",
        setIds: [547],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经626-650",
        setName: "机经626-650",
        setIds: [547],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经651-675",
        setName: "机经651-675",
        setIds: [549],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经676-700",
        setName: "机经676-700",
        setIds: [549],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经701-725",
        setName: "机经701-725",
        setIds: [550],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经726-750",
        setName: "机经726-750",
        setIds: [550],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经751-775",
        setName: "机经751-775",
        setIds: [551],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经776-800",
        setName: "机经776-800",
        setIds: [551],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经801-825",
        setName: "机经801-825",
        setIds: [552],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经826-850",
        setName: "机经826-850",
        setIds: [552],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经851-875",
        setName: "机经851-875",
        setIds: [553],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经876-900",
        setName: "机经876-900",
        setIds: [553],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经901-925",
        setName: "机经901-925",
        setIds: [554],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经926-950",
        setName: "机经926-950",
        setIds: [554],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经951-975",
        setName: "机经951-975",
        setIds: [557],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 1,
        to: 25,
      },
      {
        name: "机经976-1000",
        setName: "机经976-1000",
        setIds: [557],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 26,
        to: 50,
      },
      {
        name: "机经1001-1022",
        setName: "机经1001-1022",
        setIds: [557],
        reviewTurn: 0,
        state: 2,
        evaluation: "",
        comment: "",
        from: 51,
        to: 72,
      },
    ],
  },
];

export { TASKGENERATOR };
