let TAGS = [
  {
    id: 2,
    chineseName: "注意词性",
    tag: false,
  },
  {
    id: 6,
    chineseName: "注意发音",
    tag: false,
  },
  {
    id: 11,
    chineseName: "注意拼写",
    tag: false,
  },
  {
    id: 1,
    chineseName: "一词多义",
    tag: false,
  },
  {
    id: 7,
    chineseName: "固定搭配",
    tag: false,
  },
  {
    id: 8,
    chineseName: "英文释义",
    tag: false,
  },
  {
    id: 9,
    chineseName: "消化例句",
    tag: false,
  },
  {
    id: 10,
    chineseName: "混词哒呲",
    tag: false,
  },
];

// const POS = {
//   id: 2,
//   chineseName: "关注词性"
// };
// const MULTIMEANING = {
//   id: 1,
//   chineseName: "一词多义"
// };
// const PRONUNCIATION = {
//   id: 6,
//   chineseName: "注意发音"
// };
// const PHRASE = {
//   id: 7,
//   chineseName: "固定搭配"
// };
// const ENGLISHMEANING = {
//   id: 8,
//   chineseName: "英文释义"
// };
// const EXAMPLE = {
//   id: 9,
//   chineseName: "消化例句"
// };
// const CONFUSION = {
//   id: 10,
//   chineseName: "混词哒呲"
// };

// const POS = 2;
// const MULTIMEANING = 1;
// const PRONUNCIATION = 6;
// const PHRASE = 7;
// const ENGLISHMEANING = 8;
// const EXAMPLE = 9;
// const CONFUSION = 10;

export default {
  TAGS,
  // POS,
  // MULTIMEANING,
  // PRONUNCIATION,
  // PHRASE,
  // ENGLISHMEANING,
  // EXAMPLE,
  // CONFUSION,
};
