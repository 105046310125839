<template>
  <div>
    <div style="text-align: left">
      <el-select
        v-if="userRole != 'Student'"
        v-model="currentViewingTeacherId"
        filterable
        class="m-2"
        placeholder="选择老师"
        @change="handleSelectTeacherChange"
      >
        <el-option
          v-for="item in teachersAndAdmins"
          :key="item.userId"
          :label="item.userName"
          :value="item.userId"
        />
      </el-select>

      <el-button
        v-if="userRole != 'Student'"
        @click="duplicateMultipleTests"
        style="margin-left: 10px"
        >批量复制</el-button
      >

      <el-button @click="clearFilter">清除过滤</el-button>
    </div>
    <el-table
      ref="allTestTable"
      :data="filterTestList"
      style="width: 80%"
      :row-class-name="tableRowClassName"
      @row-click="
        (row, column, event) => {
          handleTestListRowClick(row, column, event);
        }
      "
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="userRole != 'Student'"
        type="selection"
        width="55"
      />
      <el-table-column prop="name" label="名称"> </el-table-column>
      <!-- <el-table-column
      prop="createdDate"
      label="创建日期"
      sortable
      width="180"
      column-key="date"
    /> -->
      <el-table-column
        property="createdDate"
        label="创建日期"
        sortable
        width="180"
        column-key="createdDate"
      >
        <template #default="scope">
          {{ scope.row.createdDate.substring(5, 10) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="type"
        label="模式"
        width="150"
        :filters="[
          { text: '听写英文', value: 1 },
          { text: '听写中英', value: 2 },
          { text: '听英写中', value: 3 },
          { text: '看英写中', value: 4 },
          { text: '语音测试', value: 5 },
          { text: '看中写英', value: 6 },
        ]"
        :filter-method="filterType"
        filter-placement="bottom-end"
      >
        <template #default="scope">
          {{ testTypeString(scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column>
        <template #header>
          <el-input
            v-model="searchOrigin"
            size="small"
            placeholder="Type to search origin"
          />
        </template>
        <template #default="scope">
          <el-popover
            effect="light"
            trigger="hover"
            placement="top"
            width="auto"
          >
            <template #default>
              <div>origin: {{ scope.row.origin }}</div>
            </template>
            <template #reference>
              {{ scope.row.origin.substring(0, 20) + "..." }}
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="描述"> </el-table-column>

      <el-table-column
        prop="category"
        label="类别"
        width="150"
        :filters="[
          { text: '词测', value: '词测' },
          { text: '默写', value: '默写' },
          { text: '常规词汇机筛', value: '常规词汇机筛' },
          { text: '星标词检查', value: '星标词检查' },
          { text: '无', value: 'None' },
        ]"
        :filter-method="filterCategory"
        filter-placement="bottom-end"
      >
        <template #default="scope">
          {{ scope.row.category }}
        </template>
      </el-table-column>

      <el-table-column
        prop="status"
        label="状态"
        width="100"
        :filters="[
          { text: 'standing', value: 'standing' },
          { text: 'pending', value: 'pending' },
          { text: 'finished', value: 'finished' },
        ]"
        :filter-method="filterStatus"
        filter-placement="bottom-end"
      >
        <template #default="scope">
          <el-tag
            :type="scope.row.status === 'finished' ? '' : 'success'"
            disable-transitions
            >{{ scope.row.status }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column align="right" v-if="userRole != 'Student'">
        <template #default="scope">
          <el-button
            size="small"
            type="success"
            @click.stop="handlePublish(scope.row)"
            v-show="scope.row.published == false"
            >Publish</el-button
          >
          <el-button
            size="small"
            type="success"
            @click.stop="handleDuplicate(scope.row)"
            >Dolly</el-button
          >
          <el-button
            v-if="userRole != 'Student' && scope.row.status != 'finished'"
            size="small"
            type="success"
            @click.stop="handleUpdateAsFinished(scope.row)"
            >Terminator</el-button
          >
          <el-button
            size="small"
            type="danger"
            @click.stop="handleDelete(scope.$index, scope.row)"
            >Delete</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <duplicate-test-form
      v-model="showCreateTestForm"
      :originalTestInfo="currentRowInfo"
      @on-test-mode-form-cancel="showCreateTestForm = false"
      @on-test-mode-form-confirm="showCreateTestForm = false"
      @on-tool-config-form-close="showCreateTestForm = false"
    ></duplicate-test-form>

    <duplicate-multiple-tests
      v-model="showDuplicateMultipleTestForm"
      :multipleSelectedTests="multipleSelectedTests"
      @on-duplicate-multiple-form-cancel="showDuplicateMultipleTestForm = false"
      @on-duplicate-multiple-form-confirm="
        showDuplicateMultipleTestForm = false
      "
      @on-duplicate-multiple-form-close="showDuplicateMultipleTestForm = false"
    ></duplicate-multiple-tests>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import DuplicateTestForm from "./DuplicateTestForm.vue";
import duplicateMultipleTests from "./DuplicateMultipleTests.vue";

export default {
  name: "TestList",

  props: ["targetId"],

  components: { DuplicateTestForm, duplicateMultipleTests },

  computed: {
    ...mapGetters("StudentTest", ["testList"]),
    ...mapMutations("StudentTest", ["mutateTestList"]),
    filterTestList() {
      return this.testList.filter(
        (data) =>
          !this.searchOrigin ||
          data.origin.toLowerCase().includes(this.searchOrigin.toLowerCase())
      );
    },
  },

  mounted: function () {
    this.userRole = localStorage.getItem("userRole");
    this.getStudentTestList();
    this.getAllTeachersAndAdmins();
  },

  data() {
    return {
      searchOrigin: "",
      userRole: "",
      showCreateTestForm: false,
      showDuplicateMultipleTestForm: false,
      currentRowInfo: null,
      currentViewingTeacherId: null,
      teachersAndAdmins: [],
      multipleSelectedTests: [],
    };
  },

  methods: {
    openAsNewPageWithCryption: function (routeName, query) {
      query = JSON.stringify(query);
      query = window.encodeURIComponent(query);
      let newpage = this.$router.resolve({
        path: routeName,
        query: { encodedString: window.btoa(query) },
      });
      window.open(newpage.href, "_blank");
    },

    OpenInCurrentPageWithCryption: function (routeName, query) {
      query = JSON.stringify(query);
      query = window.encodeURIComponent(query);
      this.$router.push({
        path: routeName,
        query: { encodedString: window.btoa(query) },
      });
    },

    getAllTeachersAndAdmins: function () {
      this.$store.dispatch("Admin/getAllTeachersAndAdmins").then((response) => {
        this.teachersAndAdmins = response.data;
        this.processTeachersAndAdminsList();
      });
    },

    processTeachersAndAdminsList: function () {
      //去掉 angas
      this.teachersAndAdmins = this.teachersAndAdmins.filter(
        (item) => item.userName.trim() != "angas"
      );
    },

    //get test list based on teacher id
    handleSelectTeacherChange: function (teacherId) {
      this.$store.dispatch("StudentTest/getAllTestsByUserId", {
        userId: teacherId,
      });
    },

    getStudentTestList: function () {
      this.$store.dispatch("StudentTest/getAllTestByStudentId", {
        userId: this.targetId || localStorage.getItem("targetId"),
        userRole: this.userRole,
      });
    },

    //handleTestListRowClick: function (row, column, event, payload) {
    handleTestListRowClick: function (row) {
      //加入学生名字
      row.targetCNName =
        localStorage.getItem("targetCNName") ||
        localStorage.getItem("userCNName");
      //标记打开测试的用户是否是自己（如果不是，就是老师绑定了学生，那么不允许“提交”等操作
      row.isSelf =
        localStorage.getItem("userId") == localStorage.getItem("targetId");
      //如果是学生打开，且是第一次打开（status是standing证明是第一次打开），就更新status到pending
      if (
        localStorage.getItem("userRole") == "Student" &&
        row.status == "standing"
      ) {
        this.$store
          .dispatch("StudentTest/updateStudentTestStatus", {
            id: row.id,
            studentId: row.studentId,
            name: row.name,
            type: row.type,
            origin: row.origin,
            nirvanaCount: 0,
            description: row.description || "",
            status: "pending",
            published: row.published || true,
          })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => this.$message(error.toString()));
      }

      if (row.type === 5) {
        if (row.status == "finished") {
          this.openAsNewPageWithCryption("/audiotestitemresult", row);
        } else {
          //如果type = 5， 也就是语音测试，就新开audiotestitem页面
          this.openAsNewPageWithCryption("/audiotestitem", row);
        }
      } else {
        if (row.status == "finished") {
          this.openAsNewPageWithCryption("/newtestitemresult", row);
        } else {
          this.openAsNewPageWithCryption("/newtestitem", row);
        }
      }

      // this.$store
      //   .dispatch("AssignmentPractice/getAssignmentQuestion", {
      //     assignmentId: row.id,
      //   })
      //   .then((response) => {
      //     console.log(response);
      //     sessionStorage.setItem(
      //       "completionQuestions",
      //       JSON.stringify(response.data)
      //     );
      //     this.$router.push("/practice");
      //   })
      //   .catch((error) => console.log(error));
    },

    //处理table中选中多行的selection value
    handleSelectionChange: function (val) {
      this.multipleSelectedTests = val;
    },

    duplicateMultipleTests: function () {
      if (this.multipleSelectedTests.length > 0) {
        this.showDuplicateMultipleTestForm = true;
      } else {
        this.$message("请至少选中一个测试");
      }
    },

    testTypeString: function (type) {
      switch (type) {
        case 1:
          return "听写英文";
        case 2:
          return "听写中英";
        case 3:
          return "听英写中";
        case 4:
          return "看英写中";
        default:
          return "";
      }
    },

    filterType: function (val, row) {
      return row.type == val;
    },

    filterStatus: function (val, row) {
      return row.status == val;
    },

    filterCategory: function (val, row) {
      if (val == "None") return row.category == "" || row.category == null;
      return row.category == val;
    },

    clearFilter: function () {
      this.$refs.allTestTable.clearFilter();
      this.searchOrigin = "";
    },

    handleDelete: function (index, row) {
      this.$store
        .dispatch("StudentTest/delete", {
          testId: row.id,
        })
        .then((response) => {
          console.log(response);
          this.testList.splice(index, 1);
        })
        .catch((error) => this.$message(error.toString()));
    },

    handleDuplicate: function (row) {
      this.currentRowInfo = row;
      console.log(this.currentRowInfo);
      this.showCreateTestForm = true;
    },

    handleUpdateAsFinished: function (row) {
      row.status = "finished";
      this.$store.dispatch("StudentTest/updateStudentTestStatus", row);
    },

    handlePublish: function (row) {
      console.log(row);
      this.$store
        .dispatch("StudentTest/publishStudentTest", {
          id: row.id,
          studentId: row.studentId,
          published: true,
        })
        .then((response) => {
          console.log(response);
          this.$message({
            type: "success",
            message:
              localStorage.getItem("targetCNName") +
              "的测试: " +
              row.name +
              "已发布",
          });
          row.published = true;
        })
        .catch((error) => this.$message(error.toString()));
    },

    //tableRowClassName: function ({ row, rowIndex }) {
    tableRowClassName: function ({ row }) {
      if (row.status == "pending") {
        return "warning-row";
      } else if (row.status == "finished") {
        return "success-row";
      }
      return "";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-9);
}
.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
</style>
