let UFOPanoramicList = [
  { id: 39934, name: "Archaeopteryx" },
  { id: 40309, name: "Tenure" },
  { id: 21237, name: "10Q Crab UFO1-3 Constraints on Natural Selection" },
  { id: 21221, name: "10Q Crab UFO2-1 Climate change and the Natufian People" },
  { id: 34570, name: "10Q UFO2-2 Metamorphosis" },
  { id: 39864, name: "10Q Crab UFO2-3 The Origin of Coral Reefs" },
  { id: 21245, name: "10Q Crab UFO3-1 Development of printing" },
  { id: 21267, name: "10Q Crab UFO4-1 Coral Reef Communities" },
  { id: 5503, name: "UFO004-1" },
  { id: 5504, name: "UFO4-2 Removing Dams" },
  { id: 5505, name: "UFO4-3 Sociality in Animals" },
  { id: 5506, name: "UFO004-4" },
  { id: 21261, name: "10Q Crab UFO8-2 The Collapse of the Maya" },
  { id: 21246, name: "10Q Crab UFO9-3 Earth’s Atmosphere" },
  { id: 34636, name: "10Q Crab UFO10-1 birdsong" },
  { id: 21122, name: "10Q Crab UFO13-1 Dinosaurs and Parental Care" },
  { id: 21226, name: "10Q Crab UFO13-2 Greek Sacred Groves and Parks" },
  { id: 21227, name: "10Q Crab UFO13-3 The Chaco phenomenon" },
  { id: 21220, name: "10Q Crab UFO14-1 Art and Culture of Pacific Northwest Communities" },
  { id: 21224, name: "10Q Crab UFO14-2 Colonial America and the Navigation Acts" },
  { id: 21250, name: "10Q Crab UFO16-1 Crown of Thorns Starfish and Coral Reefs" },
  { id: 21225, name: "10Q Crab UFO17-1 Documenting the Incas" },
  { id: 21238, name: "UFO18-2 Comets" },
  { id: 21113, name: "10Q Crab UFO20-1 Early Modern Industrialization" },
  { id: 21117, name: "10Q UFO21-2 Water Management in Early Agriculture" },
  { id: 39858, name: "10Q Crab UFO23-3 Urban Development in the United States in the Nineteenth Century" },
  { id: 21243, name: "10Q Crab UFO24-1 Costs and Benefits of Dispersal" },
  { id: 21240, name: "UFO24-2 Determining the Ages of the Planets and the Universe" },
  { id: 21118, name: "10Q UFO24-3 The Multiplier Effect" },
  { id: 2711, name: "10Q Crab UFO25-1Cognitive Maps in Animals" },
  { id: 38518, name: "10Q UFO26-1 How Animals in Rain Forests Make Themselves Heard" },
  { id: 2714, name: "10Q Crab UFO29-1 The Western Roman Empire in the Fifth Century" },
  { id: 21251, name: "10Q Crab UFO31-2 Consolidated Industry in the United States" },
  { id: 41082, name: "10Q UFO33-1 The Origin of Earth's Atmosphere" },
  { id: 2715, name: "10Q Crab UFO33-2 The Geographical Distribution of Gliding Animals" },
  { id: 21270, name: "10Q Crab UFO34-1 The First Eyes" },
  { id: 21231, name: "10Q UFO34-2 Vocalization in Frogs" },
  { id: 40502, name: "UFO35-1 Why Did Non-Avian Dinosaurs Become Extinct" },
  { id: 33299, name: "10Q UFO35-2 Live Performance" },
  { id: 38455, name: "10Q UFO38-2 Pleistocene Extinctions" },
  { id: 21252, name: "10Q UFO42-1 Bioluminescence in Marine Creatures" },
  { id: 21228, name: "10Q Crab UFO42-2 Mexican Mural Art" },
  { id: 31903, name: "10Q Crab UFO43-3 Stone Tools and Pottery Fragments" },
  { id: 2692, name: "10Q Crab UFO44-2 Early Theories of Continental Drift" },
  { id: 21254, name: "10Q Crab UFO44-2 Early Theories of Continental Drift2" },
  { id: 21253, name: "10Q UFO45-2 Gliding and Soaring" },
  { id: 2694, name: "10Q Crab UFO45-3 Sea Turtle Hatchling Strategies for Navigation" },
  { id: 2695, name: "10Q Crab UFO46-1 Domestication" },
  { id: 2691, name: " 10Q Crab UFO46-2 Forms of Locomotion" },
  { id: 2697, name: "10Q UFO47-1 Mesopotamian and Egyptian Settlement Patterns" },
  { id: 2696, name: "10Q Crab UFO49-3 Saving Soil and Cropland" },
  { id: 32451, name: "10Q UFO50-3 The Brain Size of Bottlenose Dolphins" },
  { id: 21046, name: "UFO55-3 The Beginning of Planet Formation" },
  { id: 21255, name: "10Q UFO58-1 Insect wings" },
  { id: 21058, name: "10Q UFO59-2 The Qualities of a Good Trout Stream" },
  { id: 21230, name: "10Q Crab UFO60-1 The Idea of Continental Drift" },
  { id: 21232, name: "10Q Crab UFO60-3 The Classic Mayan Collapse" },
  { id: 21094, name: "10Q UFO61-1 The Greek Revival" },
  { id: 21095, name: "10Q UFO63-2 Iron working in Africa" },
  { id: 33381, name: "10Q UFO64-3 Industrial Decline in Seventeenth-century Italy" },
  { id: 21256, name: "10Q Crab UFO65-3 The Rise of Florence" },
  { id: 21120, name: "10Q Crab UFO68-3 Water Supply in Venice" },
  { id: 21236, name: "10Q UFO69-2 The Dvaravati Civilization" },
  { id: 21271, name: "10Q Crab UFO70-3 Animal Locomotion" },
  { id: 21257, name: "10Q Crab UFO71-2 Fire Disturbance and Landscape Patterns-Stella" },
  { id: 21103, name: "10Q Crab UFO71-3 The Baptistery of San Giovanni-Stella" },
  { id: 40308, name: "10Q Crab UFO72-1 Visual Arts and Artists in Western Society-Stella" },
  { id: 21259, name: "10Q Crab UFO73-1 The Behavior of Magma" },
  { id: 21114, name: "10Q Crab UFO73-2 The Chemical Defenses of Trees" },
  { id: 21239, name: "10Q UFO74-1 Comparing the Intelligence of Different Animal Species" },
  { id: 33248, name: "10Q UFO74-2 Agriculture Made Civilization Possible" },
  { id: 39843, name: "10Q UFO74-3 Roman and Chinese Metalworking" },
  { id: 21063, name: "10Q Crab UFO75-2 Tidal Heating on lo" },
  { id: 21233, name: "10Q UFO75-3 Mites and Their Hosts" },
  { id: 21260, name: "10Q Crab UFO76-1 Calcium and Eggshells" },
  { id: 21121, name: "10Q Crab UFO76-2 Fungi and Trees" },
  { id: 21268, name: "10Q Crab UFO76-3 The Postwar Economic Boom" },
  { id: 21123, name: "10Q Crab UFO77-1 Beaks of Darwin’s Finches" },
  { id: 21244, name: "20Q Crab UFO77-2 Sea Ice" },
  { id: 21234, name: "10Q Crab UFO77-3 Theories of Megafauna Extinction" },
  { id: 21088, name: "10Q Crab UFO78-1 The Absence of Snakes in Ireland" },
  { id: 21248, name: "10Q Crab UFO78-2 Biological Rhythms" },
  { id: 39911, name: "10Q Crab UFO80-1 Colonizing Oceanic Islands" },
  { id: 21235, name: "10Q Crab UFO80-3 Lenape Horticulture" },
  { id: 21062, name: "10Q Crab UFO81-1 Children's Early Cognitive Competence" },
  { id: 21249, name: "10Q Crab UFO81-4 Dynastic Egypt and the Nile River" },
  { id: 21102, name: "10Q Crab UFO82-2 Determining Earth's Age" },
  { id: 21107, name: "10Q UFO82-3 Mass Production under China's First Emperor" },
  { id: 34810, name: "10Q Crab UFO83-1 Commedia dell'arte" },
  { id: 39701, name: "10Q Crab UFO83-2 1968" },
  { id: 21106, name: "10Q Crab UFO83-3 Accounting for the High Density of Planet Mercury" },
  { id: 21272, name: "10Q Crab UFO85-3-Fishing in Early Egypt-GERDA20210428" },
  { id: 21263, name: "10Q UFO87-1 Examining the Diets of Prehistoric People" },
  { id: 40316, name: "10Q UFO87-3 Causes of Glacial Ages" },
  { id: 21124, name: "10Q UFO88-1 Changes in the Amount of Forest on the Great Plains" },
  { id: 39842, name: "10Q UFO88-3 The Importance of Mail Service to the Early United States" },
  { id: 21111, name: "10Q Crab UFO90-1 Transportation and Urban Development in Los Angeles" },
  { id: 21273, name: "10Q Crab UFO91-1 The Chair in China" },
  { id: 40501, name: "20Q UFO94-1 Irrigation and Early Civilizations" },
];

export { UFOPanoramicList };
