import axios from "axios";
import url from "../urls";

export default {
  addProject(context, data) {
    return new Promise((resolve, reject) => {
      let pUrl = url.projectUrl;
      axios
        .put(pUrl, data)
        .then((response) => {
          context.commit("mutateAddedProject", {
            projectId: response.data,
            projectName: data.name,
          });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  editProject(context, data) {
    return new Promise((resolve, reject) => {
      let pUrl = url.projectUrl;
      axios
        .post(pUrl, data)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  getProject(context, data) {
    return new Promise((resolve, reject) => {
      let pUrl = url.projectUrl;
      axios
        .get(pUrl, {
          params: {
            userId: data.userId,
            type: data.type,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  deleteProject(context, data) {
    return new Promise((resolve, reject) => {
      let pUrl = url.projectUrl;
      axios
        .delete(pUrl, {
          params: {
            projectId: data.id,
          },
        })
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
