<template>
  <div>
    <el-dialog
      width="30%"
      v-model="dialogFormVisible"
      title="把任务转成测试"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-form-item label="单词数" v-if="vocList">
        <span>{{ vocList.length }}</span>
      </el-form-item>

      <el-form-item label="自动发布">
        <el-switch
          v-model="form.publishSwitch"
          active-color="#13ce66"
          inactive-color="gray"
        />
      </el-form-item>

      <el-form-item label="测试名称">
        <el-input
          v-model="form.testName"
          placeholder="请输入测试名称"
          @keydown.stop
        >
        </el-input>
      </el-form-item>

      <el-form-item label="测试描述">
        <el-input
          v-model="form.testDescription"
          placeholder="请输入测试描述"
          @keydown.stop
        >
        </el-input>
      </el-form-item>

      <el-form :model="form">
        <el-form-item label="测试模式">
          <el-radio-group
            v-model="form.testMode"
            @change="handleRadioGroupChange($event)"
          >
            <el-radio :label="1">听写英文</el-radio>
            <el-radio :label="2">听写中英</el-radio>
            <el-radio :label="3">听英写中</el-radio>
            <el-radio :label="4">看英写中</el-radio>
            <el-radio :label="5">语音测试</el-radio>
            <el-radio :label="6">看中写英</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <el-form-item label="测试类别">
        <el-select v-model="form.category" placeholder="请选择">
          <el-option
            v-for="item in form.categoryOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="播放次数">
        <el-input-number
          v-model="form.playCount"
          size="small"
          @keydown.stop
          placeholder="请输入播放次数"
        />
      </el-form-item>

      <el-form-item label="中文计时">
        <el-input-number
          v-model="form.testCountDown"
          :disabled="[1, 6].includes(form.testMode)"
          size="small"
          @keydown.stop
          placeholder="请输入每个中文倒计时"
        />
      </el-form-item>

      <el-form-item label="拼写计时">
        <el-input-number
          v-model="form.enTypeCountDown"
          :disabled="[3, 4].includes(form.testMode)"
          size="small"
          @keydown.stop
          placeholder="请输入每个单词倒计时"
        />
      </el-form-item>

      <template #footer>
        <span class="dialog-footer" v-if="vocList && vocList.length > 0">
          <el-button @click="onCreateTestFormCancel()">Cancel</el-button>
          <el-button type="primary" @click="onCreateTestFormConfirm()"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CreateTestForm",

  props: ["modelValue", "task"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  data() {
    return {
      dialogFormVisible: false,
      vocList: null,

      form: {
        testName: "",
        testDescription: "",
        testMode: 1,
        publishSwitch: false,
        playCount: 2,
        testCountDown: 0,
        enTypeCountDown: 5,
        category: "",
        categoryOptions: [
          {
            value: "词测",
            label: "词测",
          },
          {
            value: "默写",
            label: "默写",
          },
          {
            value: "常规词汇机筛",
            label: "常规词汇机筛",
          },
          {
            value: "星标词检查",
            label: "星标词检查",
          },
        ],
      },
    };
  },

  // mounted: function() {
  //   console.log(this.vocList)
  //   console.log(this.studentId)
  // },

  methods: {
    getVocabularies: function () {
      let params = {
        userId: localStorage.getItem("targetId"),
        setIds: this.task.setIds.split(",").map(Number),
      };

      this.$store
        .dispatch("Vocabulary/getMarkedVocabularies", params)
        .then((response) => {
          if (response.data.length >= 1) {
            this.vocList = response.data;

            let setProcesses = this.task.setProcesses.split(",").map(Number);
            for (let i = 0; i < setProcesses.length; i++) {
              this.autoSetProcess(setProcesses[i]);
            }
            this.$message(`"当前集合共获取"${this.vocList.length}个单词～`);
          }
        })
        .catch((error) => console.log(error.toString()));
    },

    autoSetProcess: function (processCode) {
      switch (processCode) {
        case 1:
          this.vocList = this.randomArray(this.vocList);
          break;
        case 2:
          this.vocList = this.vocList.filter((item) => item.marked == true);
          break;
        case 3:
          //this.filterVocListWithNoHeart();
          this.vocList = this.vocList.filter((item) => {
            return item.marked == false;
          });
          break;
        case 4:
          this.filterVocByMarkedCount(6);
          break;
        case 5:
          this.filterVocListByRange(this.task.from, this.task.to);
          break;
        case 6:
          break;
      }
    },

    filterVocListByRange: function (vocFromIndex, vocToIndex) {
      if (
        vocFromIndex == null ||
        vocFromIndex == "" ||
        vocToIndex == null ||
        vocToIndex == ""
      ) {
        //console.log("both from and to should not be null");
        return;
      } else {
        if (vocFromIndex > vocToIndex) {
          let temp = vocFromIndex;
          vocFromIndex = vocToIndex;
          vocToIndex = temp;
        }
        this.vocList = this.vocList.slice(vocFromIndex - 1, vocToIndex);
      }
    },

    filterVocByMarkedCount: function (count) {
      this.vocList = this.vocList.filter(
        (item) => item.marked == true && item.count >= count
      );
    },

    randomArray: function (arrayList) {
      if (arrayList.length === 0 || arrayList.length === null) return;
      // 继续优化，让random的元素和第i个元素换位置；这样就不需要临时变量resultArray了
      for (var i = 0; i < arrayList.length; i++) {
        var randomIndex = Math.floor(Math.random() * arrayList.length);
        var tempItem = arrayList[randomIndex];
        arrayList[randomIndex] = arrayList[i];
        arrayList[i] = tempItem;
      }
      return arrayList;
    },

    onCreateTestFormCancel: function () {
      this.dialogFormVisible = false;
      this.$emit("on-test-mode-form-cancel");
    },
    onCreateTestFormConfirm: function () {
      if (this.form.testName == "" || this.vocList.length <= 0) {
        this.$message("测试名称和单词集不能为空");
        return;
      }
      if (this.vocList.length > 300) {
        this.$message("测试词汇数量不能超过300");
        return;
      }

      let postData = {
        studentId: localStorage.getItem("targetId"),
        name: this.form.testName,
        type: this.form.testMode,
        description: this.form.testDescription,
        origin:
          this.task.project + "-" + this.task.subject + "-" + this.task.setName,
        status: "standing",
        nirvanaCount: 0,
        createdBy: localStorage.getItem("userId"),
        published: this.form.publishSwitch,
        countDown: this.form.testCountDown,
        enAnswerCountDown: this.form.enTypeCountDown,
        category: this.form.category,
      };
      this.$store
        .dispatch("StudentTest/createEditStudentTest", postData)
        .then((response) => {
          this.addStudentVocabularyAnswers(response.data);
          //拿到testId之后，把testId绑定到对应的task
          this.bindTestToTask(this.task.id, response.data, this.form.testMode);
        })
        .catch((error) => this.$message(error.toString()));
      this.$emit("on-test-mode-form-confirm", this.form);
      this.dialogFormVisible = false;
    },

    bindTestToTask: function (taskId, testId, testType) {
      this.$store
        .dispatch("StudentPlan/updateStudentPlanDetailTaskTestId", {
          taskId: taskId,
          testId: testId,
          testType: testType,
        })
        .then(() => {
          this.$emit("on-task-test-bound", {
            taskId: taskId,
            testId: testId,
            testType: testType,
          });
          this.$message({
            type: "success",
            message: "测试已绑定",
          });
        });
    },

    addStudentVocabularyAnswers: function (testId) {
      let postData = this.vocList.map((item) => {
        return {
          vocStudentTestId: testId,
          vocabularyId: item.vocabularyId,
          listeningCount: this.form.playCount,
        };
      });
      this.$store
        .dispatch("StudentTest/addVocabularyAnswers", postData)
        .then((response) => {
          if (response.data) {
            this.$message({
              type: "success",
              message: "测试已生成",
            });
          }
        })
        .catch((error) => this.$message(error.toString()));
    },

    handleRadioGroupChange: function (val) {
      console.log(val);
      switch (val) {
        case 1:
          this.form.testCountDown = 0;
          this.form.enTypeCountDown = 12;
          return;
        case 2:
          this.form.testCountDown = 12;
          this.form.enTypeCountDown = 12;
          return;
        case 3:
          this.form.testCountDown = 12;
          this.form.enTypeCountDown = 0;
          return;
        case 4:
          this.form.testCountDown = 12;
          this.form.enTypeCountDown = 0;
          return;
        case 5:
          this.form.testCountDown = 12;
          this.form.enTypeCountDown = 12;
          return;
        case 6:
          this.form.testCountDown = 0;
          this.form.enTypeCountDown = 12;
          return;
      }
    },

    // handleCheckedTagChange: function(value) {

    // },

    handleOpen: function () {
      //先重置vocList
      this.vocList = null;
      // initialize range index
      this.form.startIndex = null;
      this.form.endIndex = null;
      // reset tags
      this.form.checkedTags = [];
      console.log(this.task);
      this.getVocabularies();
    },

    handleClose: function () {
      // console.log('aaaa')
      this.$emit("on-tool-config-form-close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mark-heart {
  color: deepskyblue;
}

.cursor {
  cursor: pointer;
}

.tags-check-group {
}
</style>
