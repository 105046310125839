<template>
  <div>
    <el-dialog
      width="40%"
      v-model="dialogFormVisible"
      title="创建计划细节"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-form-item label="名称">
        <el-input
          v-model="form.name"
          placeholder="请输入计划名称"
          @keydown.stop
        >
        </el-input>
      </el-form-item>

      <el-form-item label="天数">
        <el-input-number
          v-model="form.totalDays"
          size="small"
          @keydown.stop
          placeholder="请输入该计划总天数"
        />
      </el-form-item>

      <div style="text-align: left">
        <span>描述</span>
      </div>
      <quill-editor v-model:value="form.description" />

      <el-form-item label="跨度">
        <el-date-picker
          v-model="form.daterange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="状态">
        <el-select v-model="form.status" placeholder="请选择">
          <el-option
            v-for="item in form.statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onCreatePlanFormDetailCancel()">Cancel</el-button>
          <el-button type="primary" @click="onCreatePlanFormDetailConfirm()"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CreatePlanDetailForm",
  components: {},

  props: ["modelValue", "studentPlanId", "editingPlanDetail"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  mounted: function () {},

  data() {
    return {
      dialogFormVisible: false,
      description: "",

      form: {
        studentPlanId: 0,
        name: "",
        description: "",
        evaluation: "",
        daterange: null,
        startDate: null,
        finishDate: null,
        totalDays: 0,
        status: "开始",
        statusOptions: [
          {
            value: 1,
            label: "开始",
          },
          {
            value: 2,
            label: "进行中",
          },
          {
            value: 3,
            label: "结束",
          },
        ],
        followUp: "",
        isEditing: false,
      },
    };
  },

  methods: {
    onCreatePlanFormDetailCancel: function () {
      this.dialogFormVisible = false;
      this.$emit("on-plan-form-detail-cancel");
    },
    onCreatePlanFormDetailConfirm: function () {
      //如果prop传进来的editingPlanDetail有东西，说明是在editing
      this.form.isEditing = this.editingPlanDetail ? true : false;
      this.form.startDate = this.form.daterange[0];
      this.form.finishDate = this.form.daterange[1];
      let postData = this.form;
      postData.createdBy = localStorage.getItem("userId");
      this.$store
        .dispatch("StudentPlan/addOrEditStudentPlanDetail", postData)
        .then((response) => {
          if (response.data > 0) {
            //返回成功
            postData.id = response.data;
            this.$emit("on-plan-form-detail-confirm", postData);
            this.dialogFormVisible = false;
            this.$message("已添加～");
            this.resetFormData();
          } else {
            this.$message("Something Wrong~");
          }
        });
    },

    resetFormData: function () {
      this.form = {
        studentPlanId: 0,
        name: "",
        description: "",
        evaluation: "",
        daterange: null,
        startDate: null,
        finishDate: null,
        totalDays: 0,
        status: 1,
        statusOptions: [
          {
            value: 1,
            label: "开始",
          },
          {
            value: 2,
            label: "进行中",
          },
          {
            value: 3,
            label: "结束",
          },
        ],
        followUp: "",
      };
    },

    handleOpen: function () {
      //打开form的时候，把property传进来的planid放到form里面
      this.form.studentPlanId = this.studentPlanId;
      if (this.editingPlanDetail) {
        this.form = this.editingPlanDetail;
        //generate daterange
        this.form.daterange = [
          new Date(this.editingPlanDetail.startDate),
          new Date(this.editingPlanDetail.finishDate),
        ];
      } else {
        this.form.daterange = [new Date(), new Date()];
      }
    },

    handleClose: function () {
      // console.log('aaaa')
      this.$emit("on-plan-form-detail-close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mark-heart {
  color: deepskyblue;
}

.cursor {
  cursor: pointer;
}

.tags-check-group {
}
</style>
