<template>
  <div>
    <el-drawer
      v-model="drawer"
      :before-close="handleClose"
      direction="ttb"
      :title="projectType == 0 ? 'Andover' : 'User'"
      @close="$emit('on-add-project-drawer-close')"
      size="100%"
    >
      <el-container id="addProjectContainer">
        <el-main>
          <el-row :gutter="20" class="rows">
            <el-col :span="18">
              <el-input v-model="projectName" placeholder="项目名称">
                <template #prepend>项目名称(*)</template>
              </el-input>
            </el-col>

            <el-col :offset="2" :span="4">
              <el-button
                type="primary"
                @click="handleAddProject"
                :disabled="buttons.addProjectDisabled"
              >
                新建项目
                <i class=""></i>
              </el-button>
            </el-col>
          </el-row>

          <el-row :gutter="20" class="rows">
            <el-col :span="18">
              <el-input v-model="subjectName" placeholder="科目名称">
                <template #prepend>科目名称(*)</template>
              </el-input>
            </el-col>

            <el-col :offset="2" :span="4">
              <el-button type="primary" @click="handleAddSubject">
                新建科目
                <i class=""></i>
              </el-button>
            </el-col>
          </el-row>

          <el-row :gutter="20" class="rows">
            <el-col :span="18">
              <el-input v-model="setName" placeholder="集合名称">
                <template #prepend>集合名称(*)</template>
              </el-input>
            </el-col>

            <el-col :offset="2" :span="4">
              <el-button type="primary" @click="handleAddSet">
                新建集合
                <i class=""></i>
              </el-button>
            </el-col>
          </el-row>

          <voc-add-many-content
            @on-voc-list-updated="handleOnVocListUpdated($event)"
          />
        </el-main>
      </el-container>
    </el-drawer>
  </div>
</template>

<script>
import VocAddManyContent from "./VocAddManyContent.vue";
import { ElMessage } from "element-plus";
import { mapGetters } from "vuex";
export default {
  name: "AddProjectDrawer",

  components: {
    VocAddManyContent,
  },

  watch: {
    modelValue: function () {
      this.drawer = this.modelValue;
    },
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    projectType: {
      type: Number,
    },
  },

  computed: {
    ...mapGetters("Project", ["addedProject"]),
    ...mapGetters("Subject", ["addedSubject"]),
  },

  data() {
    return {
      drawer: false,
      projectName: "",
      subjectName: "",
      setName: "",
      vocList: [],
      buttons: {
        addProjectDisabled: false,
      },
    };
  },
  mounted: function () {},

  methods: {
    handleClose: function (done) {
      this.$emit("on-add-project-drawer-closed");
      done();
    },

    handleOnVocListUpdated: function (vocList) {
      console.log(vocList);
      this.vocList = vocList;
    },

    handleAddProject: function () {
      if (this.projectName == "") {
        ElMessage({
          message: "请输入项目名称.",
          type: "warning",
        });
        return;
      }

      //出现了projectType是0（教材），但是用户不是admin，却可以加教材的情况；加入一个判断，排除这种情况
      if (this.projectType == 0 && localStorage.getItem("userRole") != "Admin")
        return;

      this.$store
        .dispatch("Project/addProject", {
          userId: localStorage.getItem("targetId"),
          type: this.projectType,
          name: this.projectName,
        })
        .then((response) => {
          ElMessage({
            message: "添加项目.",
            type: "success",
          });
          this.buttons.addProjectDisabled = true;
          console.log(response);
        })
        .catch((error) => console.log(error));
    },

    handleAddSubject: function () {
      if (this.subjectName == "") {
        ElMessage({
          message: "请输入科目名称.",
          type: "warning",
        });
        return;
      }
      if (!this.addedProject.projectId) {
        ElMessage({
          message: "没有可匹配的项目.",
          type: "warning",
        });
        return;
      }

      this.$store
        .dispatch("Subject/add", {
          vocProjectId: this.addedProject.projectId,
          name: this.subjectName,
        })
        .then(() => {
          ElMessage({
            message: "添加科目.",
            type: "success",
          });
        })
        .catch((error) => console.log(error));
    },

    handleAddSet: function () {
      if (this.setName == "") {
        ElMessage({
          message: "请输入集合名称.",
          type: "warning",
        });
        return;
      }
      if (!this.addedSubject.subjectId) {
        ElMessage({
          message: "没有可匹配的科目.",
          type: "warning",
        });
        return;
      }

      this.$store
        .dispatch("Set/add", {
          userId: localStorage.getItem("targetId"),
          name: this.setName,
        })
        .then((response) => {
          ElMessage({
            message: "添加集合.",
            type: "success",
          });
          console.log(response);
          this.addSetToSubject(response.data, this.addedSubject.subjectId);
        })
        .catch((error) => console.log(error));
    },

    addSetToSubject: function (setId, subjectId) {
      this.$store
        .dispatch("Set/addSetToSubject", {
          vocSetId: setId,
          vocSubjectId: subjectId,
        })
        .then(() => {
          ElMessage({
            message: "绑定集合到科目.",
            type: "success",
          });
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.rows {
  margin-bottom: 10px;
}
</style>
