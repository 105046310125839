<template>
  <div>
    <el-dialog
      title="编辑单词"
      v-model="dialogFormVisible"
      @close="onVocFormClose()"
      @open="onVocFormOpen()"
    >
      <el-form :model="form">
        <el-form-item label="单词" :label-width="formLabelWidth">
          <el-input
            v-model="form.english"
            autocomplete="off"
            @keydown.stop
          ></el-input>
        </el-form-item>
        <el-form-item label="释义" :label-width="formLabelWidth">
          <el-input
            v-model="form.chinese"
            autocomplete="off"
            @keydown.stop
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="picUploader" v-if="isAdmin">
        <el-upload
          ref="imageUploader"
          :action="vocImageUploadUrl"
          list-type="picture-card"
          :limit="1"
          :on-preview="handlePictureCardPreview"
          :on-success="handleUploadSuccess"
          :on-remove="handleRemove"
          :headers="imageUploadHeader"
        >
          <el-icon><plus /></el-icon>
        </el-upload>
        <el-dialog v-model="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
      </div>
      <template #footer>
        <el-button @click="onVocFormCancel()" v-if="isAdmin">取 消</el-button>
        <el-button type="primary" @click="onVocFormConfirm()">确 定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import url from "../../store/urls";
//import axios from "axios";

export default {
  name: "VocEditForm",
  props: ["modelValue", "vocItem", "currentVocIndex"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  data() {
    return {
      userRole: "",
      isAdmin: false,
      dialogFormVisible: false,
      currentUserId: null,
      currentTargetId: null,
      form: {
        english: "",
        chinese: "",
        vocId: null,
        imagePath: null,
      },
      currentImageExtension: null,
      formLabelWidth: "120px",

      dialogImageUrl: "",
      dialogVisible: false,
    };
  },

  computed: {
    vocImageUploadUrl: function () {
      let postUrl = url.ImageUploadUrl + "?vocabularyId=" + this.form.vocId;
      return postUrl;
    },

    imageUploadHeader: function () {
      return {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Encoding": "gzip",
      };
    },
  },

  mounted: function () {
    this.userRole = localStorage.getItem("userRole");
    this.currentTargetId = localStorage.getItem("targetId");
    this.currentUserId = localStorage.getItem("userId");
    if (this.userRole === "Admin") this.isAdmin = true;
  },

  methods: {
    onVocFormCancel: function () {
      this.dialogFormVisible = false;
      this.$emit("on-voc-edit-form-cancel");
    },
    onVocFormConfirm: function () {
      //加了这个clear之后，下次打开没法上传图片了
      //this.$refs.imageUploader.clearFiles(); // clear uploaded files
      this.dialogFormVisible = false;
      this.$store
        .dispatch("Vocabulary/edit", {
          id: this.form.vocId,
          englishText: this.form.english,
          chineseText: this.form.chinese,
          imagePath: this.form.imagePath,
        })
        .then(() => {
          this.$emit("on-voc-edit-form-confirm", this.form);
        })
        .catch((error) => console.log(error.toString()));

      // this.editVoc(
      //   this.form.vocId,
      //   this.form.english,
      //   this.form.chinese,
      //   this.currentUserId
      // );
      //this.$emit("on-voc-edit-form-confirm", this.form);
    },
    onVocFormOpen: function () {
      // console.log(this.$refs.imageUploader)
      // if (
      //   this.$refs.imageUploader != null ||
      //   this.$refs.imageUploader != undefined
      // )
      //   this.$refs.imageUploader.clearFiles(); // clear uploaded files
      this.form.english = this.vocItem.englishText;
      this.form.chinese = this.vocItem.chineseText;
      this.form.vocId = this.vocItem.vocabularyId;
      this.form.imagePath = this.vocItem.imagePath;
      console.log(this.form);
      //this.form.selectedOption = this.folderPathStorage;
    },
    onVocFormClose: function () {
      this.$emit("on-voc-edit-form-close");
    },

    // editVoc: function (vocId, enText, cnText, updatedBy) {
    //   let postData = {
    //     Id: vocId,
    //     EnglishText: enText,
    //     ChineseText: cnText,
    //     UpdatedBy: updatedBy,
    //   };

    //   var currentUrl = process.env.API_ROOT_URL + "/EditVocabulary";
    //   axios
    //     .post(currentUrl, postData)
    //     .then(() => {
    //       this.$message({
    //         type: "success",
    //         message: "更新单词",
    //       });
    //       // to next step
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    handleUploadSuccess: function (response, file) {
      if (response) {
        this.currentImageExtension = file.name.substring(
          file.name.lastIndexOf(".")
        );
      }
      this.form.imagePath =
        this.form.vocId.toString() + this.currentImageExtension;
      console.log(this.form);
    },

    // --- methods of image uploader ---
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // --- methods of image uploader ---

    handleItemChange: function () {
      //console.log(data);
    },

    handleSelectedItemChange: function () {
      //this.folderPathStorage = this.form.selectedOption; // cache the selected option
    },
  },
};
</script>

<style scoped>
div.folderField {
  float: left;
}
</style>
