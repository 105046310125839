<template>
  <div>
    <el-dialog
      width="80%"
      v-model="dialogFormVisible"
      :title="
        localDecodedQueryObject.projectName +
        ' > ' +
        localDecodedQueryObject.subjectName +
        ' > ' +
        localDecodedQueryObject.setName
      "
      @open="handleOpen"
      @close="handleClose"
    >
      <el-form :model="overviewForm" label-width="200px">
        <el-form-item label="词汇总数">
          <span>{{ this.currentVocList.length }}</span>
        </el-form-item>
        <el-row>
          <el-col :span="10">
            <el-form-item label="检查数量">
              <el-input v-model="overviewForm.examineCount" />
            </el-form-item>
          </el-col>
          <el-col :offset="4" :span="10">
            <el-form-item label="标记数量">
              <el-input v-model="overviewForm.markedCount" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="类型">
          <el-radio-group v-model="overviewForm.checkType">
            <el-radio label="日常词汇" />
            <el-radio label="阶段测试" />
          </el-radio-group>
        </el-form-item>

        <el-form-item label="检查方式">
          <el-radio-group v-model="overviewForm.checkVocType">
            <el-radio label="看抽" />
            <el-radio label="听抽" />
            <el-radio label="听写" />
          </el-radio-group>
        </el-form-item>

        <el-form-item label="是否过关">
          <el-radio-group v-model="overviewForm.checkBriefSummary">
            <el-radio label="过关" />
            <el-radio label="未过关" />
            <el-radio label="未检查" />
          </el-radio-group>
        </el-form-item>

        <el-form-item label="学习集轮次">
          <el-radio-group v-model="overviewForm.setHowManyTimes">
            <el-radio label="盲筛" />
            <el-radio label="第一轮" />
            <el-radio label="第二轮" />
            <el-radio label="第三轮" />
            <el-radio label="第三+轮" />
          </el-radio-group>
        </el-form-item>

        <el-form-item label="科目轮次">
          <el-radio-group v-model="overviewForm.subjectHowManyTimes">
            <el-radio label="第一轮" />
            <el-radio label="第二轮" />
            <el-radio label="第三轮" />
            <el-radio label="第三+轮" />
          </el-radio-group>
        </el-form-item>

        <el-form-item label="当前标记词">
          <el-input v-model="overviewForm.markedVocEnglish" type="textarea" />
        </el-form-item>

        <el-form-item label="备注">
          <el-input v-model="overviewForm.desc" type="textarea" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onCheckingOverviewFormCancel()">Cancel</el-button>
          <el-button type="primary" @click="onCheckingOverviewFormConfirm()"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ToolConfigForm",

  props: ["modelValue", "decodedQueryObject", "currentVocList"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  data() {
    return {
      dialogFormVisible: false,
      overviewForm: {
        examineCount: 0,
        markedCount: 0,
        checkType: "",
        checkVocType: "",
        checkBriefSummary: "",
        setHowManyTimes: "",
        subjectHowManyTimes: "",
        desc: "",
        markedVocEnglish: "",
        markedVocEnglishArrayCache: [],
      },
      localDecodedQueryObject: {},
      localCurrentVocList: [],
    };
  },

  methods: {
    onCheckingOverviewFormCancel: function () {
      this.dialogFormVisible = false;
      this.$emit("on-checking-overview-form-cancel");
    },
    onCheckingOverviewFormConfirm: function () {
      this.dialogFormVisible = false;
      this.$emit("on-checking-overview-form-confirm", this.overviewForm);
    },

    appendVocEnglishToCache: function (en) {
      this.overviewForm.markedVocEnglishArrayCache.push(en);
    },

    removeVocEnglishToCache: function (en) {
      if (this.overviewForm.markedVocEnglishArrayCache.indexOf(en) >= 0) {
        this.overviewForm.markedVocEnglishArrayCache.splice(
          this.overviewForm.markedVocEnglishArrayCache.indexOf(en),
          1
        );
      }
    },

    handleOpen: function () {
      this.localDecodedQueryObject = this.decodedQueryObject;
      this.localCurrentVocList = this.currentVocList;
      this.overviewForm.markedVocEnglish = this.overviewForm.markedVocEnglishArrayCache.join(", ");
    },

    handleClose: function () {},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cursor {
  cursor: pointer;
}
</style>
