<template>
  <div>
    <el-select
      v-model="selectRemoteStudents.value"
      filterable
      defalt-first-option
      remote
      :remote-method="getStudentsByName"
      placeholder="远程搜索学生"
      @change="handleRemoteStudentSelected"
      :loading="selectRemoteStudents.loading"
      @keydown.stop
    >
      <el-option
        v-for="item in selectRemoteStudents.options"
        :key="item.userId"
        :label="item.userName + ':' + item.chineseName"
        :value="item"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  name: "RemoteSearchStudentInputbox",
  props: [],
  watch: {},
  data() {
    return {
      search: "",
      selectRemoteStudents: {
        value: "",
        options: [],
        loading: false,
      },
    };
  },
  mounted: function () {
    //改成默认请求数据；且鼠标离开的时候，不会清空数据
    this.handleMouseEnter();
  },
  methods: {
    //鼠标进入的时候才请求数据
    handleMouseEnter: function () {
      this.$store
        .dispatch("Admin/getRelationship", {
          userId: this.currentUserId,
        })
        .then((response) => {
          this.boundStudentList = response.data;
          //找到最后一个匹配自身的index，然后如果不是第一个，就移除，以免有两个自己
          let lastIndexOfSelf = this.boundStudentList.findLastIndex(
            (item) => item.targetId == this.currentUserId
          );
          if (lastIndexOfSelf > 0) {
            this.boundStudentList.splice(lastIndexOfSelf, 1);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //鼠标离开的时候清空数据
    // handleMouseLeave: function() {
    //   this.boundStudentList = [];
    // },

    getStudentsByName: function (val) {
      this.selectRemoteStudents.loading = true;
      this.$store
        .dispatch("Admin/getAllStudentsByCriteria", {
          studentName: val.trim(),
        })
        .then((response) => {
          this.selectRemoteStudents.loading = false;
          this.selectRemoteStudents.options = response.data;
        })
        .catch((error) => {
          console.log(error.toString());
          this.selectRemoteStudents.loading = false;
        });
    },

    handleRemoteStudentSelected: function (student) {
      this.$emit("on-remote-student-selected", student);

      //clear data in select options
      this.selectRemoteStudents = {
        value: "",
        options: [],
        loading: false,
      };
    },

  },
};
</script>

<style>
.hightlightCurrentText {
  color: deepskyblue;
}
</style>
