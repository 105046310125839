import axios from "axios";
import url from "../urls";

// 给listening 的非login请求需要再发request之前将withCredentials设置成false
const service = axios.create({
  withCredentials: false,
});

export default {
  //put
  // add(context, data) {
  //   return new Promise((resolve, reject) => {
  //     let addUrl = url.projectUrl;
  //     axios
  //       .put(addUrl, data)
  //       .then((response) => {
  //         context.commit("mutateAddedProject", {
  //           projectId: 5,
  //           projectName: data.name,
  //         });
  //         resolve(response);
  //       })
  //       .catch((error) => reject(error));
  //   });
  // },
  //get
  get(context, data) {
    return new Promise((resolve, reject) => {
      let getUrl = url.ListeningRelationshipManagement;

      service
        .get(getUrl, {
          params: data,
        })
        .then((response) => {
          //console.log("question got");
          //context.commit("mutateCompletionQuestions", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //get
  getAllStudentsByCriteria(context, data) {
    return new Promise((resolve, reject) => {
      let getUrl = url.ListeningGetAllStudentsByCriteria;

      service
        .get(getUrl, {
          params: data,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //get
  getRelationship(context, data) {
    return new Promise((resolve, reject) => {
      let getUrl = url.ListeningRelationship;

      service
        .get(getUrl, {
          params: data,
        })
        .then((response) => {
          //console.log("question got");
          //context.commit("mutateCompletionQuestions", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //post
  bindStudent(context, data) {
    return new Promise((resolve, reject) => {
      let bindUrl = url.ListeningRelationshipManagement;
      service
        .post(bindUrl, data)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //delete
  // delete(context, data) {
  //   return new Promise((resolve, reject) => {
  //     let deleteUrl = url.projectUrl;
  //     axios
  //       .delete(deleteUrl, { params: data })
  //       .then((response) => {
  //         console.log(response);
  //         resolve(response);
  //       })
  //       .catch((error) => reject(error));
  //   });
  // },

  //get listening article by title
  //这个接口调用listening的用标题模糊匹配文章的接口，用于获得特定文章的articleId
  //这个接口可以获得名字匹配的多个ids，但是如果名字全，就只返回一个，比如omg1c1
  getListeningArticleByTitle(context, params) {
    return new Promise((resolve, reject) => {
      let getUrl = url.ListeningGetArticlesByTitle;
      service
        .get(getUrl, {
          params: {
            title: params.title
          }
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //通过拿到的article对象的id，获得该id下所有句子，以便获得句子音频路劲
  getSentencesByArticleId(context, params) {
    return new Promise((resolve, reject) => {
      let getUrl = url.ListeningGetSentencesByArticleId;
      service
        .get(getUrl, {
          params: {
            articleId: params.articleId
          }
        })
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //get
  getAllTeachersAndAdmins() {
    return new Promise((resolve, reject) => {
      let getAllTeachersAndAdmins = url.getAllTeachersAndAdmins;

      service
        .get(getAllTeachersAndAdmins)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
