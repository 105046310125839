<template>
  <div>
    <el-input
      class="mt-4"
      style="width: 100%"
      v-model="unorganizedSetSearchString"
      placeholder="此处输入集合名称来过滤集合"
    ></el-input>
    <el-card class="box-card"
      :body-style="{ 
        display: showCardBody ? '' : 'none'
      }"
      @mouseenter="handleMouseEnter"
      @mouseleave="showCardBody = false"
    >
      <template #header>
        <div class="card-header">
          <span>无处安放的卡片</span>
          <span
            class="add-set-button icon-group"
            @click="$emit('on-add-unorganized-set-click')"
            ><el-icon><folder-add /></el-icon
          ></span>
        </div>
      </template>
      <div
        v-for="(item, index) in sets.filter(
          (data) =>
            !unorganizedSetSearchString ||
            data.name
              .toLowerCase()
              .includes(unorganizedSetSearchString.toLowerCase())
        )"
        :key="index"
        class="text item cursorPointer"
      >
        <div @click="handleSetClick(item)">{{ item.name }}</div>
        <div>
          <span class="icon-group" @click="handleEditSetClick(item)"
            ><el-icon> <edit /> </el-icon
          ></span>
          <span class="icon-group" @click="handleDeleteSetClick(item, index)"
            ><el-icon><delete /></el-icon
          ></span>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
export default {
  name: "UnorganizedSetList",

  mounted: function () {
    this.getUserUnorganizedSets();
  },

  data() {
    return {
      sets: [],

      iconSize: 14,

      unorganizedSetSearchString: "",

      showCardBody: false,
    };
  },

  methods: {
    getUserUnorganizedSets: function () {
      this.$store
        .dispatch("Set/getUserUnorganizedSets", {
          userId: localStorage.getItem("targetId"),
        })
        .then((response) => {
          this.sets = response.data;
        })
        .catch((error) => console.log(error.toString()));
    },

    handleMouseEnter: function() {
      this.getUserUnorganizedSets();
      this.showCardBody = !this.showCardBody;
    },

    handleSetClick: function (set) {
      localStorage.setItem("projectType", 1);
      // 如果传的setIds里面只有一个id，也就是仅打开一个set，则传入setName,以便在voc card页面显示set name
      let queryObject = {
        userId: localStorage.getItem("targetId"),
        userName: localStorage.getItem("targetCNName"),
        setIds: [set.id],
        setName: set.name,
        projectType: localStorage.getItem("projectType"),
      };
      // queryObject = JSON.stringify(queryObject)
      // queryObject = window.encodeURIComponent(queryObject)
      // console.log(window.btoa(queryObject));
      // // queryObject = window.decodeURIComponent(queryObject);
      // console.log(window.atob(window.btoa(queryObject)));
      // queryObject = window.atob(window.btoa(queryObject));
      // queryObject = window.decodeURIComponent(queryObject);
      // console.log(queryObject)
      this.openAsNewPageWithCryption("/card", queryObject);
      //this.openAsNewPage("/card", queryObject);
    },

    openAsNewPageWithCryption: function (routeName, query) {
      query = JSON.stringify(query);
      query = window.encodeURIComponent(query);
      let newpage = this.$router.resolve({
        path: routeName,
        query: { encodedString: window.btoa(query) },
      });
      window.open(newpage.href, "_blank");
    },

    handleEditSetClick: function (set) {
      this.$prompt("请输入新集合名", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: set.name,
        inputPattern: /^\s*[\s\S]{1,20}\s*$/,
        inputErrorMessage: "集合名称过长",
      }).then(({ value }) => {
        set.name = value;
        this.$store
          .dispatch("Set/edit", {
            id: set.id,
            name: value,
          })
          .then(() => {
            ElMessage({
              message: "Set Renamed",
              type: "success",
            });
          })
          .catch((error) => console.log(error.toString()));
      });
    },

    handleDeleteSetClick: function (set, index) {
      this.$confirm("此操作将永久删除该集合下所有单词, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store
            .dispatch("Set/delete", {
              setId: set.id,
            })
            .then(() => this.sets.splice(index, 1))
            .catch((error) => console.log(error.toString()));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    openAsNewPage: function (routeName, query) {
      let newpage = this.$router.resolve({
        path: routeName,
        query: query,
      });
      window.open(newpage.href, "_blank");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
  display: flex;
  justify-content: space-between;

  flex: 1;
}

.item {
  margin-bottom: 18px;
}
.box-card {
  margin-top: 10px;
  width: 100%;
}

.icon-group {
  margin-right: 5px;
  cursor: pointer;
}

.cursorPointer {
  cursor: pointer;
}

.add-set-button {
  cursor: pointer;
  color: deepskyblue;
}
</style>
