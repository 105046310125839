<template>
  <div>
    <el-dialog
      width="80%"
      v-model="dialogFormVisible"
      title="绑定每日任务"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-form-item v-if="plans" label="计划">
        <el-select
          v-model="form.plan"
          placeholder="请选择"
          @change="handlePlanSelect"
        >
          <el-option
            v-for="item in plans"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
            <span>{{ item.name }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="plans && form.plan" label="执行">
        <el-select
          @change="handleDetailChange"
          v-model="form.detail"
          multiple
          placeholder="请选择"
        >
          <el-option
            v-for="item in plans.find((item) => item.name == form.plan)
              .studentPlanDetails"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <span>{{ item.name }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="任务">
        <el-checkbox
          v-model="checkAllTasks"
          :indeterminate="isIndeterminate"
          @change="handleCheckAllChange"
          >Check all</el-checkbox
        >
        <div class="task-check-box-group">
          <el-checkbox-group
            v-model="checkedTasks"
            @change="handleCheckedTasksChange"
          >
            <el-checkbox
              v-for="task in taskList"
              :key="task.id"
              :label="task.id"
              style="display: block; text-align: left"
              >{{
                task.name +
                ":" +
                studyTypeFormatter(task.studyType) +
                ":" +
                examModeFormatter(task.examMode) +
                ":" +
                task.setName
              }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </el-form-item>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onBindPlanDailyTaskCancel()">Cancel</el-button>
          <el-button type="primary" @click="onBindPlanDailyTaskConfirm()"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "BindPlanDailyTaskForm",
  components: {},

  props: ["modelValue", "plans", "daily"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  mounted: function () {},

  data() {
    return {
      dialogFormVisible: false,
      taskList: [],
      checkAllTasks: false,
      checkedTasks: [],
      isIndeterminate: true,
      selectedPlan: {},
      selectedDetail: {},

      form: {
        plan: "",
        detail: "",
      },
    };
  },

  methods: {
    onBindPlanDailyTaskCancel: function () {
      this.dialogFormVisible = false;
      this.$emit("on-bind-plan-daily-task-cancel");
    },
    onBindPlanDailyTaskConfirm: function () {
      if (!this.daily) return;
      this.$emit("on-bind-plan-daily-task-confirm");
      //用checkedTasks里面的id，获取task对象
      for (let i = 0; i < this.checkedTasks.length; i++) {
        this.$store
          .dispatch("StudentPlan/addStudentPlanDailyTask", {
            studentPlanDailyId: this.daily.id,
            studentPlanDetailTaskId: this.checkedTasks[i],
          })
          .then((response) => {
            if (response.data > 1) {
              //用task id找到具体的task object，再用事件传出去
              let task = this.taskList.find(
                (item) => item.id == this.checkedTasks[i]
              );
              this.$emit("on-plan-daily-task-bound", {
                task: task,
                daily: this.daily,
                dailyTaskId: response.data,
              });
              this.$message("任务已绑定");
            }
          });
      }
    },

    handleOpen: function () {
      //清空已选tasks
      this.checkedTasks = [];
      //默认选择最近的计划
      this.form.plan = this.plans[0].name;
      this.selectedPlan = this.plans[0];
    },

    handleClose: function () {
      // console.log('aaaa')
      this.$emit("on-bind-plan-daily-task-close");
    },

    handlePlanSelect: function (val) {
      this.selectedPlan = this.plans.find((item) => item.name === val);
    },

    handleDetailChange: function () {
      console.log("detail on blur");
      // if (!this.form.detail || this.selectedPlan.studentPlanDetails.length <= 0)
      //   return;
      // 清空task list
      this.taskList = [];
      // 清空之前选择的sets
      this.clearcheckedTasks();
      console.log(this.selectedPlan);
      //获取选中的plan下，选中的details的tasks集合
      for (let i = 0; i < this.form.detail.length; i++) {
        //this.form.detail[i] 是选中的detail的id
        let tasks = this.selectedPlan.studentPlanDetails.find(
          (item) => item.id == this.form.detail[i]
        ).studentPlanDetailTasks;
        for (let j = 0; j < tasks.length; j++) {
          this.taskList.push(tasks[j]);
        }
      }
    },

    handleCheckAllChange: function (event) {
      let tempcheckedTasks = [];
      let tempCheckedAllTaskList = this.taskList;
      if (event) {
        for (let i = 0; i < tempCheckedAllTaskList.length; i++) {
          tempcheckedTasks.push(tempCheckedAllTaskList[i].id);
        }
      }

      this.checkedTasks = event ? tempcheckedTasks : [];
      this.isIndeterminate = false;
    },

    handleCheckedTasksChange: function (event) {
      const checkedCount = event.length;
      this.checkAllTasks = checkedCount === this.taskList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.taskList.length;
    },

    clearcheckedTasks: function () {
      this.checkAllTasks = false;
      this.isIndeterminate = true;
      this.checkedTasks = [];
    },

    studyTypeFormatter: function (type) {
      if (!type) {
        return "";
      }
      let typeObject = [
        {
          id: 1,
          name: "盲筛",
        },
        {
          id: 2,
          name: "背诵",
        },
        {
          id: 3,
          name: "星标",
        },
        {
          id: 4,
          name: "复习",
        },
        {
          id: 5,
          name: "测试",
        },
      ];
      let resultName = typeObject.find((item) => item.id === type).name;
      return resultName;
    },

    examModeFormatter: function (mode) {
      if (!mode) {
        return "";
      }

      let modeObject = [
        {
          id: 1,
          name: "英翻中",
        },
        {
          id: 2,
          name: "中翻英",
        },
        {
          id: 3,
          name: "显示中英文",
        },
        {
          id: 4,
          name: "英翻中(仅音频)",
        },
      ];
      let resultName = modeObject.find((item) => item.id === mode).name;
      return resultName;
    },

    handleRadioGroupChange: function () {},

    handleStudyTypeRadioGroupChange: function () {},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cursor {
  cursor: pointer;
}
</style>
