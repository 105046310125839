<template>
  <shared-header
    ref="sharedHeader"
    @on-bound-student="handleOnBoundStudent()"
  />
  <bound-student-list-drawer
    ref="boundStudentListDrawer"
    @on-bound-student-click="handleOnBoundStudentClick"
  />
  <test-list ref="studentTestList" class="test-list-container" />
</template>

<script>
import SharedHeader from "../../shared/SharedHeader.vue";
import TestList from "./TestList.vue";
import BoundStudentListDrawer from "../../admin/BoundStudentListDrawer.vue"
export default {
  name: "Index",
  components: {
    SharedHeader,
    TestList,
    BoundStudentListDrawer
  },
  data() {
    return {};
  },
  computed: {},
  mounted: function () {},
  methods: {
    handleOnBoundStudent: function () {
      this.$refs.boundStudentListDrawer.openDrawer();
    },

    handleOnBoundStudentClick: function() {
      this.$refs.sharedHeader.updateBoundStudentChineseName();
      this.handleBoundStudentChange();
    },

    handleBoundStudentChange: function() {
      //get test list
      this.$refs.studentTestList.getStudentTestList();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.test-list-container {
  margin-top: 10px;
  margin-left: 10%;
}
</style>
