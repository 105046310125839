<template>
  <div>
    <el-card class="box-card">
      <el-table
        ref="studentListTable"
        :data="boundStudentList"
        highlight-current-row
        @current-change="onStudentClick"
      >
        <el-table-column prop="targetName">
          <template #header>
            <el-select
              v-model="selectRemoteStudents.value"
              filterable
              defalt-first-option
              remote
              :remote-method="getStudentsByName"
              placeholder="远程搜索学生"
              @change="handleRemoteStudentSelected"
              :loading="selectRemoteStudents.loading"
            >
              <el-option
                v-for="item in selectRemoteStudents.options"
                :key="item.userId"
                :label="item.userName + ':' + item.chineseName"
                :value="item.userId"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="中文名" prop="chineseName"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
export default {
  name: "NewBindStudent",
  props: [],
  watch: {},
  data() {
    return {
      currentUserId: "",
      isAdminOrTeacher: null,
      sentenceIndex: 0,
      boundStudentList: [],
      search: "",
      selectRemoteStudents: {
        value: "",
        options: [],
        loading: false,
      },
      showTableContent: false,
    };
  },
  mounted: function () {
    this.setPermission();
    this.currentUserId = localStorage.getItem("userId"); // get current user Id
    //改成默认请求数据；且鼠标离开的时候，不会清空数据
    this.handleMouseEnter();
  },
  methods: {
    //鼠标进入的时候才请求数据
    handleMouseEnter: function() {
      this.$store
      .dispatch("Admin/getRelationship", {
        userId: this.currentUserId,
      })
      .then((response) => {
        this.boundStudentList = response.data;
        //找到最后一个匹配自身的index，然后如果不是第一个，就移除，以免有两个自己
        let lastIndexOfSelf = this.boundStudentList.findLastIndex(item => item.targetId == this.currentUserId);
        if(lastIndexOfSelf > 0) {
          this.boundStudentList.splice(lastIndexOfSelf, 1)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    //鼠标离开的时候清空数据
    // handleMouseLeave: function() {
    //   this.boundStudentList = [];
    // },
    onStudentClick: function (targetInfo) {
      if (targetInfo === null) return;
      this.setTargetInfoOnStorage(targetInfo.targetId, targetInfo.chineseName);
      this.$emit("on-bound-student-click", targetInfo);

      this.$refs.studentListTable.setCurrentRow();
    },
    setPermission: function () {
      if (
        localStorage.getItem("userRole") === "Admin" ||
        localStorage.getItem("userRole") === "Teacher"
      )
        this.isAdminOrTeacher = true;
      else this.isAdminOrTeacher = false;
    },

    getStudentsByName: function (val) {
      this.selectRemoteStudents.loading = true;
      this.$store
        .dispatch("Admin/getAllStudentsByCriteria", {
          studentName: val.trim(),
        })
        .then((response) => {
          this.selectRemoteStudents.loading = false;
          console.log(response);
          this.selectRemoteStudents.options = response.data;
        })
        .catch((error) => {
          console.log(error.toString());
          this.selectRemoteStudents.loading = false;
        });
    },

    handleRemoteStudentSelected: function (studentId) {
      let seletedStudent = this.selectRemoteStudents.options.find(
        (item) => item.userId == studentId
      );
      if (!seletedStudent) return;
      this.setTargetInfoOnStorage(
        seletedStudent.userId,
        seletedStudent.chineseName
      );
      this.$emit("on-remote-select-student-click");

      //clear data in select options
      this.selectRemoteStudents = {
        value: "",
        options: [],
        loading: false,
      }
    },

    setTargetInfoOnStorage: function (targetId, targetCNName) {
      localStorage.setItem("targetId", targetId);
      localStorage.setItem("targetCNName", targetCNName);
      this.bindStudent();
    },

    bindStudent: function () {
      let data = {
        sourceId: localStorage.getItem("userId"),
        targetId: localStorage.getItem("targetId"),
        enabled: true,
      };

      this.$store
        .dispatch("Admin/bindStudent", data)
        .then(() => {
          ElMessage({
            message: "已绑定" + localStorage.getItem("targetCNName"),
            type: "success",
          });
        })
        .catch((error) => console.log(error.toString()));
    },
  },
};
</script>

<style>
.hightlightCurrentText {
  color: deepskyblue;
}
</style>
