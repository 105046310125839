<template>
  <div>
    <el-dialog
      width="30%"
      v-model="dialogFormVisible"
      title="生成测试"
      @open="handleOpen"
      @close="handleClose"
    >
      <div style="margin-bottom: 10px">
        <el-select
          v-model="form.assigningStudentId"
          filterable
          class="m-2"
          placeholder="选择学生"
          @change="handleSelectStudentChange"
        >
          <el-option
            v-for="item in form.boundStudentList"
            :key="item.targetId"
            :label="item.chineseName"
            :value="item.targetId"
          />
        </el-select>
      </div>
      <el-form-item label="自动发布">
        <el-switch
          v-model="form.publishSwitch"
          active-color="#13ce66"
          inactive-color="gray"
        />
      </el-form-item>
      <el-form-item label="测试名称">
        <el-input
          v-model="form.testName"
          placeholder="请输入测试名称"
          @keydown.stop
        >
        </el-input>
      </el-form-item>

      <el-form-item label="测试描述">
        <el-input
          v-model="form.testDescription"
          placeholder="请输入测试描述"
          @keydown.stop
        >
        </el-input>
      </el-form-item>

      <el-form :model="form">
        <el-form-item label="测试模式">
          <el-radio-group
            v-model="form.testMode"
            @change="handleRadioGroupChange($event)"
          >
            <el-radio :label="1">听写英文</el-radio>
            <el-radio :label="2">听写中英</el-radio>
            <el-radio :label="3">听英写中</el-radio>
            <el-radio :label="4">看英写中</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onCreateTestFormCancel()">Cancel</el-button>
          <el-button type="primary" @click="onCreateTestFormConfirm()"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "DuplicateTestForm",

  props: ["modelValue", "originalTestInfo"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  data() {
    return {
      dialogFormVisible: false,
      userId: 0,
      targetId: 0,

      form: {
        testName: "",
        testDescription: "",
        testMode: 1,
        assigningStudentId: null,
        boundStudentList: [],
        publishSwitch: false,
      },
    };
  },

  // mounted: function () {
  //   this.userId = localStorage.getItem("userId");
  //   this.targetId = localStorage.getItem("targetId");
  // },

  methods: {
    onCreateTestFormCancel: function () {
      this.dialogFormVisible = false;
      this.form.assigningStudentId = null;
      this.$emit("on-test-mode-form-cancel");
    },
    onCreateTestFormConfirm: function () {
      if (this.form.testName == "") {
        this.$message("测试名称不能为空");
        return;
      }
      if (!this.form.assigningStudentId) {
        this.$message("布置对象未填写");
        return;
      }
      let postData = {
        oldTestId: this.originalTestInfo.id,
        studentId: this.form.assigningStudentId,
        name: this.form.testName,
        type: this.form.testMode,
        origin: this.originalTestInfo.origin,
        description: this.form.testDescription,
        status: "standing",
        nirvanaCount: 0,
        countDown: this.originalTestInfo.countDown,
        enAnswerCountDown: this.originalTestInfo.enAnswerCountDown,
        category: this.originalTestInfo.category,
        listeningCount: this.originalTestInfo.listeningCount,
        createdBy: this.userId,
        published: this.form.publishSwitch,
      };
      this.$store
        .dispatch("StudentTest/createDuplicatedTest", postData)
        .then(() => {
          this.$message({
            type: "success",
            message: "测试已生成",
          });
        })
        .catch((error) => this.$message(error.toString()));
      this.dialogFormVisible = false;
    },

    getBoundStudentList: function () {
      this.$store
        .dispatch("Admin/getRelationship", {
          userId: this.userId,
        })
        .then((response) => {
          this.form.boundStudentList = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    handleSelectStudentChange: function (val) {
      this.form.assigningStudentId = val;
    },

    handleOpen: function () {
      this.userId = localStorage.getItem("userId");
      this.targetId = localStorage.getItem("targetId");
      this.form.testMode = this.originalTestInfo.type;
      this.form.testDescription = this.originalTestInfo.description;
      this.getBoundStudentList();
    },

    handleClose: function () {
      // console.log('aaaa')
      this.form.assigningStudentId = null;
      this.$emit("on-tool-config-form-close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mark-heart {
  color: deepskyblue;
}

.cursor {
  cursor: pointer;
}

.tags-check-group {
}
</style>
