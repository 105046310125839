import axios from "axios";
import url from "../urls";

export default {
  //put
  // add(context, data) {
  //   return new Promise((resolve, reject) => {
  //     let addUrl = url.projectUrl;
  //     axios
  //       .put(addUrl, data)
  //       .then((response) => {
  //         context.commit("mutateAddedProject", {
  //           projectId: 5,
  //           projectName: data.name,
  //         });
  //         resolve(response);
  //       })
  //       .catch((error) => reject(error));
  //   });
  // },
  //get
  // get(context, data) {
  //   return new Promise((resolve, reject) => {
  //     let getUrl = url.ListeningRelationshipManagement;

  //     service
  //       .get(getUrl, {
  //         params: data,
  //       })
  //       .then((response) => {
  //         //console.log("question got");
  //         //context.commit("mutateCompletionQuestions", response.data);
  //         resolve(response);
  //       })
  //       .catch((error) => reject(error));
  //   });
  // },

  //get
  // getAllStudentsByCriteria(context, data) {
  //   return new Promise((resolve, reject) => {
  //     let getUrl = url.ListeningGetAllStudentsByCriteria;

  //     service
  //       .get(getUrl, {
  //         params: data,
  //       })
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => reject(error));
  //   });
  // },

  //get
  getStudentPlansByUserId(context, data) {
    return new Promise((resolve, reject) => {
      let getUrl = url.GetStudentPlansByUserId;

      axios
        .get(getUrl, {
          params: data,
        })
        .then((response) => {
          //console.log("question got");
          //context.commit("mutateCompletionQuestions", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //get
  getStudentPlanDetailTaskById(context, data) {
    return new Promise((resolve, reject) => {
      let getUrl = url.GetStudentPlanDetailTaskById;

      axios
        .get(getUrl, {
          params: data,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  addOrEditStudentPlan(context, data) {
    return new Promise((resolve, reject) => {
      let addUrl = url.AddOrEditStudentPlan;
      axios
        .post(addUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //delete
  deleteStudentPlan(context, data) {
    return new Promise((resolve, reject) => {
      let deleteUrl = url.DeleteStudentPlan;
      axios
        .delete(deleteUrl, { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  addOrEditStudentPlanDetail(context, data) {
    return new Promise((resolve, reject) => {
      let addUrl = url.AddOrEditStudentPlanDetail;
      axios
        .post(addUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  addOrEditStudentPlanDetailTask(context, data) {
    return new Promise((resolve, reject) => {
      let addUrl = url.AddOrEditStudentPlanDetailTask;
      axios
        .post(addUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  addOrEditStudentPlanDaily(context, data) {
    return new Promise((resolve, reject) => {
      let addUrl = url.AddOrEditStudentPlanDaily;
      axios
        .post(addUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  addStudentPlanDailyTask(context, data) {
    return new Promise((resolve, reject) => {
      let addUrl = url.AddStudentPlanDailyTask;
      axios
        .post(addUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post UpdateStudentPlanDetailTaskIsExaminingAsStudent
  updateStudentPlanDetailTaskTestId(context, data) {
    return new Promise((resolve, reject) => {
      let editUrl = url.UpdateStudentPlanDetailTaskTestId;
      axios
        .post(editUrl, null, {params: data})
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post 
  updateStudentPlanDetailTaskIsExaminingAsStudent(context, data) {
    return new Promise((resolve, reject) => {
      let editUrl = url.UpdateStudentPlanDetailTaskIsExaminingAsStudent;
      axios
        .post(editUrl, null, {params: data})
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //get
  getStudentPlanDetailTaskResultsByStudentPlanDetailTaskId(context, data) {
    return new Promise((resolve, reject) => {
      let getUrl = url.GetStudentPlanDetailTaskResultsByStudentPlanDetailTaskId;

      axios
        .get(getUrl, {
          params: data,
        })
        .then((response) => {
          //console.log("question got");
          //context.commit("mutateCompletionQuestions", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  addOrEditStudentPlanDetailTaskResult(context, data) {
    return new Promise((resolve, reject) => {
      let addUrl = url.AddOrEditStudentPlanDetailTaskResult;
      axios
        .post(addUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //delete
  deleteStudentPlanDetailTaskResult(context, data) {
    return new Promise((resolve, reject) => {
      let deleteUrl = url.DeleteStudentPlanDetailTaskResult;
      axios
        .delete(deleteUrl, { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //delete
  deleteStudentPlanDailyTask(context, data) {
    return new Promise((resolve, reject) => {
      let deleteUrl = url.DeleteStudentPlanDailyTask;
      axios
        .delete(deleteUrl, { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //delete
  deleteStudentPlanDaily(context, data) {
    return new Promise((resolve, reject) => {
      let deleteUrl = url.DeleteStudentPlanDaily;
      axios
        .delete(deleteUrl, { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //delete
  deleteStudentPlanDetail(context, data) {
    return new Promise((resolve, reject) => {
      let deleteUrl = url.DeleteStudentPlanDetail;
      axios
        .delete(deleteUrl, { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //delete
  deleteStudentPlanDetailTask(context, data) {
    return new Promise((resolve, reject) => {
      let deleteUrl = url.DeleteStudentPlanDetailTask;
      axios
        .delete(deleteUrl, { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //get
  getStudentPlanDailiesByStudentPlanId(context, params) {
    return new Promise((resolve, reject) => {
      let getUrl = url.GetStudentPlanDailiesByStudentPlanId;
      axios
        .get(getUrl, {
          params: {
            studentPlanId: params.studentPlanId,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //通过拿到的article对象的id，获得该id下所有句子，以便获得句子音频路劲
  // getSentencesByArticleId(context, params) {
  //   return new Promise((resolve, reject) => {
  //     let getUrl = url.ListeningGetSentencesByArticleId;
  //     service
  //       .get(getUrl, {
  //         params: {
  //           articleId: params.articleId
  //         }
  //       })
  //       .then((response) => {
  //         console.log(response);
  //         resolve(response);
  //       })
  //       .catch((error) => reject(error));
  //   });
  // },

  //get
  // getAllTeachersAndAdmins() {
  //   return new Promise((resolve, reject) => {
  //     let getAllTeachersAndAdmins = url.getAllTeachersAndAdmins;

  //     service
  //       .get(getAllTeachersAndAdmins)
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => reject(error));
  //   });
  // },
};
