<template>
  <div>
    <div class="header-content">
      <h2>
        {{
          decodedQueryObject.setRow.name +
          " - " +
          decodedQueryObject.assignmentRow.name
        }}
      </h2>
      <p v-if="setStatus == 'finished'">
        {{ decodedQueryObject.setRow.correctCount + "/" + setQuestions.length }}
      </p>
    </div>
    <div class="question-content">
      <div
        v-for="(question, indexQ) in setQuestions"
        :key="indexQ"
        class="question-item"
      >
        <div class="question-body">
          <p style="text-align: left">
            <span v-html="question.questionBody"> </span>
          </p>
        </div>
        <div class="options-content">
          <div
            v-for="(option, index) in question.vocQuestionOptions"
            :key="index"
            style="margin-top: 10px"
          >
            <el-radio-group
              v-model="question.studentQuestionOptionId"
              @change="
                radioSelected(question.studentQuestionOptionId, question)
              "
              :disabled="setStatus == 'finished'"
            >
              <div>
                <p>
                  <el-radio :label="option.id"
                    ><span v-html="option.optionBody"> </span
                  ></el-radio>
                </p>
              </div>
            </el-radio-group>
          </div>
        </div>

        <div v-if="setStatus == 'finished'" class="result-container">
          <div v-if="question.isCorrect" class="answer-correct">
            <el-icon :size="16" color="deepskyblue" style="align: center"
              ><CircleCheckFilled
            /></el-icon>
          </div>
          <div v-else class="answer-incorrect">
            <span>
              <el-icon :size="16" color="red" style="align: center"
                ><CircleClose
              /></el-icon>
            </span>
            <span style="color: deepskyblue; margin-left: 20px">{{
              question.answerMessage
            }}</span>
          </div>
        </div>
      </div>

      <el-button
        v-if="setStatus != 'finished'"
        class="mt-4"
        style="width: 100%; margin-top: 10px"
        @click="submit()"
        >提交</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "StudentVocPracticeItem",

  data() {
    return {
      setQuestions: [],
      decodedQueryObject: null,
      setStatus: "",
    };
  },

  beforeRouteEnter(to, from) {
    // 在导航完成前获取数据, from参数后面还可以加入next参数
    if (!from.name) {
      return false;
    }
  },

  beforeMount: function () {
    // decode query string into object
    let queryString = window.atob(this.$route.query.encodedString);
    queryString = window.decodeURIComponent(queryString);
    this.decodedQueryObject = JSON.parse(queryString);
    console.log(this.decodedQueryObject);
    console.log(this.$route);
    //this.vocCardItem.vocItem = this.vocItem;
    //this.vocCardItem.currentVocIndex = this.currentVocIndex;
    //this.vocCardItem.vocCount = this.vocCount;
    //this.vocCardItem.currentVocMarkedCount = this.vocItem.MarkedCount;
  },

  mounted: function () {
    this.getVocQuestion();
    this.initializeAssignmentSetStatus();
  },

  methods: {
    getVocQuestion: function () {
      console.log(this.decodedQueryObject.setRow.id);
      this.$store
        .dispatch("VIP/getVocQuestion", {
          setId: this.decodedQueryObject.setRow.id,
          studentId: this.decodedQueryObject.assignmentRow.studentId,
          assignmentId: this.decodedQueryObject.assignmentRow.id,
        })
        .then((response) => {
          this.setQuestions = response.data;
          this.processQuestionBody();
          if (this.setStatus == "finished") {
            this.calculateCorrectCount();
            this.cookCorrectAnswerMessage();
            console.log(this.setQuestions);
          }
        })
        .catch((error) => console.log(error));
    },

    initializeAssignmentSetStatus: function () {
      if (this.decodedQueryObject.setRow.vocAssignmentSetSummary == null) {
        this.setStatus = "pending";
      } else {
        this.setStatus = this.decodedQueryObject.setRow.vocAssignmentSetSummary
          .status
          ? this.decodedQueryObject.setRow.vocAssignmentSetSummary.status
          : "pending";
      }
    },

    processQuestionBody: function () {
      for (let i = 0; i < this.setQuestions.length; i++) {
        //添加一个studentQuestionOptionId属性，存学生选择的答案
        //this.setQuestions[i].studentQuestionOptionId = "";
        if (this.setQuestions[i].questionBody.split(" ").length > 1) {
          this.setQuestions[i].questionBody =
            "The phrase " +
            '"' +
            this.setQuestions[i].questionBody +
            '" ' +
            "in the passage is closest in meaning to";
        } else {
          this.setQuestions[i].questionBody =
            "The word " +
            '"' +
            this.setQuestions[i].questionBody +
            '" ' +
            "in the passage is closest in meaning to";
        }
      }
    },

    radioSelected: function (optionId, question) {
      this.$store
        .dispatch("VIP/PostVocStudentAssignmentAnswer", {
          assignmentId: this.decodedQueryObject.assignmentRow.id,
          questionId: question.id,
          questionOptionId: optionId,
          isNirvana: false,
          studentId: this.decodedQueryObject.assignmentRow.studentId,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => console.log(error));
    },

    submit: function () {
      //如果有没做答的题目，就在答案一行里添加null，以便nirvana的时候能去除题目
      this.addNullToUnansweredQuestion();
      //do something here, to submit all answers and summary
      //不用再check all is answered了，允许有词汇空着（不会就空着，防止乱选选对)
      //if (this.checkAllIsAnswered()) {
      this.$store
        .dispatch("VIP/postVocAssignmentSetSummary", {
          vocAssignmentSetId:
            this.decodedQueryObject.setRow.vocAssignmentVocSetId,
          correctCount: this.calculateCorrectCount(),
          totalCount: this.setQuestions.length,
          status: "finished",
        })
        .then(() => {
          this.cookCorrectAnswerMessage();
          //提交成功后，改变当前set的summary状态
          this.setStatus = "finished";
          //标记选错的词汇题
          this.markIncorrectVoc();
        })
        .catch((error) => console.log(error));
      // } else {
      //   this.$message("请检查所有答案均已做答");
      // }
    },

    // checkAllIsAnswered: function () {
    //   for (let i = 0; i < this.setQuestions.length; i++) {
    //     if (!this.setQuestions[i].studentQuestionOptionId) return false;
    //   }
    //   return true;
    // },

    addNullToUnansweredQuestion: function () {
      for (let i = 0; i < this.setQuestions.length; i++) {
        if (!this.setQuestions[i].studentQuestionOptionId) {
          this.$store
            .dispatch("VIP/PostVocStudentAssignmentAnswer", {
              assignmentId: this.decodedQueryObject.assignmentRow.id,
              questionId: this.setQuestions[i].id,
              studentId: this.decodedQueryObject.assignmentRow.studentId,
            })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => console.log(error));
        }
      }
    },

    markIncorrectVoc: function () {
      for (let i = 0; i < this.setQuestions.length; i++) {
        if (!this.isAnswerCorrect(i)) {
          //标记答错的词 - ! 这里的addMark会把mark count重置成1
          this.addMark(
            this.decodedQueryObject.assignmentRow.studentId,
            this.setQuestions[i].vocabularyId
          );
        }
      }
    },

    addMark: function (studentId, vocabularyId) {
      this.$store
        .dispatch("Vocabulary/markVocabulary", {
          userId: studentId,
          vocabularyId: vocabularyId,
          marked: true,
          updatedBy: -1,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => console.log(error.toString()));
    },

    calculateCorrectCount: function () {
      let correctCount = 0;
      for (let i = 0; i < this.setQuestions.length; i++) {
        if (this.isAnswerCorrect(i)) {
          this.setQuestions[i].isCorrect = true;
          correctCount += 1;
        } else {
          this.setQuestions[i].isCorrect = false;
        }
      }
      this.decodedQueryObject.setRow.correctCount = correctCount;
      return correctCount;
    },

    isAnswerCorrect: function (index) {
      if (
        this.setQuestions[index].studentQuestionOptionId &&
        this.setQuestions[index].vocQuestionOptions.find(
          (selectedOption) =>
            selectedOption.id ==
            this.setQuestions[index].studentQuestionOptionId
        ).isAnswer
      ) {
        return true;
      } else {
        return false;
      }
    },

    cookCorrectAnswerMessage: function () {
      for (let i = 0; i < this.setQuestions.length; i++) {
        let answerIndex = this.setQuestions[i].vocQuestionOptions.findIndex(
          (selectedOption) => selectedOption.isAnswer
        );
        this.setQuestions[i].answerMessage =
          "正确答案： " +
          this.parseIndexToAnswer(answerIndex) +
          " " +
          this.setQuestions[i].vocQuestionOptions[answerIndex].optionBody;
      }
    },

    parseIndexToAnswer: function (index) {
      switch (index) {
        case 0:
          return "A";
        case 1:
          return "B";
        case 2:
          return "C";
        case 3:
          return "D";
        default:
          return "";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header-content {
  position: fixed;
  z-index: 100;
  top: 0px;
  width: 100%;
  text-align: center;
  background-color: #2a7049;
  color: white;
}

.question-content {
  margin-top: 100px;
  margin-left: 30%;
  margin-right: 30%;
  text-align: center;
  line-height: 18px;
  font-family: “Helvetica Neue”, Helvetica, Arial, sans-serif;
}

span {
  font-family: “Helvetica Neue”, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 550;
  color: black;
}

.question-body,
.options-content {
  margin-left: 20px;
  overflow-y: auto;
}
.options-content {
  text-align: left;
}

.question-item {
  border: 1px solid rgb(233, 233, 233);
  border-radius: 10px;
  margin-bottom: 10px;
}

.result-container {
  border-top: 1px solid rgb(233, 233, 233);
}

.answer-correct,
.answer-incorrect {
  margin: 5px 0;
}
</style>
