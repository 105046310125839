<template>
  <div>
    <el-dialog
      width="50%"
      v-model="dialogFormVisible"
      title="创建日常背诵任务"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-form-item label="自动任务">
        <el-select
          v-model="taskGenerator.autoTaskName"
          placeholder="请选择"
          @change="handleAutoTaskSelect"
        >
          <el-option
            v-for="item in taskGenerator"
            :key="item.id"
            :label="item.autoTaskName"
            :value="item.id"
          >
            <span>{{ item.autoTaskName }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="名称">
        <el-input
          v-model="selectedAutoTask.autoTaskName"
          placeholder="请输入名称"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="类型">
        <el-radio-group v-model="form.studyType">
          <el-radio :label="1">盲筛</el-radio>
          <el-radio :label="2">背诵</el-radio>
          <el-radio :label="3">星标</el-radio>
          <el-radio :label="4">复习</el-radio>
          <el-radio :label="5">测试</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item label="背诵日期">
        <el-date-picker
          v-model="form.proceedDate"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item> -->

      <el-form-item label="项目">
        <el-input v-model="selectedAutoTask.project" placeholder="请输入名称">
        </el-input>
      </el-form-item>

      <el-form-item v-if="projects && form.project" label="科目">
        <el-input v-model="selectedAutoTask.subject" placeholder="请输入名称">
        </el-input>
      </el-form-item>

      <!-- <el-form-item label="集合">
        <el-input
          v-model="form.setName"
          placeholder="选择科目后选择集合"
          disabled
        >
        </el-input>
      </el-form-item> -->

      <el-form-item label="步骤">
        <div class="tags-check-group">
          <el-checkbox-group
            v-model="form.checkedSetProcesses"
            @change="handleCheckedProcessChange"
          >
            <el-checkbox
              v-for="(process, index) in form.setProcessOptions"
              :key="index"
              :label="process.id"
              >{{ process.name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </el-form-item>

      <!-- <el-form-item label="范围" v-if="form.showRange">
        <el-row>
          <el-col :span="11">
            <el-input-number v-model="form.from" size="small" />
          </el-col>
          <el-col :span="2"> - </el-col>
          <el-col :span="11">
            <el-input-number v-model="form.to" size="small" />
          </el-col>
        </el-row>
      </el-form-item> -->

      <el-form-item label="模式">
        <el-radio-group v-model="form.answer">
          <el-radio :label="1">英翻中</el-radio>
          <el-radio :label="4">英翻中(仅音频)</el-radio>
          <el-radio :label="2">中翻英</el-radio>
          <el-radio :label="3">显示中英文</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="通过标准">
        <el-input-number v-model="form.criterion" size="small" />
      </el-form-item>

      <!-- <div style="text-align: left">
        <span>评价</span>
      </div>
      <quill-editor v-model:value="form.evaluation" /> -->

      <!-- <el-form-item label="切分任务">
        <el-switch
          v-model="splitTask.splitted"
          active-color="#13ce66"
          inactive-color="gray"
        />
      </el-form-item>

      <el-form-item v-if="splitTask.splitted" label="按集合数量切分">
        <el-input-number
          v-model="splitTask.span"
          size="small"
          @change="handleSplitTaskNumberChange"
        />
      </el-form-item>

      <el-form-item v-if="splitTask.splitted" label="按任务数量切分">
        <el-input-number v-model="splitTask.rangeNumber" size="small" />
      </el-form-item>

      <el-form-item v-if="splitTask.rangeNumber > 0" label="词汇数量">
        <el-input-number
          v-model="splitTask.rangeSpan"
          size="small"
          @change="handleSplitTaskRangeNumberChange"
        />
      </el-form-item> -->

      <el-form-item label="已切分任务">
        <div v-for="task in selectedAutoTask.taskList" :key="task">
          <el-input
            v-model="task.setIds"
            placeholder="选择科目后选择集合"
            disabled
          >
          </el-input>
          <span>{{ task.setName }}</span>
        </div>
      </el-form-item>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onAutoCreatePlanFormDailyTaskCancel()"
            >Cancel</el-button
          >
          <el-button
            type="primary"
            @click="onAutoCreatePlanFormDailyTaskConfirm()"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 点击其他subject之后出现sets列表 -->
    <!-- <el-dialog v-model="dialogSetListVisible">
      <el-row>
        <el-col :span="12">
          <el-input
            v-model="setNameSearchString"
            placeholder="此处输入集合名称来过滤集合"
          ></el-input>
        </el-col>
        <el-col :offset="5" :span="2">
          <el-input v-model.number="form.setRangeFrom"></el-input>
        </el-col>
        <el-col :span="1"> </el-col>
        <el-col :span="2">
          <el-input v-model.number="form.setRangeTo"></el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="handleSetRange">Range</el-button>
        </el-col>
      </el-row>
      <el-checkbox
        v-model="checkAllSets"
        :indeterminate="isIndeterminate"
        @change="handleCheckAllChange"
        >Check all</el-checkbox
      >
      <div class="set-check-box-group">
        <el-checkbox-group
          v-model="checkedSets"
          @change="handleCheckedSetsChange"
        >
          <el-checkbox
            v-for="set in setList.filter(
              (data) =>
                !setNameSearchString ||
                data.name
                  .toLowerCase()
                  .includes(setNameSearchString.toLowerCase())
            )"
            :key="set.name"
            :label="set.id"
            >{{ set.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogSetListVisible = false">Cancel</el-button>
          <el-button type="primary" @click="handleSetListDialogConfirm"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog> -->
    <!-- 点击其他subject之后出现sets列表 -->
  </div>
</template>

<script>
import { SETPROCESSOPTIONS } from "../../../setting/setProcess.js";
import { TASKGENERATOR } from "../../../setting/autoTasks.js";
export default {
  name: "CreatePlanDetailTaskForm",
  components: {},

  props: ["modelValue", "planDetail", "editingPlanDetailTask"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  mounted: function () {
    //初始化一些数据
    this.userId = localStorage.getItem("userId");
    this.targetId = localStorage.getItem("targetId");
    //先拿到taskGenerator的数据
    this.taskGenerator = TASKGENERATOR;
    //默认选择第一个Evian的自动任务
    this.selectedAutoTask = this.taskGenerator.find((item) => item.id === 1);
  },

  data() {
    return {
      userId: null,
      targetId: null,
      dialogFormVisible: false,
      description: "",
      projects: null, //获得当前的教材，只读
      setList: [],
      dialogSetListVisible: false,
      setNameSearchString: "",
      checkAllSets: false,
      checkedSets: [],
      isIndeterminate: true,

      taskGenerator: null,
      selectedAutoTask: null,

      form: {
        studentPlanDetailId: 0,
        name: "",
        proceedDate: null,
        project: "",
        selectedProject: null,
        subjectObject: "", // 这个实际上是选中的所有subject对象
        subjectName: "", //储存选中的科目名字，传出去备用
        setName: "",
        setIds: [],
        setProcessOptions: [],
        setProcesses: [],
        setRangeFrom: 0,
        setRangeTo: 0,
        checkedSetProcesses: [],
        showRange: false,
        from: 0,
        to: 0,
        showAnswerMode: false,
        answer: 4, //进入单词卡后的显示模式
        studyType: 0,
        reviewTurn: 0,
        examMode: 0,
        criterion: 85,
        state: 0,
        evaluation: "",
        comment: "",
      },

      splitTask: {
        splitted: false,
        span: 0,
        rangeNumber: 0,
        rangeSpan: 0,
        tasks: [],
      },
    };
  },

  methods: {
    onAutoCreatePlanFormDailyTaskCancel: function () {
      this.dialogFormVisible = false;
      this.$emit("on-auto-plan-form-detail-task-cancel");
    },
    onAutoCreatePlanFormDailyTaskConfirm: function () {
      this.createSplittedTasks();
    },

    createSplittedTasks: function () {
      if (!this.selectedAutoTask) return;

      //增加获得response时候的计数
      //let taskPostedCount = 0;

      let _localThis = this;
      // Try alternative sources one by one
      (function postSelectedAutoTask(index) {
        if (index < _localThis.selectedAutoTask.taskList.length) {
          let postData = {};

          //postData = _localThis.form;
          postData.studentPlanDetailId = _localThis.planDetail.id;
          postData.teacherId = _localThis.userId;
          postData.createdBy = _localThis.userId;
          //postData.setIds = this.form.setIds.join(",");
          postData.setProcesses = _localThis.form.checkedSetProcesses.join(",");
          postData.project = _localThis.selectedAutoTask.project;
          postData.subject = _localThis.selectedAutoTask.subject;
          postData.examMode = _localThis.form.answer;
          postData.proceedDate = new Date();
          postData.studyType = _localThis.form.studyType;

          postData.setIds =
            _localThis.selectedAutoTask.taskList[index].setIds.join(",");
          postData.setName =
            _localThis.selectedAutoTask.taskList[index].setName;
          postData.name = _localThis.selectedAutoTask.taskList[index].setName;
          postData.from = _localThis.selectedAutoTask.taskList[index].from || 0;
          postData.to = _localThis.selectedAutoTask.taskList[index].to || 0;
          _localThis.$store
            .dispatch("StudentPlan/addOrEditStudentPlanDetailTask", postData)
            .then((response) => {
              if (response.data > 0) {
                postData.id = response.data;
                //taskPostedCount += 1;
                _localThis.$emit("on-task-response", postData)
                postSelectedAutoTask(index + 1);
              }
            });
        } else {
          // No more alternative sources, reject the promise
          // _localThis.$emit("all-tasks-posted");
          _localThis.dialogFormVisible = false;
        }
      })(0);

      // for (let i = 0; i < this.selectedAutoTask.taskList.length; i++) {
      //   //循环会在所有post的then之前完成，所以传出的setIds和setName一定是循环结束后的状态；页面需要刷新才可能获得正确的数据
      //   postData.setIds = this.selectedAutoTask.taskList[i].setIds.join(",");
      //   postData.setName = this.selectedAutoTask.taskList[i].setName;
      //   postData.name = this.selectedAutoTask.taskList[i].setName;
      //   postData.from = this.selectedAutoTask.taskList[i].from || 0;
      //   postData.to = this.selectedAutoTask.taskList[i].to || 0;
      //   this.$store
      //     .dispatch("StudentPlan/addOrEditStudentPlanDetailTask", postData)
      //     .then((response) => {
      //       if (response.data > 0) {
      //         postData.id = response.data;
      //         taskPostedCount += 1;
      //         if (taskPostedCount == this.selectedAutoTask.taskList.length) {
      //           //说明所有response都拿到了
      //           this.$emit("all-tasks-posted");
      //         }
      //       }
      //     });
      // }
    },

    handleAutoTaskSelect(autoSelectedId) {
      this.selectedAutoTask = this.taskGenerator.find(
        (item) => item.id === autoSelectedId
      );
      this.initializeFormData();
    },

    handleCheckedProcessChange: function () {},

    initializeFormData: function () {
      //更新form中的字段，对应新选择的task
      this.form.checkedSetProcesses = this.selectedAutoTask.setProcesses;
      this.form.studyType = this.selectedAutoTask.studyType;
      this.form.criterion = this.selectedAutoTask.criterion;
      this.form.answer = this.selectedAutoTask.answer;
    },

    handleOpen: function () {
      //初始化预设的set processes，从setting文件夹的js文件里面
      this.form.setProcessOptions = SETPROCESSOPTIONS;
      this.initializeFormData();
    },

    handleClose: function () {
      // console.log('aaaa')
      this.$emit("on-auto-plan-form-detail-task-close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cursor {
  cursor: pointer;
}
</style>
