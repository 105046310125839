import axios from "axios";
import url from "../urls";

export default {
  //get
  getGradesByName(context, data) {
    return new Promise((resolve, reject) => {
      let getUrl = url.GetGradesByName;

      axios
        .get(getUrl, {
          params: data,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //get
  getStudentsInGrade(context, data) {
    return new Promise((resolve, reject) => {
      let getUrl = url.GetStudentsInGrade;

      axios
        .get(getUrl, {
          params: data,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
