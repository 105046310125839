import axios from "axios";
import url from "../urls";

export default {
  //post
  getAudioText(context, data) {
    return new Promise((resolve, reject) => {
      let getUrl = url.GetAudioText;
      axios
        .post(getUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
