import axios from "axios";
import url from "../urls";

export default {
  //put
  add(context, data) {
    return new Promise((resolve, reject) => {
      let sUrl = url.setUrl;
      axios
        .put(sUrl, data)
        .then((response) => {
          context.commit("mutateAddedSet", {
            userId: data.userId,
            setId: response.data,
            setName: data.name,
          });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  addSetToSubject(context, data) {
    return new Promise((resolve, reject) => {
      let sUrl = url.setToSubjectUrl;
      axios
        .post(sUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //get
  get(context, data) {
    return new Promise((resolve, reject) => {
      let sUrl = url.setUrl;

      axios
        .get(sUrl, {
          params: {
            assignmentId: data.assignmentId,
          },
        })
        .then((response) => {
          console.log("question got");
          context.commit("mutateCompletionQuestions", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  getUserUnorganizedSets(context, data) {
    return new Promise((resolve, reject) => {
      let sUrl = url.getUserUnorganizedSetsUrl;

      axios
        .get(sUrl, {
          params: {
            userId: data.userId,
          },
        })
        .then((response) => {
          //context.commit("mutateCompletionQuestions", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  edit(context, data) {
    return new Promise((resolve, reject) => {
      let sUrl = url.setUrl;
      axios
        .post(sUrl, data)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //delete
  delete(context, data) {
    return new Promise((resolve, reject) => {
      let sUrl = url.setUrl;
      axios
        .delete(sUrl, {
          params: data,
        })
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  getSetsBySubjectId(context, data) {
    return new Promise((resolve, reject) => {
      let sUrl = url.getSetsBySubjectIdUrl;

      axios
        .get(sUrl, {
          params: {
            userId: data.targetId,
            subjectId: data.subjectId,
          },
        })
        .then((response) => {
          //context.commit("mutateCompletionQuestions", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  getSetMarkCountList(context, params) {
    return new Promise((resolve, reject) => {
      let sUrl = url.GetSetMarkCountList;
      axios
        .get(sUrl, {
          params,
          paramsSerializer: Array.isArray(params.setIds)
            ? (params) => {
                return params.setIds.map((item) => `userId=${params.userId}&` + `setIds=${item}`).join("&");
              }
            : null,
        })
        .then((response) => {
          //context.commit("mutateCompletionQuestions", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

};
