import axios from "axios";
import url from "../urls";

export default {
  //put
  add(context, data) {
    return new Promise((resolve, reject) => {
      let addUrl = url.overviewUrl;
      axios
        .put(addUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //get
  getStudentOverviews(context, data) {
    return new Promise((resolve, reject) => {
      let getVocStudentOverviewsUrl = url.GetVocStudentOverviews;

      axios
        .get(getVocStudentOverviewsUrl, {
          params: data,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //get
  getStudentPagedOverviews(context, data) {
    return new Promise((resolve, reject) => {
      let getVocStudentPagedOverviewsUrl = url.GetVocStudentPagedOverviews;

      axios
        .get(getVocStudentPagedOverviewsUrl, {
          params: data,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  edit(context, data) {
    return new Promise((resolve, reject) => {
      let editUrl = url.overviewUrl;
      axios
        .post(editUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  addVocStudentOverview(context, data) {
    return new Promise((resolve, reject) => {
      let addVocStudentOverviewUrl = url.AddVocStudentOverview;
      axios
        .post(addVocStudentOverviewUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post
  UpsertVocStudentOverviewDetail(context, data) {
    return new Promise((resolve, reject) => {
      let upsertVocStudentOverviewDetailUrl = url.UpsertVocStudentOverviewDetail;
      axios
        .post(upsertVocStudentOverviewDetailUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //delete
  delete(context, data) {
    return new Promise((resolve, reject) => {
      let deleteUrl = url.overviewUrl;
      axios
        .delete(deleteUrl, { data: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
