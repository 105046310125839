import axios from "axios";
import url from "../urls";

export default {
  //put
  add(context, data) {
    return new Promise((resolve, reject) => {
      let addUrl = url.StudentViewingHistoryUrl;
      axios
        .put(addUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //get
  // get(context, data) {
  //   return new Promise((resolve, reject) => {
  //     let getUrl = url.projectUrl;

  //     axios
  //       .get(getUrl, {
  //         params: data,
  //       })
  //       .then((response) => {
  //         //console.log("question got");
  //         //context.commit("mutateCompletionQuestions", response.data);
  //         resolve(response);
  //       })
  //       .catch((error) => reject(error));
  //   });
  // },
  // //post
  // edit(context, data) {
  //   return new Promise((resolve, reject) => {
  //     let editUrl = url.projectUrl;
  //     axios
  //       .post(editUrl, data)
  //       .then((response) => {
  //         console.log(response);
  //         resolve(response);
  //       })
  //       .catch((error) => reject(error));
  //   });
  // },
  // //delete
  // delete(context, data) {
  //   return new Promise((resolve, reject) => {
  //     let deleteUrl = url.projectUrl;
  //     axios
  //       .delete(deleteUrl, { params: data })
  //       .then((response) => {
  //         console.log(response);
  //         resolve(response);
  //       })
  //       .catch((error) => reject(error));
  //   });
  // },
};
