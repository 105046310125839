<template>
  <shared-header
    ref="sharedHeader"
    @on-bound-student="handleOnBoundStudent()"
  />

  <el-drawer
    title="bind student remotely"
    v-model="bindStudentListDrawer"
    direction="ltr"
    size="20%"
  >
    <div
      class="new-bind-student-container"
      v-if="userRole == 'Admin' || userRole == 'Teacher'"
    >
      <new-bind-student
        @on-remote-select-student-click="handleOnBoundStudentClick"
        @on-bound-student-click="handleOnBoundStudentClick"
      />
    </div>
  </el-drawer>

  <el-row :gutter="20" class="main-content">
    <el-col :span="22" :offset="1">
      <el-button
        class="mt-4"
        style="width: 100%"
        @click="createPlan()"
        v-if="userRole == 'Admin' || userRole == 'Teacher'"
        >Create Plan</el-button
      >

      <div v-for="item in plan" :key="item" class="plan_card">
        <el-card class="box-card" shadow="hover">
          <template #header>
            <div class="card-header">
              <el-row>
                <el-col :span="2">
                  <h2>{{ item.name }}</h2>
                </el-col>
                <el-col :span="20">
                  <span v-html="item.description"></span
                ></el-col>
                <el-col :span="2"
                  ><div
                    class="planManipulation"
                    v-if="userRole == 'Admin' || userRole == 'Teacher'"
                  >
                    <!-- <span class="cursor" @click="handleAddPlanDaily(item.id)"
                      ><el-icon> <reading /> </el-icon
                    ></span> -->
                    <span
                      class="cursor"
                      :class="showAllTaskAndResults ? 'planShowTime' : ''"
                      @click="handleShowAllTaskAndResults(item)"
                      ><el-icon><View /></el-icon
                    ></span>
                    <span class="cursor" @click="handleEditPlan(item)"
                      ><el-icon> <edit /> </el-icon
                    ></span>
                    <span
                      class="cursor"
                      @click="handleAddPlanDetailAndTasks(item.id)"
                      ><el-icon> <plus /> </el-icon
                    ></span>
                    <span class="cursor" @click="handleDeletePlan(item.id)"
                      ><el-icon> <delete /> </el-icon
                    ></span></div
                ></el-col>
              </el-row>
            </div>
          </template>
          <div v-for="detail in item.studentPlanDetails" :key="detail">
            <el-row>
              <el-col :span="2" :offset="1">
                <h3
                  class="cursor"
                  v-html="detail.name"
                  @click="handleDetailNameClick(detail)"
                ></h3>
              </el-col>
              <el-col :span="17">
                <span v-html="detail.description"></span
              ></el-col>
              <el-col :span="4"
                ><div
                  class="planManipulation"
                  v-if="userRole == 'Admin' || userRole == 'Teacher'"
                >
                  <span
                    class="cursor"
                    @click="handleConvertAllTaskToTest(detail)"
                    >转成测试</span
                  >
                  <span class="cursor" @click="handleAddAutoTask(detail)"
                    >自动任务</span
                  >
                  <span class="cursor" @click="handleEditPlanDetail(detail)"
                    >编辑</span
                  >
                  <span class="cursor" @click="handleAddPlanDetailTask(detail)"
                    >添加</span
                  >
                  <span
                    class="cursor"
                    @click="
                      handleDeletePlanDetail({
                        detailId: detail.id,
                        planId: item.id,
                      })
                    "
                    >删除</span
                  >
                </div></el-col
              >
            </el-row>
            <el-divider border-style="double" />

            <div v-if="detail.showTask">
              <div v-for="task in detail.studentPlanDetailTasks" :key="task">
                <el-row :class="task.status">
                  <!-- <el-col
                    :span="2"
                    :offset="2"
                    @click="task.showTaskResult = !task.showTaskResult"
                    class="cursor"
                  >
                    <span v-html="task.name"></span>
                  </el-col> -->

                  <el-col
                    :offset="2"
                    :span="2"
                    @click="task.showTaskResult = !task.showTaskResult"
                  >
                    <span v-html="task.project"></span>
                  </el-col>

                  <el-col
                    :span="2"
                    @click="task.showTaskResult = !task.showTaskResult"
                  >
                    <span v-html="task.subject"></span>
                  </el-col>

                  <el-col :span="10" :offset="2">
                    <el-popover
                      effect="light"
                      trigger="hover"
                      placement="top"
                      width="auto"
                    >
                      <template #default>
                        <div>{{ task.setName }}</div>
                      </template>
                      <template #reference>
                        <span v-html="reduceTaskSetName(task.setName)"></span>
                      </template>
                    </el-popover>
                  </el-col>

                  <el-col :span="4" style="text-align: left">
                    <span>{{ setProcessesFormatter(task) }}</span>
                  </el-col>

                  <el-col :span="2"
                    ><div
                      class="planManipulation"
                      v-if="userRole == 'Admin' || userRole == 'Teacher'"
                    >
                      <span
                        class="cursor"
                        @click="handleConvertTaskToTest(task)"
                        v-if="!task.vocStudentTestId"
                        ><el-icon><MessageBox /></el-icon
                      ></span>
                      <span
                        class="cursor"
                        @click="
                          handleEditPlanDetailTask({
                            task: task,
                            detail: detail,
                          })
                        "
                        ><el-icon> <edit /> </el-icon
                      ></span>
                      <span
                        class="cursor"
                        @click="handleAddPlanDetailTaskResult(task)"
                        ><el-icon> <plus /> </el-icon
                      ></span>
                      <span
                        class="cursor"
                        @click="
                          handleDeletePlanDetailTask({
                            task: task,
                            detail: detail,
                          })
                        "
                        ><el-icon> <delete /> </el-icon
                      ></span></div
                  ></el-col>
                </el-row>

                <div
                  v-for="result in task.studentPlanDetailTaskResults"
                  :key="result"
                  v-show="task.showTaskResult"
                >
                  <el-row class="result-row">
                    <el-col :span="2" :offset="3">
                      <span>{{ dateTimeFormatter(result.updatedDate) }}</span>
                    </el-col>

                    <el-col :span="2">
                      <span>{{ result.timeConsumed.toFixed(2) + "分钟" }}</span>
                    </el-col>

                    <el-col :span="2">
                      <span>{{
                        Math.floor(
                          (result.timeConsumed * 60) / result.checkedCount
                        ) + "秒/词"
                      }}</span>
                    </el-col>

                    <el-col :span="7">
                      <el-popover
                        effect="light"
                        trigger="hover"
                        placement="top"
                        width="auto"
                      >
                        <template #default>
                          <div>{{ result.generatedResult }}</div>
                        </template>
                        <template #reference>
                          <span @click="handleResultClick(result)">{{
                            generatedResultFormatter(result.generatedResult)
                          }}</span>
                        </template>
                      </el-popover>
                      <!-- <span>{{
                    generatedResultFormatter(result.generatedResult)
                  }}</span> -->
                    </el-col>

                    <el-col :span="2" :offset="2">
                      <span v-if="result.passed" style="color: deepskyblue"
                        >Passed</span
                      >
                      <span v-else style="color: red">Failed</span>
                    </el-col>

                    <el-col :span="2"
                      ><div
                        class="planManipulation"
                        v-if="userRole == 'Admin' || userRole == 'Teacher'"
                      >
                        <!-- <span class="cursor"
                      ><el-icon> <edit /> </el-icon
                    ></span>
                    <span class="cursor"
                      ><el-icon> <plus /> </el-icon
                    ></span> -->
                        <span class="cursor"
                          ><el-icon
                            @click="
                              handleDeletePlanDetailTaskResult({
                                task: task.studentPlanDetailTask,
                                result: result,
                              })
                            "
                          >
                            <delete /> </el-icon
                        ></span></div
                    ></el-col>
                  </el-row>
                  <!-- <span @click="handleTaskContentClick(task)">{{ task.name }}</span> -->
                </div>
                <el-divider border-style="dashed" />
              </div>
            </div>
          </div>
          <el-button
            v-if="userRole == 'Admin' || userRole == 'Teacher'"
            class="mt-4"
            style="width: 100%"
            @click="handleAddPlanDaily(item.id)"
            >Add Plan Daily</el-button
          >
        </el-card>
      </div>

      <el-divider class="cursor" @click="handleShowAllDailyDetailsClick"
        ><el-icon style="color: purple"><star-filled /></el-icon
        ><el-icon style="color: purple"><star-filled /></el-icon
        ><el-icon style="color: purple"><star-filled /></el-icon
        >显示全部每日词汇<el-icon style="color: purple"
          ><star-filled /><el-icon style="color: purple"
            ><star-filled /></el-icon></el-icon
        ><el-icon style="color: purple"><star-filled /></el-icon
        ><el-icon style="color: purple"><star-filled /></el-icon
      ></el-divider>

      <div
        v-for="(item, dailyIndex) in allDaily"
        :key="item"
        class="daily_card"
      >
        <el-card
          class="daily-card"
          shadow="hover"
          @mouseover="currentDailyIndex = dailyIndex"
          @mouseleave="currentDailyIndex = -1"
        >
          <template #header>
            <div class="card-header">
              <el-row>
                <el-col :span="2">
                  <span style="color: #409eff">{{
                    dateFormatter(item.date)
                  }}</span>
                  <span v-if="item.isToday" style="color: #b88230"
                    >(Today)</span
                  >
                </el-col>
                <el-col :span="20">
                  <span
                    v-if="item.isMilestone"
                    v-html="item.milestoneComment"
                  ></span
                ></el-col>
                <el-col :span="2"
                  ><div
                    class="planManipulation"
                    v-if="userRole == 'Admin' || userRole == 'Teacher'"
                  >
                    <span
                      class="cursor"
                      :class="item.showDailyDetail ? 'planShowTime' : ''"
                      @click="handleShowDailyDetail(item)"
                      ><el-icon><View /></el-icon
                    ></span>
                    <span class="cursor" @click="handleEditPlanDaily(item)"
                      ><el-icon> <edit /> </el-icon
                    ></span>
                    <span
                      class="cursor"
                      @click="handleBindPlanDetailTaskAndDaily(item)"
                      ><el-icon> <plus /> </el-icon
                    ></span>
                    <span class="cursor" @click="handlePlanDailyDelete(item)"
                      ><el-icon> <delete /> </el-icon
                    ></span></div
                ></el-col>
              </el-row>
            </div>
          </template>
          <div v-show="item.showDailyDetail || item.isToday">
            <div
              v-for="(task, index) in item.studentPlanDailyTasks"
              :key="task"
            >
              <el-row
                :class="
                  currentTaskIndex == index && currentDailyIndex == dailyIndex
                    ? 'taskBackgroundColor'
                    : ''
                "
                @mouseover="currentTaskIndex = index"
                @mouseleave="currentTaskIndex = -1"
              >
                <!-- <el-col
                :span="2"
                :offset="1"
                @click="handleDailyTaskClick(task.studentPlanDetailTask)"
              >
                <span class="cursor">{{
                  task.studentPlanDetailTask.name
                }}</span>
              </el-col> -->

                <!-- <el-col
                :offset="1"
                :span="2"
                @click="handleDailyTaskClick(task.studentPlanDetailTask)"
                class="cursor"
              >
                <span v-html="task.studentPlanDetailTask.project"></span>
              </el-col> -->

                <el-col
                  :offset="1"
                  :span="2"
                  @click="handleDailyTaskClick(task.studentPlanDetailTask)"
                  class="cursor"
                >
                  <el-popover
                    effect="light"
                    trigger="hover"
                    placement="top"
                    width="auto"
                  >
                    <template #default>
                      <div>{{ task.studentPlanDetailTask.project }}</div>
                    </template>
                    <template #reference>
                      <span v-html="task.studentPlanDetailTask.subject"></span>
                    </template>
                  </el-popover>
                </el-col>

                <el-col :span="4">
                  <el-popover
                    effect="light"
                    trigger="hover"
                    placement="top"
                    width="auto"
                  >
                    <template #default>
                      <div>{{ task.studentPlanDetailTask.setName }}</div>
                    </template>
                    <template #reference>
                      <span
                        class="cursor"
                        :style="
                          task.studentPlanDetailTask.vocStudentTestId
                            ? 'color: #eebe77'
                            : ''
                        "
                        @click="
                          handleTaskContentClick(task.studentPlanDetailTask)
                        "
                        >{{
                          reduceTaskSetName(task.studentPlanDetailTask.setName)
                        }}</span
                      >
                    </template>
                  </el-popover>
                </el-col>

                <el-col :span="4">
                  <span>{{
                    setProcessesFormatter(task.studentPlanDetailTask)
                  }}</span>
                </el-col>

                <el-col :span="2">
                  <span>{{
                    studyTypeFormatter(task.studentPlanDetailTask.studyType)
                  }}</span>
                </el-col>

                <el-col
                  :span="2"
                  v-if="task.studentPlanDetailTask.vocStudentTestId"
                >
                  <span style="color: #eebe77">测试</span>
                </el-col>

                <el-col :span="2">
                  <span>{{
                    examModeFormatter(task.studentPlanDetailTask.examMode)
                  }}</span>
                </el-col>

                <el-col
                  :span="2"
                  :offset="task.studentPlanDetailTask.vocStudentTestId ? 1 : 3"
                >
                  <span>{{ task.studentPlanDetailTask.criterion }}</span>
                </el-col>

                <!-- <el-col
                :span="2"
                v-if="userRole == 'Admin' || userRole == 'Teacher'"
              >
                <el-button
                  @click="handleActivateExamine(task.studentPlanDetailTask)"
                  >{{
                    task.studentPlanDetailTask.isExaminingAsStudent
                      ? "激活"
                      : "未激活"
                  }}</el-button
                >
              </el-col> -->

                <el-col :span="2"
                  ><div
                    class="planManipulation"
                    v-if="userRole == 'Admin' || userRole == 'Teacher'"
                  >
                    <span class="cursor"
                      ><el-icon> <edit /> </el-icon
                    ></span>
                    <span
                      class="cursor"
                      @click="handleBindPlanDetailTaskAndDaily(item)"
                      ><el-icon> <plus /> </el-icon
                    ></span>
                    <span
                      class="cursor"
                      @click="
                        handleDeletePlanDailyTask({
                          daily: item,
                          dailyTaskBound: task,
                        })
                      "
                      ><el-icon> <delete /> </el-icon
                    ></span></div
                ></el-col>
              </el-row>

              <div
                v-for="result in task.studentPlanDetailTask
                  .studentPlanDetailTaskResults"
                :key="result"
              >
                <el-row class="result-row">
                  <el-col :span="4" :offset="1">
                    <span>{{ dateTimeFormatter(result.updatedDate) }}</span>
                  </el-col>

                  <el-col :span="1">
                    <span v-if="result.sync"
                      ><el-icon
                        :size="16"
                        color="deepskyblue"
                        style="align: center; margin-top: 3px"
                        ><CircleCheckFilled /></el-icon
                    ></span>
                  </el-col>

                  <el-col :span="2">
                    <span>{{ result.timeConsumed.toFixed(2) + "分钟" }}</span>
                  </el-col>

                  <el-col :span="2">
                    <span>{{
                      Math.floor(
                        (result.timeConsumed * 60) / result.checkedCount
                      ) + "秒/词"
                    }}</span>
                  </el-col>

                  <el-col :span="7" style="text-align: left">
                    <el-popover
                      effect="light"
                      trigger="hover"
                      placement="top"
                      width="auto"
                    >
                      <template #default>
                        <div>{{ result.generatedResult }}</div>
                      </template>
                      <template #reference>
                        <span @click="handleResultClick(result)">{{
                          generatedResultFormatter(result.generatedResult)
                        }}</span>
                      </template>
                    </el-popover>
                    <!-- <span>{{
                    generatedResultFormatter(result.generatedResult)
                  }}</span> -->
                  </el-col>

                  <el-col :span="2" :offset="2">
                    <span v-if="result.passed" style="color: deepskyblue"
                      >Passed</span
                    >
                    <span v-else style="color: red">Failed</span>
                  </el-col>

                  <el-col :span="2"
                    ><div
                      class="planManipulation"
                      v-if="userRole == 'Admin' || userRole == 'Teacher'"
                    >
                      <!-- <span class="cursor"
                      ><el-icon> <edit /> </el-icon
                    ></span>
                    <span class="cursor"
                      ><el-icon> <plus /> </el-icon
                    ></span> -->
                      <span class="cursor"
                        ><el-icon
                          @click="
                            handleDeletePlanDetailTaskResult({
                              task: task.studentPlanDetailTask,
                              result: result,
                            })
                          "
                        >
                          <delete /> </el-icon
                      ></span></div
                  ></el-col>
                </el-row>
                <!-- <span @click="handleTaskContentClick(task)">{{ task.name }}</span> -->
              </div>
              <el-divider>
                <el-icon style="color: purple"><star-filled /></el-icon>
                <el-icon style="color: purple"><star-filled /></el-icon>
                <el-icon style="color: purple"><star-filled /></el-icon>
                <el-icon style="color: purple"><star-filled /></el-icon>
                <el-icon style="color: purple"><star-filled /></el-icon>
                <el-icon style="color: purple"><star-filled /></el-icon>
              </el-divider>
              <!-- <span @click="handleTaskContentClick(task)">{{ task.name }}</span> -->
            </div>
          </div>
        </el-card>
      </div>
    </el-col>
  </el-row>

  <!-- 创建计划的表单 -->
  <create-plan-form
    v-model="showCreatePlanForm"
    :editingPlan="currentEditingPlan"
    @on-plan-form-cancel="showCreatePlanForm = false"
    @on-plan-form-confirm="handlePlanFormConfirm($event)"
    @on-plan-form-close="showCreatePlanForm = false"
  ></create-plan-form>

  <create-plan-detail-form
    v-model="showCreatePlanDetailForm"
    :studentPlanId="currentPlanId"
    :editingPlanDetail="currentEditingPlanDetail"
    @on-plan-form-detail-cancel="showCreatePlanDetailForm = false"
    @on-plan-form-detail-confirm="handlePlanFormDetailConfirm($event)"
    @on-plan-form-detail-close="showCreatePlanDetailForm = false"
  ></create-plan-detail-form>

  <create-plan-daily-form
    v-model="showCreatePlanDailyForm"
    :studentPlanId="currentPlanId"
    :allDaily="allDaily"
    :edittingPlanDaily="currentEdittingPlanDaily"
    @on-plan-form-daily-cancel="showCreatePlanDailyForm = false"
    @on-plan-form-daily-confirm="handlePlanFormDailyConfirm($event)"
    @on-plan-form-daily-close="showCreatePlanDailyForm = false"
    @all-dailies-posted="handleAllDailiesPosted"
  ></create-plan-daily-form>

  <create-plan-detail-task-form
    v-model="showCreatePlanDetailTaskForm"
    :planDetail="currentPlanDetail"
    :editingPlanDetailTask="currentEditingPlanDetailTask"
    @on-plan-form-detail-task-cancel="showCreatePlanDetailTaskForm = false"
    @on-plan-form-detail-task-confirm="handlePlanFormDetailTaskConfirm($event)"
    @on-plan-form-detail-task-close="showCreatePlanDetailTaskForm = false"
    @all-tasks-posted="handleAllTasksPosted()"
    @on-task-response="handleOnTaskResponse($event)"
  ></create-plan-detail-task-form>

  <!-- 同时创建plan下的detail和tasks的窗口 -->
  <create-plan-detail-and-task-form
    v-model="showCreatePlanDetailAndTaskForm"
    :studentPlanId="currentPlanId"
    @on-plan-form-detail-confirm="handlePlanFormDetailConfirm($event)"
    @on-plan-form-detail-and-task-cancel="
      showCreatePlanDetailAndTaskForm = false
    "
    @on-plan-form-detail-and-task-confirm="
      handlePlanFormDetailAndTaskConfirm($event)
    "
    @on-plan-form-detail-and-task-close="
      showCreatePlanDetailAndTaskForm = false
    "
    @all-tasks-posted="handleAllTasksPosted()"
    @on-task-response="handleOnTaskResponse($event)"
  ></create-plan-detail-and-task-form>

  <bind-plan-daily-task-form
    v-model="showBindPlanDailyTaskForm"
    :plans="plan"
    :daily="currentBindingDaily"
    @on-bind-plan-daily-task-cancel="showBindPlanDailyTaskForm = false"
    @on-bind-plan-daily-task-confirm="handleBindPlanDailyTaskConfirm($event)"
    @on-bind-plan-daily-task-close="showBindPlanDailyTaskForm = false"
    @on-plan-daily-task-bound="handleDailyTaskBound($event)"
  ></bind-plan-daily-task-form>

  <convert-all-detail-task-to-test-form
    v-model="showConvertAllDetailTaskToTestForm"
    :planDetail="currentEditingPlanDetail"
    @on-convert-all-task-to-test-form-cancel="
      showConvertAllDetailTaskToTestForm = false
    "
    @on-convert-all-task-to-test-form-confirm="
      showConvertAllDetailTaskToTestForm = false
    "
    @on-convert-all-task-to-test-form-close="
      showConvertAllDetailTaskToTestForm = false
    "
  ></convert-all-detail-task-to-test-form>

  <create-test-form
    v-model="showCreateTestForm"
    :task="currentTaskToTest"
    @on-test-mode-form-cancel="showCreateTestForm = false"
    @on-test-mode-form-confirm="showCreateTestForm = false"
    @on-tool-config-form-close="showCreateTestForm = false"
    @on-task-test-bound="handleTaskTestBound($event)"
  ></create-test-form>

  <auto-create-plan-detail-task-form
    v-model="showAutoCreatePlanDetailTaskForm"
    :planDetail="currentPlanDetail"
    @on-auto-plan-form-detail-task-cancel="
      showAutoCreatePlanDetailTaskForm = false
    "
    @on-auto-plan-form-detail-task-confirm="
      handleAutoPlanFormDetailTaskConfirm($event)
    "
    @on-auto-plan-form-detail-task-close="
      showAutoCreatePlanDetailTaskForm = false
    "
    @all-tasks-posted="handleAllTasksPosted()"
    @on-task-response="handleOnTaskResponse($event)"
  ></auto-create-plan-detail-task-form>
</template>

<script>
import SharedHeader from "../../shared/SharedHeader.vue";
import NewBindStudent from "../../admin/NewBindStudent.vue";
import CreatePlanForm from "./CreatePlanForm.vue";
import CreatePlanDetailForm from "./CreatePlanDetailForm.vue";
import CreatePlanDailyForm from "./CreatePlanDailyForm.vue";
import CreatePlanDetailTaskForm from "./CreatePlanDetailTaskForm.vue";
import BindPlanDailyTaskForm from "./BindPlanDailyTaskForm.vue";
import { SETPROCESSOPTIONS } from "../../../setting/setProcess.js";
import ConvertAllDetailTaskToTestForm from "./ConvertAllDetailTaskToTestForm.vue";
import CreateTestForm from "./CreateTestForm.vue";
import AutoCreatePlanDetailTaskForm from "./AutoCreatePlanDetailTaskForm.vue";
import CreatePlanDetailAndTaskForm from "./CreatePlanDetailAndTaskForm.vue";
import { ElMessage } from "element-plus";
export default {
  name: "Index",
  components: {
    SharedHeader,
    NewBindStudent,
    CreatePlanForm,
    CreatePlanDetailForm,
    CreatePlanDailyForm,
    CreatePlanDetailTaskForm,
    BindPlanDailyTaskForm,
    ConvertAllDetailTaskToTestForm,
    CreateTestForm,
    AutoCreatePlanDetailTaskForm,
    CreatePlanDetailAndTaskForm,
  },

  watch: {
    showCreatePlanForm: {
      handler(newValue) {
        //如果newValue是false，说明dialog关闭，就清空edit的内容
        if (!newValue) {
          this.currentEditingPlan = null;
        }
      },
    },

    showCreatePlanDetailForm: {
      handler(newValue) {
        //如果newValue是false，说明dialog关闭，就清空edit的内容
        if (!newValue) {
          this.currentEditingPlanDetail = null;
        }
      },
    },
  },

  data() {
    return {
      userRole: null,
      showCreatePlanForm: false,
      showCreatePlanDetailForm: false,
      showCreatePlanDailyForm: false,
      showCreatePlanDetailTaskForm: false,
      showCreatePlanDetailAndTaskForm: false,
      showAutoCreatePlanDetailTaskForm: false,
      showBindPlanDailyTaskForm: false,
      showConvertAllDetailTaskToTestForm: false,
      showCreateTestForm: false,
      showAllTaskAndResults: false,
      currentPlanId: null,
      currentPlanDetail: null,
      plan: [],
      currentEditingPlan: null,
      currentEditingPlanDetail: null,
      currentEditingPlanDetailTask: null,
      currentEdittingPlanDaily: null,
      currentBindingDaily: null,
      currentTaskToTest: null,
      allDaily: [],
      content: "hello",
      bindStudentListDrawer: false,
      currentTaskIndex: -1,
      currentDailyIndex: -1,
    };
  },
  computed: {},
  mounted: function () {
    this.userRole = localStorage.getItem("userRole");
    this.getStudentPlans();
  },
  methods: {
    getStudentPlans: function () {
      let currentTargetUserId = localStorage.getItem("targetId");
      this.$store
        .dispatch("StudentPlan/getStudentPlansByUserId", {
          userId: currentTargetUserId,
        })
        .then((response) => {
          this.plan = response.data;
          //拿到所有plan之后，获取所有plan下面的dailies
          console.log(this.plan);
          this.getAllDailyByPlanIds();
        })
        .catch((error) => console.log(error.toString()));
    },

    getAllDailyByPlanIds: function () {
      this.allDaily = [];
      for (let i = 0; i < this.plan.length; i++) {
        this.$store
          .dispatch("StudentPlan/getStudentPlanDailiesByStudentPlanId", {
            studentPlanId: this.plan[i].id,
          })
          .then((response) => {
            for (let i = 0; i < response.data.length; i++) {
              //给每个daily加一个是否是today的标记
              response.data[i].isToday = this.isToday(response.data[i].date);
              this.allDaily.push(response.data[i]);
            }
            //push完allDaily之后需要以日期排序
            this.allDaily.sort((a, b) => new Date(b.date) - new Date(a.date));
            //拿到全部daily和任务之后，设置任务结果的日期状态，加入可能的sync（也就是结果日期等于当天日期
            this.setDailyTaskResultStatus();
          });
      }
    },

    setDailyTaskResultStatus: function () {
      if (!this.allDaily) return;
      for (let i = 0; i < this.allDaily.length; i++) {
        if (
          this.allDaily[i].studentPlanDailyTasks &&
          this.allDaily[i].studentPlanDailyTasks.length > 0
        ) {
          for (
            let j = 0;
            j < this.allDaily[i].studentPlanDailyTasks.length;
            j++
          ) {
            if (
              this.allDaily[i].studentPlanDailyTasks[j] &&
              this.allDaily[i].studentPlanDailyTasks[j].studentPlanDetailTask &&
              this.allDaily[i].studentPlanDailyTasks[j].studentPlanDetailTask
                .studentPlanDetailTaskResults &&
              this.allDaily[i].studentPlanDailyTasks[j].studentPlanDetailTask
                .studentPlanDetailTaskResults.length > 0
            )
              for (
                let k = 0;
                k <
                this.allDaily[i].studentPlanDailyTasks[j].studentPlanDetailTask
                  .studentPlanDetailTaskResults.length;
                k++
              ) {
                let currentDate = new Date(this.allDaily[i].date);
                let resultDate = new Date(
                  this.allDaily[i].studentPlanDailyTasks[
                    j
                  ].studentPlanDetailTask.studentPlanDetailTaskResults[
                    k
                  ].updatedDate
                );

                if (
                  resultDate.getFullYear() === currentDate.getFullYear() &&
                  resultDate.getMonth() === currentDate.getMonth() &&
                  resultDate.getDate() === currentDate.getDate()
                ) {
                  this.allDaily[i].studentPlanDailyTasks[
                    j
                  ].studentPlanDetailTask.studentPlanDetailTaskResults[
                    k
                  ].sync = true;
                } else {
                  this.allDaily[i].studentPlanDailyTasks[
                    j
                  ].studentPlanDetailTask.studentPlanDetailTaskResults[
                    k
                  ].sync = false;
                }
              }
          }
        }
      }
    },

    setPlanDetailTaskStatus: function (detail) {
      //根据传进来的detail，赋值每个任务的当前状态
      for (let i = 0; i < detail.studentPlanDetailTasks.length; i++) {
        if (
          detail.studentPlanDetailTasks[i].studentPlanDetailTaskResults
            .length <= 0
        ) {
          detail.studentPlanDetailTasks[i].status = "unstarted";
        } else {
          //获得results的最后一个结果，如果是passed就是passed，否则failed
          detail.studentPlanDetailTasks[i].studentPlanDetailTaskResults[
            detail.studentPlanDetailTasks[i].studentPlanDetailTaskResults
              .length - 1
          ].passed
            ? (detail.studentPlanDetailTasks[i].status = "passed")
            : (detail.studentPlanDetailTasks[i].status = "failed");
        }
      }
    },

    handleDeletePlan: function (planId) {
      this.$store
        .dispatch("StudentPlan/deleteStudentPlan", {
          id: planId,
        })
        .then((response) => {
          //删除成功返回true
          if (response.data) {
            this.plan = this.plan.filter((item) => item.id != planId);
          }
        })
        .catch((error) => console.log(error.toString()));
    },
    handleDeletePlanDetail: function (data) {
      this.$store
        .dispatch("StudentPlan/deleteStudentPlanDetail", {
          id: data.detailId,
        })
        .then((response) => {
          //删除成功返回true
          if (response.data) {
            //找到当前detail所属的plan
            let currentPlan = this.plan.filter(
              (item) => item.id == data.planId
            )[0];
            //移除detail
            currentPlan.studentPlanDetails =
              currentPlan.studentPlanDetails.filter(
                (item) => item.id != data.detailId
              );
          }
        })
        .catch((error) => console.log(error.toString()));
    },
    handleDeletePlanDetailTask: function (data) {
      this.$store
        .dispatch("StudentPlan/deleteStudentPlanDetailTask", {
          id: data.task.id,
        })
        .then((response) => {
          //删除成功返回true
          if (response.data) {
            data.detail.studentPlanDetailTasks =
              data.detail.studentPlanDetailTasks.filter(
                (item) => item.id != data.task.id
              );
          }
        })
        .catch((error) => console.log(error.toString()));
    },

    handlePlanDailyDelete: function (item) {
      this.$store
        .dispatch("StudentPlan/deleteStudentPlanDaily", {
          id: item.id,
        })
        .then((response) => {
          //删除成功返回true
          if (response.data) {
            this.allDaily = this.allDaily.filter(
              (daily) => daily.id != item.id
            );
          }
        })
        .catch((error) => console.log(error.toString()));
    },

    handleBindPlanDetailTaskAndDaily: function (daily) {
      this.currentBindingDaily = daily;
      this.showBindPlanDailyTaskForm = true;
    },

    handleBindPlanDailyTaskConfirm: function () {
      this.showBindPlanDailyTaskForm = false;
    },

    handleDailyTaskBound: function (data) {
      //找到需要绑定任务的daily
      let currentDaily = this.allDaily.find((item) => item.id == data.daily.id);
      //使用事件传出的data，拼接dailyTask对象，push到需要绑定的daily里面
      let dailyTaskObject = {};
      dailyTaskObject.id = data.dailyTaskId;
      dailyTaskObject.studentPlanDailyId = data.daily.id;
      dailyTaskObject.studentPlanDetailTaskId = data.task.id;
      dailyTaskObject.studentPlanDetailTask = data.task;
      currentDaily.studentPlanDailyTasks.push(dailyTaskObject);
    },

    handleTaskTestBound: function (data) {
      this.currentTaskToTest.vocStudentTestId = data.testId;
      this.currentTaskToTest.testType = data.testType;
      //绑定完testId后清空
      this.currentTaskToTest = null;
    },

    handleDeletePlanDailyTask: function (data) {
      this.$store
        .dispatch("StudentPlan/deleteStudentPlanDailyTask", {
          id: data.dailyTaskBound.id,
        })
        .then((response) => {
          //删除成功返回true
          if (response.data) {
            data.daily.studentPlanDailyTasks =
              data.daily.studentPlanDailyTasks.filter(
                (item) => item.id != data.dailyTaskBound.id
              );
          }
        })
        .catch((error) => console.log(error.toString()));
    },

    handleOnBoundStudent: function () {
      this.bindStudentListDrawer = true;
    },

    handleOnBoundStudentClick: function () {
      this.$refs.sharedHeader.updateBoundStudentChineseName();
      this.handleBoundStudentChange();
    },

    handleBoundStudentChange: function () {
      //绑定新学生后，在这里获取新学生的plan
      this.getStudentPlans();
    },
    createPlan: function () {
      this.showCreatePlanForm = true;
    },

    handlePlanFormConfirm: function (plan) {
      if (plan.isEditing) {
        let editingPlan = this.plan.find((item) => item.id == plan.id);
        editingPlan.name = plan.name;
        editingPlan.description = plan.description;
        editingPlan.isActive = plan.isActive;
        editingPlan.totalDays = plan.totalDays;
      } else {
        this.plan.unshift(plan);
      }
    },

    handlePlanFormDetailConfirm: function (planDetail) {
      let plan = this.plan.find((item) => item.id == planDetail.studentPlanId);
      if (!plan.studentPlanDetails) {
        plan.studentPlanDetails = [];
      }

      if (planDetail.isEditing) {
        let epd = plan.studentPlanDetails.find(
          (item) => item.id == planDetail.id
        );

        epd.name = planDetail.name;
        epd.description = planDetail.description;
        epd.evaluation = planDetail.evaluation;
        epd.daterange = planDetail.daterange;
        epd.startDate = planDetail.startDate;
        epd.finishDate = planDetail.finishDate;
        epd.totalDays = planDetail.totalDays;
        epd.status = planDetail.status;
        epd.followUp = planDetail.followUp;
      } else {
        plan.studentPlanDetails.unshift(planDetail);
      }

      //增加后，更新current editing和currentPlanDetail（有两个当前活跃的detail）到index
      if (!planDetail.studentPlanDetailTasks) {
        planDetail.studentPlanDetailTasks = [];
      }
      this.currentEditingPlanDetail = planDetail;
      this.currentPlanDetail = planDetail;
    },

    handleAddPlanDetail: function (planId) {
      this.currentPlanId = planId;
      this.showCreatePlanDetailForm = true;
    },

    handleAddPlanDetailAndTasks: function (planId) {
      this.currentPlanId = planId;
      this.showCreatePlanDetailAndTaskForm = true;
    },

    handlePlanFormDetailAndTaskConfirm: function () {
      //获得plan detail task的detail和taks对象，但是先不使用，直接刷新页面
      this.$router.go(0);
      //this.handlePlanFormDetailConfirm(detailTasksObject.formDetail)
      //this.handlePlanFormDetailTaskConfirm(detailTasksObject.formData)
    },

    handleShowAllTaskAndResults: function (planItem) {
      this.showAllTaskAndResults = !this.showAllTaskAndResults;
      this.showOrHideEverythingInPlan(planItem, this.showAllTaskAndResults);
    },

    handleShowDailyDetail: function (dailyItem) {
      dailyItem.showDailyDetail = !dailyItem.showDailyDetail;
    },

    handleShowAllDailyDetailsClick: function () {
      const allDailyDetailsShown = this.allDaily.every(
        (item) => item.showDailyDetail
      );
      for (let i = 0; i < this.allDaily.length; i++) {
        this.allDaily[i].showDailyDetail = !allDailyDetailsShown;
      }
    },

    showOrHideEverythingInPlan: function (planItem, show) {
      for (let i = 0; i < planItem.studentPlanDetails.length; i++) {
        planItem.studentPlanDetails[i].showTask = show;
        for (
          let j = 0;
          j < planItem.studentPlanDetails[i].studentPlanDetailTasks.length;
          j++
        ) {
          planItem.studentPlanDetails[i].studentPlanDetailTasks[
            j
          ].showTaskResult = show;
        }
      }
    },

    handleEditPlan: function (plan) {
      this.currentEditingPlan = plan;
      this.showCreatePlanForm = true;
    },

    handleDetailNameClick: function (detail) {
      detail.showTask = !detail.showTask;
      if (detail.showTask) {
        //显示的时候刷新每个task的status
        this.setPlanDetailTaskStatus(detail);
      }
    },

    handleEditPlanDetail: function (planDetail) {
      this.currentEditingPlanDetail = planDetail;
      this.showCreatePlanDetailForm = true;
    },

    handleConvertTaskToTest: function (task) {
      this.currentTaskToTest = task;
      this.showCreateTestForm = true;
    },

    handleEditPlanDetailTask: function (task) {
      this.currentEditingPlanDetailTask = task.task;
      this.currentPlanDetail = task.detail;
      this.showCreatePlanDetailTaskForm = true;
    },

    handleEditPlanDaily: function (daily) {
      this.currentEdittingPlanDaily = daily;
      this.showCreatePlanDailyForm = true;
    },

    handleDailyTaskClick: function (task) {
      this.$store
        .dispatch(
          "StudentPlan/getStudentPlanDetailTaskResultsByStudentPlanDetailTaskId",
          {
            studentPlanDetailTaskId: task.id,
          }
        )
        .then((response) => {
          if (response.data) {
            task.studentPlanDetailTaskResults = response.data;
          }
        });
    },

    handleAddPlanDaily: function (planId) {
      //清空editting的内容
      this.currentEdittingPlanDaily = null;
      this.currentPlanId = planId;
      this.showCreatePlanDailyForm = true;
    },

    handlePlanFormDailyConfirm: function (planDaily) {
      if (planDaily.isEditing) {
        let editingDaily = this.allDaily.find(
          (item) => item.id == planDaily.id
        );
        editingDaily.id = planDaily.id;
        editingDaily.date = planDaily.date;
        editingDaily.evaluation = planDaily.evaluation;
        editingDaily.comment = planDaily.comment;
        editingDaily.isMilestone = planDaily.isMilestone;
        editingDaily.milestoneComment = planDaily.milestoneComment;
        editingDaily.studentPlanId = planDaily.studentPlanId;
      } else {
        //添加的时候，直接加到处理好了的allDaily里面
        this.allDaily.unshift(planDaily);
      }

      this.$router.go(0);

      // //update all daily
      // this.allDaily = this.plan.reduce((accumulator, plan) => {
      //   if (plan.planDaily && plan.planDaily.length > 0) {
      //     return accumulator.concat(plan.planDaily);
      //   }
      //   return accumulator;
      // }, []);
      // console.log(this.allDaily);
    },

    handleAddPlanDetailTask: function (planDetail) {
      //添加task的时候，editing要放空
      this.currentEditingPlanDetailTask = null;
      this.currentPlanDetail = planDetail;
      this.showCreatePlanDetailTaskForm = true;
      //展开正在编辑下的detail的task列表
      planDetail.showTask = true;
    },

    handleConvertAllTaskToTest: function (planDetail) {
      this.currentEditingPlanDetail = planDetail;
      this.showConvertAllDetailTaskToTestForm = true;
    },

    handleAddAutoTask: function (planDetail) {
      //添加task的时候，editing要放空
      this.currentEditingPlanDetailTask = null;
      this.currentPlanDetail = planDetail;
      this.showAutoCreatePlanDetailTaskForm = true;
      //展开正在编辑下的detail的task列表
      planDetail.showTask = true;
    },

    handlePlanFormDetailTaskConfirm: function (detailTask) {
      let plan = this.plan.find(
        (item) => item.id == detailTask.formDetail.studentPlanId
      );
      let detail = plan.studentPlanDetails.find(
        (item) => item.id == detailTask.formDetail.id
      );
      if (!detail.studentPlanDetailTasks) {
        detail.studentPlanDetailTasks = [];
      }
      if (!detailTask.formData.isEditing)
        detail.studentPlanDetailTasks.push(detailTask.formData);
      this.showCreatePlanDetailTaskForm = false;
      // let daily = this.plan.find((item) => item.id == planDaily.planId);
      // if (!plan.planDaily) {
      //   plan.planDaily = [];
      // }

      // plan.planDaily.push(planDaily);

      // //update all daily
      // this.allDaily = this.plan.reduce((accumulator, plan) => {
      //   if (plan.planDaily && plan.planDaily.length > 0) {
      //     return accumulator.concat(plan.planDaily);
      //   }
      //   return accumulator;
      // }, []);
      // console.log(this.allDaily);
    },

    handleAutoPlanFormDetailTaskConfirm: function (task) {
      console.log(task);
    },

    //因为for循环是顺序执行，而批量post任务是异步，所以，全部任务上传完成之后，就需要刷新一下index页面
    handleAllTasksPosted: function () {
      this.$router.go(0);
    },

    //每次获得一个task的response，就添加到对应的detail下面
    handleOnTaskResponse: function (responseTask) {
      this.currentPlanDetail.studentPlanDetailTasks.push(responseTask);
    },

    //因为for循环是顺序执行，而批量post任务是异步，所以，全部任务上传完成之后，就需要刷新一下index页面
    handleAllDailiesPosted: function () {
      this.$router.go(0);
    },

    handleTaskContentClick: function (task) {
      //可能还需要跳转到test，如果有vocStudentTestId
      if (!task.vocStudentTestId) {
        let queryObject = {
          userId: localStorage.getItem("targetId"),
          userName: localStorage.getItem("targetCNName"),
          setIds: task.setIds.split(",").map(Number),
          setProcesses: task.setProcesses.split(",").map(Number),
          projectName: task.project,
          projectType: 0,
          subjectName: task.subject,
          setName: task.setName,
          // currentSubjects: task.selectedProject.vocSubjects, //添加了之后出现431错误，应该是request的string太长了；这个应该也不需要在card添加，毕竟一开始就指定好了，所以可以删除
          //多添加几个属性，测试是否出现431错误
          taskId: task.id,
          examMode: task.examMode,
          criterion: task.criterion,
          isExaminingAsStudent: task.isExaminingAsStudent,
          from: task.from,
          to: task.to,
        };
        // direct 到其他页面后，把isExaminingAsStudent 变成false
        //不用变成false，应该是直到学生的词汇页面confirm之后，才最终变成false
        //task.isExaminingAsStudent = false;
        // direct to card page
        this.openAsNewPageWithCryption("/card", queryObject);
      } else {
        this.gotoTestPage(task);
      }
    },

    gotoTestPage: function (task) {
      // 用testId直接进入测试，要注意把task传过去
      let query = {
        id: task.vocStudentTestId,
        studentId: localStorage.getItem("targetId"),
        task: task,
      };

      // this.$store
      //   .dispatch("StudentTest/getTestById", {
      //     id: query.id,
      //     userId: query.studentId,
      //   })
      //   .then((response) => {
      //拿到测试的信息后，根据type判断是语音测试还是普通测试；语音测试的type是5
      if (task.testType === 5) {
        //进入语音测试
        //如果是绑定的学生，就进入结果页面，否则进入学生测试页面
        if (
          localStorage.getItem("userId") == localStorage.getItem("targetId")
        ) {
          this.openAsNewPageWithCryption("/audiotestitem", query);
        } else {
          this.openAsNewPageWithCryption("/audiotestitemresult", query);
        }
      } else {
        //进入普通测试
        //如果是绑定的学生，就进入结果页面，否则进入学生测试页面
        if (
          localStorage.getItem("userId") == localStorage.getItem("targetId")
        ) {
          this.openAsNewPageWithCryption("/newtestitem", query);
        } else {
          this.openAsNewPageWithCryption("/newtestitemresult", query);
        }
      }
      // })
      // .catch((error) => {
      //   this.$message(error.toString());
      // });
    },

    generatedResultFormatter: function (str) {
      if (str.includes(",")) {
        return str.split(",")[0] + "...";
      } else {
        return str;
      }
    },

    handleResultClick: function (result) {
      //console.log(result);
      let copyIncorrectsInput = document.createElement("input");
      copyIncorrectsInput.setAttribute("value", result.generatedResult);
      document.body.appendChild(copyIncorrectsInput);
      copyIncorrectsInput.select();
      try {
        let copied = document.execCommand("copy");

        if (copied) {
          ElMessage({
            message: result.generatedResult,
            type: "success",
          });
          document.body.removeChild(copyIncorrectsInput);
        }
      } catch {
        document.body.removeChild(copyIncorrectsInput);
      }
    },

    dateFormatter: function (dateValue) {
      const localDate = new Date(dateValue); // Create a new Date object (current date)
      const month = localDate.getMonth() + 1; // Get the month (add 1 to get correct month value)
      const day = localDate.getDate(); // Get the day
      const year = localDate.getFullYear(); // Get the year
      return `${month}.${day}.${year}`;
    },

    dateTimeFormatter: function (dateValue) {
      const localDate = new Date(dateValue); // Create a new Date object (current date)
      const month = localDate.getMonth() + 1; // Get the month (add 1 to get correct month value)
      const day = localDate.getDate(); // Get the day
      const hour = localDate.getHours(); // Get the year
      const minute = localDate.getMinutes();
      return `${month > 9 ? month : "0" + month}.${day > 9 ? day : "0" + day} ${
        hour > 9 ? hour : "0" + hour
      }:${minute > 9 ? minute : "0" + minute}`;
    },

    setProcessesFormatter: function (task) {
      let processes = task.setProcesses;
      if (!processes) {
        return "";
      }
      let objectArray = SETPROCESSOPTIONS;
      let setProcessesArray = processes.split(",");
      let resultNames = setProcessesArray.map((item) => {
        const matchingObject = objectArray.find(
          (obj) => obj.id === parseInt(item)
        );
        if (matchingObject.id === 5) {
          matchingObject.from = task.from;
          matchingObject.to = task.to;
        }
        return matchingObject.id === 5
          ? matchingObject.name + matchingObject.from + "-" + matchingObject.to
          : matchingObject.name;
      });
      return resultNames.join(",");
    },

    studyTypeFormatter: function (type) {
      if (!type) {
        return "";
      }
      let typeObject = [
        {
          id: 1,
          name: "盲筛",
        },
        {
          id: 2,
          name: "背诵",
        },
        {
          id: 3,
          name: "星标",
        },
        {
          id: 4,
          name: "复习",
        },
        {
          id: 5,
          name: "测试",
        },
      ];
      let resultName = typeObject.find((item) => item.id === type).name;
      return resultName;
    },

    examModeFormatter: function (mode) {
      if (!mode) {
        return "";
      }

      let modeObject = [
        {
          id: 1,
          name: "英翻中",
        },
        {
          id: 2,
          name: "中翻英",
        },
        {
          id: 3,
          name: "显示中英文",
        },
        {
          id: 4,
          name: "英翻中(仅音频)",
        },
      ];
      let resultName = modeObject.find((item) => item.id === mode).name;
      return resultName;
    },

    handleActivateExamine: function (task) {
      this.$store
        .dispatch(
          "StudentPlan/updateStudentPlanDetailTaskIsExaminingAsStudent",
          {
            taskId: task.id,
            isExaminingAsStudent: true,
          }
        )
        .then(() => {
          //把 isExaminingAsStudent 设置为true，以便在学生电脑端检查
          task.isExaminingAsStudent = true;
          this.$message({
            type: "success",
            message: "任务已激活～",
          });
        });
    },

    handleDeletePlanDetailTaskResult: function (data) {
      this.$store
        .dispatch("StudentPlan/deleteStudentPlanDetailTaskResult", {
          id: data.result.id,
        })
        .then((response) => {
          if (response.data) {
            data.task.studentPlanDetailTaskResults =
              data.task.studentPlanDetailTaskResults.filter(
                (item) => item.id != data.result.id
              );
          }
        });
    },

    // shortenSetName: function (str) {
    //   if (!str.includes("-")) return str;
    //   const parts = str.split("-");
    //   const shortenedStr = parts[0] + "...";
    //   return shortenedStr;
    // },

    isToday: function (date) {
      let today = new Date();
      let dailyDate = new Date(date);
      const isToday =
        today.getFullYear() === dailyDate.getFullYear() &&
        today.getMonth() === dailyDate.getMonth() &&
        today.getDate() === dailyDate.getDate();

      return isToday ? true : false;
    },

    // resultDateSync: function (resultDate, currentDate) {
    //   if (!(resultDate && currentDate)) return false;
    //   const isDateMatch =
    //     resultDate.getFullYear() === currentDate.getFullYear() &&
    //     resultDate.getMonth() === currentDate.getMonth() &&
    //     resultDate.getDate() === currentDate.getDate();
    //   return isDateMatch ? true : false;
    // },

    filterTaskSetName: function (setName) {
      if (!setName) return;
      if (setName.length > 30) {
        return (
          setName.substring(0, 15) +
          "..." +
          setName.substring(setName.length - 15)
        );
      } else {
        return setName;
      }
    },

    reduceTaskSetName: function (setName) {
      if (!setName || !setName.includes("-")) return setName;
      let setNameList = setName.split("-");
      return setNameList[0] + "..." + setNameList[setNameList.length - 1];
    },

    openAsNewPageWithCryption: function (routeName, query) {
      localStorage.setItem("projectType", 0);
      query = JSON.stringify(query);
      query = window.encodeURIComponent(query);
      let newpage = this.$router.resolve({
        path: routeName,
        query: { encodedString: window.btoa(query) },
      });
      window.open(newpage.href, "_blank");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-content {
  margin-top: 1rem;
}
.cursor {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.9);
}
.cursor:hover {
  color: #b3e19d;
}
.planManipulation {
  float: right;
  padding: 3px 0;
}
.planManipulation span {
  margin-left: 0.5rem;
}

.plan_card {
  margin-top: 1rem;
}

.result-row {
  background-color: #ecf5ff;
  border-bottom: 1px solid white;
}

.taskBackgroundColor {
  background-color: #d9ecff;
}

.planShowTime {
  color: deepskyblue;
}

.passed {
  color: deepskyblue;
}

.failed {
  color: red;
}
</style>
