<template>
  <div>
    <el-dialog
      width="40%"
      v-model="dialogFormVisible"
      title="创建计划"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-form-item label="激活">
        <el-switch
          v-model="form.isActive"
          active-color="#13ce66"
          inactive-color="gray"
        />
      </el-form-item>

      <el-form-item label="名称">
        <el-input
          v-model="form.name"
          placeholder="请输入计划名称"
          @keydown.stop
        >
        </el-input>
      </el-form-item>

      <el-form-item label="天数">
        <el-input-number
          v-model="form.totalDays"
          size="small"
          @keydown.stop
          placeholder="请输入该计划总天数"
        />
      </el-form-item>
      <div style="text-align: left">
        <span>描述</span>
      </div>
      <quill-editor v-model:value="form.description" />

      <div style="text-align: left">
        <span>总结</span>
      </div>
      <quill-editor v-model:value="form.summary" />

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onCreatePlanFormCancel()">Cancel</el-button>
          <el-button type="primary" @click="onCreatePlanFormConfirm()"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CreatePlanForm",
  components: {},

  props: ["modelValue", "editingPlan"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  mounted: function () {},

  data() {
    return {
      dialogFormVisible: false,
      description: "",

      form: {
        isActive: true,
        name: "",
        description: "",
        totalDays: 0,
        isEditing: false,
        summary: ""
      },
    };
  },

  methods: {
    handleColorPickerOpen() {
      const colorPicker = document.querySelector(".tox-colorpicker");
      colorPicker.style.zIndex = 9999; // Adjust the z-index value as needed
    },
    onCreatePlanFormCancel: function () {
      this.dialogFormVisible = false;
      this.$emit("on-plan-form-cancel");
    },
    onCreatePlanFormConfirm: function () {
      //如果editing plan有东西，就是editing，否则是add
      this.form.isEditing = this.editingPlan ? true : false;
      let postData = this.form;
      postData.userId = localStorage.getItem("targetId");
      postData.createdBy = localStorage.getItem("userId");
      this.$store
        .dispatch("StudentPlan/addOrEditStudentPlan", postData)
        .then((response) => {
          if (response.data > 0) {
            //返回成功
            postData.id = response.data;
            this.$emit("on-plan-form-confirm", postData);
            this.form = {
              isActive: true,
              name: "",
              description: "",
              totalDays: 0,
              summary: ""
            };
            this.dialogFormVisible = false;
            this.$message("已添加～")
          } else {
            this.$message("Something Wrong~");
          }
        });
    },

    handleOpen: function () {
      if (this.editingPlan) {
        this.form = this.editingPlan;
      }
    },

    handleClose: function () {
      // console.log('aaaa')
      this.$emit("on-plan-form-close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mark-heart {
  color: deepskyblue;
}

.cursor {
  cursor: pointer;
}
</style>
