<template>
  <div>
    <el-select
      v-model="selectRemoteGrade.value"
      filterable
      defalt-first-option
      remote
      :remote-method="getGradesByName"
      placeholder="远程搜索班级"
      @change="handleRemoteGradeSelected"
      :loading="selectRemoteGrade.loading"
      @keydown.stop
    >
      <el-option
        v-for="item in selectRemoteGrade.options"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  name: "RemoteSearchGradeInputbox",
  props: [],
  watch: {},
  data() {
    return {
      search: "",
      selectRemoteGrade: {
        value: "",
        options: [],
        loading: false,
      },
    };
  },
  mounted: function () {
    //改成默认请求数据；且鼠标离开的时候，不会清空数据
    this.handleMouseEnter();
  },
  methods: {
    //鼠标进入的时候才请求数据
    handleMouseEnter: function () {
      this.$store
        .dispatch("Admin/getRelationship", {
          userId: this.currentUserId,
        })
        .then((response) => {
          this.boundStudentList = response.data;
          //找到最后一个匹配自身的index，然后如果不是第一个，就移除，以免有两个自己
          let lastIndexOfSelf = this.boundStudentList.findLastIndex(
            (item) => item.targetId == this.currentUserId
          );
          if (lastIndexOfSelf > 0) {
            this.boundStudentList.splice(lastIndexOfSelf, 1);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //鼠标离开的时候清空数据
    // handleMouseLeave: function() {
    //   this.boundStudentList = [];
    // },

    getGradesByName: function (val) {
      this.selectRemoteGrade.loading = true;
      this.$store
        .dispatch("Grade/getGradesByName", {
          name: val.trim(),
        })
        .then((response) => {
          this.selectRemoteGrade.loading = false;
          this.selectRemoteGrade.options = response.data;
          console.log(this.selectRemoteGrade.options);
        })
        .catch((error) => {
          console.log(error.toString());
          this.selectRemoteGrade.loading = false;
        });
    },

    handleRemoteGradeSelected: function (gradeId) {
      console.log(gradeId);
      //用gradeid获得grade里main的学生
      this.$store
        .dispatch("Grade/getStudentsInGrade", {
          gradeId: gradeId,
        })
        .then((response) => {
          this.$emit("get-students-by-grade", response.data)
        })
        .catch((error) => {
          console.log(error.toString());
        });
      //clear data in select options
      this.selectRemoteGrade = {
        value: "",
        options: [],
        loading: false,
      };
    },
  },
};
</script>

<style>
.hightlightCurrentText {
  color: deepskyblue;
}
</style>
