<template>
  <div id="header">

    <el-menu class="el-menu-demo" mode="horizontal">


      <el-menu-item index="1" @click="handleGoHome">
        <router-link to="/">Home</router-link>
      </el-menu-item>


      <el-menu-item index="2" @click="handleGoDIY">
        <router-link to="/DIY">DIY</router-link>
      </el-menu-item>
      
      <el-menu-item index="4" v-if="this.userType.isAdmin || projectType == 1">
        <span @click="$emit('org-clicked')">ORG</span>
      </el-menu-item>

      <el-menu-item index="7">
        <router-link to="/studenttest">Test</router-link>
      </el-menu-item>

      <el-menu-item index="8">
        <router-link :to="userType.isStudent ? 'vPracticeList' : '/assigntostudent'">VIP</router-link>
      </el-menu-item>

      <el-menu-item index="9" v-if="!userType.isStudent">
        <span @click="$emit('student-overview-clicked')">Enigma</span>
      </el-menu-item>

      <el-menu-item index="10">
        <span @click="handleRecentResultsClick()">Deja vu</span>
      </el-menu-item>

      <el-menu-item index="10">
        <span @click="handlePlanClick()">Plan</span>
      </el-menu-item>

      <el-sub-menu index="5" v-if="userType.isAdmin || userType.isTeacher">
        <template #title>Student</template>
        <el-menu-item index="5-1" @click="handleBindStudent()">Bind Student</el-menu-item>
        <el-menu-item index="5-2" @click="$emit('on-bound-student')">Bound Student</el-menu-item>
        <!-- <el-menu-item index="5-2" @click="handleUserManagement()">User Management</el-menu-item> -->
      </el-sub-menu>

      <el-sub-menu index="6" class="userInfo">
        <template #title>
          <div>
            <el-icon><avatar /></el-icon>
            <span>{{ currentUserName }}</span>
            <span class="currentTarget">: {{ targetCNName }}</span>
          </div>
        </template>
        <!-- 如果要改密码，就去listening的页面，这里不再需要change password了 -->
        <!-- <el-menu-item index="5-1" @click="handleChangePassword()">Change Password</el-menu-item> -->
        <el-menu-item index="6-1" @click="handleLogout()">Logout</el-menu-item>
        <el-menu-item index="6-2" @click="handleChangePassword()">ChangePassword</el-menu-item>
      </el-sub-menu>

      <!-- <el-menu-item class="userInfo">
        <el-dropdown>
          <div>
            <i class="el-icon-user"></i>
            <span>{{ currentUserName }}</span>
            <span class="currentTarget">: {{ targetCNName }}</span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click="handleChangePassword()"
              >Change Password</el-dropdown-item
            >
            <el-dropdown-item @click="handleLogout()"
              >Logout</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-menu-item> -->
    </el-menu>

  </div>
</template>

<script type="text/javascript">
export default {
  name: "SharedHeader",
  props: [],

  data() {
    return {
      targetId: null,
      targetCNName: "",
      currentUserName: null,
      projectType: 0,
      userType: {
        isAdmin: false,
        isTeacher: false,
        isStudent: false,
      },
    };
  },

  mounted: function () {
    this.targetId = localStorage.getItem("targetId");
    this.currentUserName = localStorage.getItem("userName");
    this.targetCNName = localStorage.getItem("targetCNName");
    this.setUserPermission();
  },

  methods: {
    setUserPermission: function () {
      let roleName = localStorage.getItem("userRole");
      if (roleName === "Admin") {
        this.userType.isAdmin = true;
      } else if (roleName === "Teacher") {
        this.userType.isTeacher = true;
      } else {
        this.userType.isStudent = true;
      }
    },

    updateBoundStudentChineseName: function() {
      this.targetCNName = localStorage.getItem("targetCNName");
    },

    handleGoHome: function () {
      this.projectType = 0;
      this.$emit("router-go-home");
    },

    handleGoDIY: function () {
      this.projectType = 1;
      this.$emit("router-go-diy");
    },

    handleRecentResultsClick: function () {
      this.$emit("recent-result-click");
    },

    handlePlanClick: function() {
      this.$router.push("/plan");
    },

    // handleSelect: function (key, keyPath) {
    //   if (keyPath.length > 2) {
    //     // only Andover menu has 2 layer menu, so if length > 2, the user is routing grammar sentences
    //     this.$router.push({
    //       path: "/grammar",
    //       query: {
    //         key: key,
    //       },
    //     });
    //     this.$emit("handle-get-grammar-sentences", key);
    //   } else {
    //     if (key === "2" || key === "4") this.$emit("route-change", ""); //used to refresh the mark lobby. Otherwise, from article sentences to user mark sentences will not refresh the data
    //     if (
    //       key === "3" ||
    //       key === "6" ||
    //       key === "8" ||
    //       key === "9" ||
    //       key === "10" ||
    //       key === "14"
    //     )
    //       this.$emit("refresh-articles");
    //   }
    // },

    handleLogout: function () {
      localStorage.clear();
      this.$router.push("/login");
    },

    handleChangePassword: function () {
      this.$emit("change-password-click");
    },

    // handleChangePassword: function () {
    //   this.$router.push("/changepassword");
    // },

    handleUserManagement: function () {
      this.$router.push("/usermanagement");
    },

    handleBindStudent: function () {
      this.$router.push("/bindstudent");
    },

  },
};
</script>
<style scoped>
div {
  background-color: white;
}
div a {
  margin-left: 5%;
  cursor: pointer;
}
div a font {
  font-size: 30px;
  color: #f5f5f5;
}

div#header {
  text-align: center;
}


.userInfo {
  float: right !important;
  margin-right: 5% !important;
  height: 50% !important;
}

.currentTarget {
  color: deepskyblue;
}

a {
  text-decoration: none;
}
</style>
