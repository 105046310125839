import axios from "axios";
import url from "../urls";

export default {
  //put
  add(context, data) {
    return new Promise((resolve, reject) => {
      let pUrl = url.vocUrl;
      axios
        .put(pUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //get
  // get(context, data) {
  //   return new Promise((resolve, reject) => {
  //     let getUrl = "/Assignment/GetAssignmentQuestion";

  //     axios
  //       .get(getUrl, {
  //         params: {
  //           assignmentId: data.assignmentId,
  //         },
  //       })
  //       .then((response) => {
  //         console.log("question got");
  //         context.commit("mutateCompletionQuestions", response.data);
  //         resolve(response);
  //       })
  //       .catch((error) => reject(error));
  //   });
  // },
  //post
  edit(context, data) {
    return new Promise((resolve, reject) => {
      let editUrl = url.vocUrl;
      axios
        .post(editUrl, data)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  //delete
  delete(context, data) {
    return new Promise((resolve, reject) => {
      let deleteUrl = url.vocUrl;
      axios
        .delete(deleteUrl, { params: data })
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //add many voc
  batchly(context, data) {
    return new Promise((resolve, reject) => {
      console.log(data);
      let addManyUrl = url.batchVocUrl;
      axios
        .post(addManyUrl, data)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //get
  getMarkedVocabularies(context, params) {
    return new Promise((resolve, reject) => {
      let getUrl = url.markedVocabularyUrl;
      axios
        .get(getUrl, {
          params,
          paramsSerializer: Array.isArray(params.setIds)
            ? (params) => {
                return params.setIds
                  .map((item) => `userId=${params.userId}&` + `setIds=${item}`)
                  .join("&");
              }
            : null,
        })
        .then((response) => {
          //context.commit("mutateCompletionQuestions", response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //get
  getMarkedVocabulariesBySingleSet(context, params) {
    return new Promise((resolve, reject) => {
      let getUrl = url.markedVocabulariesBySingleSetUrl;
      axios
        .get(getUrl, {
          params: params
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post mark
  markVocabulary(context, data) {
    return new Promise((resolve, reject) => {
      let murl = url.markedVocabularyUrl;
      axios
        .post(murl, data)
        .then((response) => {
          console.log(response)
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post tag
  tagVocabulary(context, data) {
    return new Promise((resolve, reject) => {
      let turl = url.VocabularyTagUrl;
      axios
        .post(turl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post comment
  commentVocabulary(context, data) {
    return new Promise((resolve, reject) => {
      let turl = url.VocCommentUrl;
      axios
        .post(turl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  //post student mark
  studentMarkVocabulary(context, data) {
    return new Promise((resolve, reject) => {
      let surl = url.VocStudentMark;
      axios
        .post(surl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
