<template>
  <div>
    <shared-header />

    <div style="width: 60%; margin-left: 20%">
      <el-button
        class="mt-4"
        style="width: 100%; margin-top: 10px"
        @click="Nirvana()"
        >Nirvana</el-button
      >
      <el-table
        ref="allAssignmentTable"
        class="assignment-table"
        :data="assignmentList"
        @row-click="
          (row, column, event) => {
            handleAssignmentListRowClick(row, column, event);
          }
        "
      >
        <el-table-column type="expand">
          <template #default="props">
            <div>
              <h3>作业中的词汇题set</h3>
              <div class="voc-sets-container">
                <div
                  class="voc-single-set"
                  v-for="(setItem, index) in props.row.vocSets"
                  :key="index"
                  @click="handleAssignmentSetRowClick(setItem, props)"
                >
                  <span> {{ setItem.name }} &nbsp; </span>
                  <span>{{
                    setItem.vocAssignmentSetSummary
                      ? setItem.vocAssignmentSetSummary.status + " "
                      : ""
                  }}</span>
                  <span
                    v-if="
                      setItem.vocAssignmentSetSummary &&
                      setItem.vocAssignmentSetSummary.status == 'finished'
                    "
                  >
                    {{
                      dateFormater(setItem.vocAssignmentSetSummary.endDate)
                    }}&nbsp;
                  </span>
                  <span
                    v-if="
                      setItem.vocAssignmentSetSummary &&
                      setItem.vocAssignmentSetSummary.status == 'finished'
                    "
                  >
                    {{
                      "耗时: " +
                      Math.round(
                        parseInt(
                          new Date(setItem.vocAssignmentSetSummary.endDate) -
                            new Date(setItem.vocAssignmentSetSummary.startDate)
                        ) / 1000
                      ).toString() +
                      "s"
                    }}&nbsp;
                  </span>
                  <span
                    v-if="
                      setItem.vocAssignmentSetSummary &&
                      setItem.vocAssignmentSetSummary.status == 'finished'
                    "
                  >
                    {{
                      "准确率：(" +
                      setItem.vocAssignmentSetSummary.correctCount +
                      "/" +
                      setItem.vocAssignmentSetSummary.totalCount +
                      ")"
                    }}
                  </span>
                </div>
              </div>
              <!-- <el-table
                :data="props.row.vocSets"
                @row-click="
                  (row) => {
                    handleAssignmentSetRowClick(row, props);
                  }
                "
                :row-class-name="tableRowClassName"
              >
                <el-table-column label="名称" prop="name" width="100" />
                <el-table-column label="状态" width="100">
                  <template #default="scope">
                    {{
                      scope.row.vocAssignmentSetSummary
                        ? scope.row.vocAssignmentSetSummary.status
                        : ""
                    }}
                  </template>
                </el-table-column>

                <el-table-column label="完成日期" width="100">
                  <template #default="scope">
                    <span
                      v-if="
                        scope.row.vocAssignmentSetSummary &&
                        scope.row.vocAssignmentSetSummary.status == 'finished'
                      "
                    >
                      {{
                        dateFormater(scope.row.vocAssignmentSetSummary.endDate)
                      }}
                    </span>
                  </template>
                </el-table-column>

                <el-table-column label="耗时" width="180">
                  <template #default="scope">
                    <span
                      v-if="
                        scope.row.vocAssignmentSetSummary &&
                        scope.row.vocAssignmentSetSummary.status == 'finished'
                      "
                    >
                      {{
                        "耗时: " +
                        Math.round(
                          parseInt(
                            new Date(
                              scope.row.vocAssignmentSetSummary.endDate
                            ) -
                              new Date(
                                scope.row.vocAssignmentSetSummary.startDate
                              )
                          ) / 1000
                        ).toString() +
                        "s"
                      }}
                    </span>
                  </template>
                </el-table-column>

                <el-table-column label="准确率" width="100">
                  <template #default="scope">
                    <span
                      v-if="
                        scope.row.vocAssignmentSetSummary &&
                        scope.row.vocAssignmentSetSummary.status == 'finished'
                      "
                    >
                      {{
                        "(" +
                        scope.row.vocAssignmentSetSummary.correctCount +
                        "/" +
                        scope.row.vocAssignmentSetSummary.totalCount +
                        ")"
                      }}
                    </span>
                  </template>
                </el-table-column>
              </el-table> -->
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="name" label="名称"> </el-table-column>
        <el-table-column
          property="createdDate"
          label="创建日期"
          sortable
          width="180"
          column-key="createdDate"
        >
          <template #default="scope">
            {{ scope.row.createdDate.substring(5, 10) }}
          </template>
        </el-table-column>

        <el-table-column align="right" v-if="userRole != 'Student'">
          <template #default="scope">
            <el-button
              size="small"
              type="danger"
              @click.stop="handleDelete(scope.$index, scope.row)"
              >Delete</el-button
            >
          </template>
        </el-table-column>

        <!-- <el-table-column
        prop="status"
        label="状态"
        width="100"
        :filters="[
          { text: 'standing', value: 'standing' },
          { text: 'pending', value: 'pending' },
          { text: 'finished', value: 'finished' },
        ]"
        :filter-method="filterStatus"
        filter-placement="bottom-end"
      >
        <template #default="scope">
          <el-tag
            :type="scope.row.status === 'finished' ? '' : 'success'"
            disable-transitions
            >{{ scope.row.status }}</el-tag
          >
        </template>
      </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
import SharedHeader from "../../shared/SharedHeader.vue";

export default {
  name: "StudentVocPracticeList",

  components: {
    SharedHeader,
  },

  props: ["targetId"],

  watch: {
    targetId: function () {
      this.studentId = this.targetId;
      this.getStudentAssignmentList();
    },
  },

  computed: {},

  mounted: function () {
    this.userRole = localStorage.getItem("userRole");
    this.studentId = localStorage.getItem("targetId");
    this.getStudentAssignmentList();
  },

  data() {
    return {
      searchOrigin: "",
      userRole: "",
      studentId: 0,
      currentRowInfo: null,
      assignmentList: [],
    };
  },

  methods: {
    openAsNewPageWithCryption: function (routeName, query) {
      query = JSON.stringify(query);
      query = window.encodeURIComponent(query);
      let newpage = this.$router.resolve({
        path: routeName,
        query: { encodedString: window.btoa(query) },
      });
      window.open(newpage.href, "_blank");
    },

    OpenInCurrentPageWithCryption: function (routeName, query) {
      query = JSON.stringify(query);
      query = window.encodeURIComponent(query);
      this.$router.push({
        path: routeName,
        query: { encodedString: window.btoa(query) },
      });
    },

    Nirvana: function () {
      this.$store
        .dispatch("VIP/postVocNirvanaSummary", {
          userId: this.studentId,
        })
        .then((response) => {
          console.log(response);
          this.openAsNewPageWithCryption("vNirvanaItem", {
            studentId: this.studentId,
            nirvanaId: response.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getStudentAssignmentList: function () {
      console.log(
        "getting assignment by student id " + this.studentId.toString()
      );
      this.$store
        .dispatch("VIP/getAssignmentList", {
          studentId: this.studentId,
        })
        .then((response) => {
          this.assignmentList = response.data;
          console.log(this.assignmentList);
        })
        .catch((error) => console.log(error));
    },

    //删除整个assignment
    handleDelete: function (index, row) {
      console.log(row.id);
      this.$store
        .dispatch("VIP/delete", {
          vocAssignmentId: row.id,
        })
        .then((response) => {
          if (response.data) {
            this.assignmentList.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功",
            });
          }
        })
        .catch((error) => console.log(error.toString()));
    },

    //处理日期显示格式
    dateFormater: function (dateString) {
      if (!dateString) return;
      // let lastIndexOfDot = dateString.lastIndexOf('.');
      //取前面10位，也就是2022-12-09
      return dateString.substr(0, 10);
    },
    //处理日期显示格式

    //handleAssignmentListRowClick: function (row, column, event, payload) {
    handleAssignmentListRowClick: function (row) {
      console.log(row);
    },

    handleAssignmentSetRowClick: function (row, assignment) {
      if (
        this.userRole != "Student" &&
        row?.vocAssignmentSetSummary?.status != "finished"
      )
        return;
      let query = {
        setRow: row,
        assignmentRow: assignment.row,
      };

      // 如果是打开新页面，那么新页面做题结束的时候，set是finished，不会更新到assignment list页面，造成状态不匹配的bug
      // 切换到同一个页面打开，以便强制回到assignment list页面的时候会刷新
      // 有可能需要再调整成，老师在新页面打开查看学生结果；学生则在本页面打开
      this.OpenInCurrentPageWithCryption("vPracticeItem", query);
      if (
        !row.vocAssignmentSetSummary ||
        row?.vocAssignmentSetSummary?.status != "finished"
      ) {
        this.$store
          .dispatch("VIP/postVocAssignmentSetSummary", {
            vocAssignmentSetId: row.vocAssignmentVocSetId,
            status: "pending",
          })
          .then((response) => console.log(response))
          .catch((error) => console.log(error));
      }
    },

    // filterType: function (val, row) {
    //   return row.type == val;
    // },

    // filterStatus: function (val, row) {
    //   return row.status == val;
    // },

    // clearFilter: function () {
    //   this.$refs.allTestTable.clearFilter();
    //   this.searchOrigin = "";
    // },

    //tableRowClassName: function ({ row, rowIndex }) {
    tableRowClassName: function ({ row }) {
      if (
        row.vocAssignmentSetSummary &&
        row.vocAssignmentSetSummary.status == "pending"
      ) {
        return "warning-row";
      } else if (
        row.vocAssignmentSetSummary &&
        row.vocAssignmentSetSummary.status == "finished"
      ) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-9);
}
.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}

.voc-single-set span {
  margin-right: 3rem;
  line-height: 2rem;
  cursor: pointer;
}
</style>