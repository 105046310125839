<template>
  <div id="vocExportDrawer">
    <el-drawer
      title="导出单词"
      v-model="drawer"
      :before-close="handleClose"
      direction="ttb"
      size="100%"
    >
      <el-container id="addManyVocContainer">
        <el-main>
          <div class="filterToolBar">
            <el-checkbox
              v-model="exportContentController.showChinese"
              label="中文"
              border
            ></el-checkbox>
            <el-checkbox
              v-model="exportContentController.showMarkedCount"
              label="显示心标次数文本"
              border
            ></el-checkbox>
            <el-checkbox
              v-model="exportContentController.showTags"
              label="标签"
              border
            ></el-checkbox>
            <el-checkbox
              v-model="exportContentController.showComment"
              label="评论"
              border
            ></el-checkbox>

                


            <!-- <el-checkbox
          v-model="exportContentController.showStudentComment"
          label="学生笔记"
          border
        ></el-checkbox>
        <el-checkbox
          v-model="exportContentController.showTeacherComment"
          label="教师笔记"
          border
        ></el-checkbox>
        <el-button @click="handleTeacherRows()">删除无教师笔记行</el-button>
        <el-button @click="handleStudentRows()">删除无学生笔记行</el-button> -->
          </div>

          <el-row>
            <el-col :span="12">
              <p class="introduction">此处文本可用于编辑和复制</p>
              <el-input
                type="textarea"
                :rows="20"
                placeholder="请输入内容"
                v-model="textarea"
              ></el-input>
            </el-col>
            <el-col :span="12">
              <p class="introduction">
              <span>
                当前累积标记单词: {{ this.currentMarkedVocs.length }}
              </span>
              <span style="margin-left: 20px"><el-button @click="handleClearCurrentMarkedVocs">清空文本 </el-button></span>
              </p>
              <el-input
                type="textarea"
                :rows="20"
                placeholder="请输入内容"
                v-model="markedVocsTextarea"
              ></el-input>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-input
                v-model="splitChar"
                placeholder="请输入单词和释义间的分隔符"
                @input="refreshTextarea()"
              >
                <template #prepend>单词和释义间的分隔符(默认为空格)</template>
              </el-input>
            </el-col>
            <el-col :span="12">
              <el-input
                v-model="splitVocChar"
                placeholder="请输入单词和单词间的分隔符"
                @input="refreshTextarea()"
              >
                <template #prepend>单词和单词间的分隔符(默认为换行)</template>
              </el-input>
            </el-col>
          </el-row>
        </el-main>
      </el-container>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "VocExportDrawer",

  watch: {
    modelValue: function () {
      this.drawer = this.modelValue;
      if (this.drawer) {
        this.textarea = this.parseVocList(this.currentVocList);
        this.markedVocsTextarea = this.parseVocList(this.currentMarkedVocs);
      }
    },

    exportContentController: {
      handler() {
        console.log(this.exportContentController);
        this.refreshTextarea();
      },
      deep: true,
    },
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    currentVocList: {
      type: Array,
    },
    currentMarkedVocs: {
      type: Array,
    },
  },

  data() {
    return {
      drawer: false,
      textarea: "",
      markedVocsTextarea: "",
      splitChar: " ",
      splitVocChar: "\n",
      vocList: [],
      exportContentController: {
        showChinese: true,
        showMarkedCount: true,
        showTags: true,
        showComment: true,
      },
    };
  },
  mounted: function () {},
  methods: {
    handleClose: function (done) {
      this.$emit("on-voc-export-drawer-closed");
      done();
    },

    parseVocList: function (inputData) {
      if (inputData === null || inputData.length < 0) return;
      let result = "";
      for (var i = 0; i < inputData.length; i++) {
        result += inputData[i].englishText + this.splitChar;
        if (this.exportContentController.showChinese) {
          result += inputData[i].chineseText;
        }
        if (this.exportContentController.showMarkedCount) {
          result += "; *" + inputData[i].count;
        }
        if (this.exportContentController.showTags) {
          result +=
            "; " +
            inputData[i].vocabularyTags
              .map((item) => {
                return item.chineseName;
              })
              .join(" | ");
        }

        if (
          this.exportContentController.showComment &&
          inputData[i].studentComent
        ) {
          result += "：" + inputData[i].studentComent;
        }

        result += this.splitVocChar;
      }
      return result;
    },

    refreshTextarea: function () {
      this.textarea = this.parseVocList(this.currentVocList);
      this.markedVocsTextarea = this.parseVocList(this.currentMarkedVocs);
    },

    handleClearCurrentMarkedVocs: function() {
      this.$emit('on-handle-clear-current-marked-vocs');
      this.markedVocsTextarea = "";
    },
  },
};
</script>

<style scoped>
#vocExportDrawer {
  margin-left: 20%;
  margin-right: 20%;
}

.el-input__inner {
  border: 0 none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
}

.el-drawer.ttb {
  overflow: auto !important;
}

p.introduction {
  font-size: 24px;
}
</style>
