<template>
  <div>
    <el-dialog
      width="35%"
      v-model="dialogFormVisible"
      title="词汇过滤器"
      @open="handleOpen"
      @close="handleClose"
    >
      <!-- <div>
        <el-tooltip
          class="box-item"
          effect="dark"
          content="减少心，确认后，将只显示所选心数量以上的词汇"
          placement="top-start"
        >
          <span @click="handleMarkCountMinusClick" class="cursor"
            ><el-icon><RemoveFilled /></el-icon
          ></span>
        </el-tooltip>
        <span v-for="(item, index) in form.markCountFilter" :key="index">
          <span class="mark-heart iconfont heart icon-marked"></span>
        </span>
        <el-tooltip
          class="box-item"
          effect="dark"
          content="增加心，确认后，将只显示所选心数量以上的词汇"
          placement="top-start"
        >
          <span @click="handleMarkCountAddClick" class="cursor"
            ><el-icon><CirclePlusFilled /></el-icon
          ></span>
        </el-tooltip>
      </div> -->

      <el-form :model="form">
        <!-- <el-form-item label="随机">
          <el-switch
            v-model="form.random"
            @change="handleRandomSwitchChange($event)"
          />
        </el-form-item> -->
        <!-- <el-form-item label="音频">
          <el-switch
            v-model="form.audio"
            @change="handleAudioSwitchChange($event)"
          />
        </el-form-item> -->
        <!-- <el-form-item label="星标">
          <div>
            <el-tooltip
              class="box-item"
              effect="dark"
              content="减少心，确认后，将只显示所选心数量及以上的词汇"
              placement="top-start"
            >
              <span @click="handleMarkCountMinusClick" class="cursor"
                ><el-icon><RemoveFilled /></el-icon
              ></span>
            </el-tooltip>
            <span v-for="(item, index) in form.markCountFilter" :key="index">
              <span class="mark-heart iconfont heart icon-marked"></span>
            </span>
            <el-tooltip
              class="box-item"
              effect="dark"
              content="增加心，确认后，将只显示所选心数量及以上的词汇"
              placement="top-start"
            >
              <span @click="handleMarkCountAddClick" class="cursor"
                ><el-icon><CirclePlusFilled /></el-icon
              ></span>
            </el-tooltip>
          </div>
        </el-form-item> -->
        <el-form-item label="心标">
          <div style="display: flex">
            <!-- <el-tooltip
              class="box-item"
              effect="dark"
              content="选择几个，就显示有几颗心标记的单词，支持多选"
              placement="top-start"
            > -->
            <el-checkbox
              v-model="checkAllHeart"
              :indeterminate="isIndeterminate"
              @change="handleCheckAllHeartChange"
              ><span style="font-size: 12px; margin-right: 10px"
                >check all</span
              ></el-checkbox
            >
            <el-checkbox-group
              v-model="form.checkedHeart"
              @change="handleCheckedHeartChange"
            >
              <el-checkbox
                v-for="(markCount, index) in form.allHeartCount"
                :key="index"
                :label="markCount"
                >{{ markCount }}</el-checkbox
              >
            </el-checkbox-group>

            <!-- </el-tooltip> -->
          </div>
        </el-form-item>
        <el-form-item label="范围">
          <el-row>
            <el-col :span="5">
              <el-input
                v-model.number="form.startIndex"
                class="input-from"
                size="small"
              ></el-input>
            </el-col>
            <el-col :span="1"> - </el-col>
            <el-col :span="5">
              <el-input
                v-model.number="form.endIndex"
                class="input-from"
                size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>

        <!-- <el-form-item label="检查模式">
          <el-radio-group
            v-model="form.answer"
            @change="handleRadioGroupChange($event)"
          >
            <el-radio :label="1">英翻中</el-radio>
            <el-radio :label="4">英翻中(仅音频)</el-radio>
            <el-radio :label="2">中翻英</el-radio>
            <el-radio :label="3">显示中英文</el-radio>
          </el-radio-group>
        </el-form-item> -->

        <el-form-item label="标签">
          <div class="tags-check-group">
            <el-checkbox-group v-model="form.checkedTags">
              <el-checkbox
                v-for="(tag, index) in form.allTags"
                :key="index"
                :label="tag.id"
                >{{ tag.chineseName }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onToolConfigFormCancel()">Cancel</el-button>
          <el-button type="primary" @click="onToolConfigFormConfirm()"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Tags from "./tags";
export default {
  name: "ToolConfigForm",

  props: ["modelValue", "currentVocIndex", "totalVocCount"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  data() {
    return {
      dialogFormVisible: false,
      isIndeterminate: true,
      checkAllHeart: false,

      form: {
        random: false,
        audio: true,
        starred: false,
        answer: 1,
        startIndex: null,
        endIndex: null,
        checkedHeart: [],
        allHeartCount: [1, 2, 3, 4, 5, 6],
        checkedTags: [],
        allTags: Tags.TAGS,
      },
    };
  },

  // mounted: function() {
  //   this.form.allTags = Tags;
  //   console.log(this.form.allTags)
  // },

  methods: {
    onToolConfigFormCancel: function () {
      this.dialogFormVisible = false;
      this.$emit("on-tool-config-form-cancel");
    },
    onToolConfigFormConfirm: function () {
      this.dialogFormVisible = false;
      this.$emit("on-tool-config-form-confirm", this.form);
    },

    // handleRandomSwitchChange: function (val) {
    //   this.$emit("on-random-switch-change", val);
    // },

    handleAudioSwitchChange: function (val) {
      this.$emit("on-audio-switch-change", val);
    },

    handleRadioGroupChange: function (val) {
      this.$emit("on-answer-radio-group-change", val);
    },

    // handleMarkCountMinusClick: function () {
    //   if (this.form.markCountFilter > 0) this.form.markCountFilter -= 1;
    // },

    // handleMarkCountAddClick: function () {
    //   if (this.form.markCountFilter < 5) this.form.markCountFilter += 1;
    // },

    handleCheckAllHeartChange: function (val) {
      this.form.checkedHeart = val ? this.form.allHeartCount : [];
      this.isIndeterminate = false;
    },

    handleCheckedHeartChange: function (value) {
      let heartCount = value.length;
      this.checkAllHeart = this.form.checkedHeart === heartCount;
      this.isIndeterminate =
        heartCount > 0 && heartCount < this.form.checkedHeart.length;
    },

    // handleCheckedTagChange: function(value) {

    // },

    handleOpen: function () {
      // initialize range index
      this.form.startIndex = this.currentVocIndex + 1;
      this.form.endIndex = this.totalVocCount;
      // reset tags
      this.form.checkedTags = [];
    },

    handleClose: function () {
      // console.log('aaaa')
      this.$emit("on-tool-config-form-close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mark-heart {
  color: deepskyblue;
}

.cursor {
  cursor: pointer;
}

.tags-check-group {
}
</style>
