import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

const state = {
  addedProject: {
    projectId: null,
    projectName: "",
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
