<template>
  <el-row>
    <el-col :span="11">
      <el-row>
        <el-col :span="20">
          <el-input
            v-model="splitChar"
            placeholder="请输入单词和释义间的分隔符"
            @input="editingText()"
          >
            <template #prepend>单词和释义间的分隔符(默认为空格)</template>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button
            type="primary"
            style="margin-right: 0"
            @click="addVocabularies"
          >
            批量导入
            <i class=""></i>
          </el-button>
        </el-col>
      </el-row>
      <el-input
        type="textarea"
        :rows="100"
        placeholder="请输入内容"
        v-model="textarea"
        @input="editingText()"
      ></el-input>
    </el-col>
    <el-col :offset="2" :span="11">
      <el-table :data="vocList" style="width: 100%">
        <el-table-column type="index" width="50"></el-table-column>
        <!-- <el-table-column prop="EnglishText" label="英文"></el-table-column> -->
        <el-table-column prop="EnglishText" label="单词">
          <template #default="scope">
            <el-input v-model="scope.row.EnglishText"></el-input>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="ChineseText" label="中文"></el-table-column> -->
        <el-table-column prop="ChineseText" label="释义">
          <template #default="scope">
            <el-input v-model="scope.row.ChineseText"></el-input>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="sentenceEnText" label="例句"></el-table-column> -->

        <el-table-column fixed="right" label="操作">
          <template #default="scope">
            <el-button
              @click="handleVocDelete(scope.$index, scope.row.Id, vocList)"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>

<script>
import { ElMessage } from "element-plus";
import { mapGetters } from "vuex";
export default {
  name: "VocAddManyContent",

  computed: {
    ...mapGetters("Set", ["addedSet"]),
  },

  data() {
    return {
      textarea: "",
      splitChar: " ",
      setName: "",
      vocList: [],
    };
  },
  mounted: function () {},
  methods: {
    editingText: function () {
      var vocArray = this.textarea.split("\n"); // split text into array based on enter
      for (let i = vocArray.length - 1; i >= 0; i--) {
        if (vocArray[i].trim() === "") vocArray.splice(i, 1);
      }
      this.vocList = [];
      for (let vocItem of vocArray) {
        vocItem = vocItem.trim();
        var enTempText = "";
        var cnTempText = "";
        if (vocItem.indexOf(this.splitChar) >= 0) {
          let splitIndex = vocItem.indexOf(this.splitChar);
          // if the word after splitIndex is english word, move the split index to the next position only if there is next position of splitIndex
          console.log(vocItem.indexOf(this.splitChar, splitIndex + 1));
          while (
            this.nextCharIsEnglish(vocItem, splitIndex) &&
            vocItem.indexOf(this.splitChar, splitIndex + 1) > splitIndex
          ) {
            splitIndex = vocItem.indexOf(this.splitChar, splitIndex + 1);
          }
          // if there is no split char in the vocItem, the index would be -1
          enTempText = vocItem.substring(0, splitIndex);
          cnTempText = vocItem.substring(splitIndex + 1);
        } else {
          enTempText = vocItem;
        }
        var tempRowItem = {
          EnglishText: enTempText,
          ChineseText: cnTempText,
        };
        this.vocList.push(tempRowItem);
        this.$emit("on-voc-list-updated", this.vocList);
      }
    },

    nextCharIsEnglish: function (str, index) {
      if (str.length === 0) return;
      var reg = /^[a-zA-Z]/;
      if (str[index + 1] && reg.test(str.charAt(index + 1))) return true;
      else return false;
    },

    handleVocDelete: function (rowIndex, rowId, vocList) {
      vocList.splice(rowIndex, 1);
    },

    addVocabularies: function () {
      if (
        this.addedSet.setId == null ||
        this.vocList === null ||
        this.vocList.length === 0
      ) {
        ElMessage({
          message: "请先添加集合^_^",
          type: "warning",
        });
        return;
      }

      // let postData = {
      //   updatedBy: this.currentTargetId,
      //   vocSetId: this.addedSet.setId,
      //   SimpleVocabularies: this.vocList,
      // };
      let postData = this.generateManyVocPostData();
      this.$store
        .dispatch("Vocabulary/batchly", postData)
        .then(() => {
          ElMessage({
            message: "词汇已添加.",
            type: "success",
          });
          this.textarea = ""; // clear the text area
          this.vocList = []; // clear the list
        })
        .catch((error) => ElMessage.error(error.toString()));
    },

    generateManyVocPostData: function () {
      let result = [];
      for (let i = 0; i < this.vocList.length; i++) {
        let temp = {
          updatedBy: localStorage.getItem("targetId"),
          vocSetId:
            this.addedSet.setId,
          englishText: this.vocList[i].EnglishText,
          chineseText: this.vocList[i].ChineseText,
        };
        result.push(temp);
      }
      return result;
    },
  },
};
</script>

<style scoped>
.el-input__inner {
  outline: none;
  border: 0 none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
}
</style>
