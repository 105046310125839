<template>
  <div>
    <el-dialog
      width="30%"
      v-model="dialogFormVisible"
      title="生成测试"
      @open="handleOpen"
      @close="handleClose"
    >
      <div style="margin-bottom: 10px">
        <el-select
          v-model="form.assigningStudentId"
          filterable
          class="m-2"
          placeholder="选择学生"
          @change="handleSelectStudentChange"
        >
          <el-option
            v-for="item in form.boundStudentList"
            :key="item.targetId"
            :label="item.chineseName"
            :value="item.targetId"
          />
        </el-select>
        <el-switch
          style="margin-left: 30px"
          v-model="form.publishSwitch"
          active-color="#13ce66"
          inactive-color="gray"
          inactive-text="自动发布-关"
          active-text="开"
        />
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onFormCancel()">Cancel</el-button>
          <el-button type="primary" @click="onFormConfirm()">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "DuplicateMultipleTests",

  props: ["modelValue", "multipleSelectedTests"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  data() {
    return {
      dialogFormVisible: false,
      userId: 0,
      targetId: 0,

      form: {
        assigningStudentId: null,
        boundStudentList: [],
        publishSwitch: true,
      },
    };
  },

  // mounted: function () {
  //   this.userId = localStorage.getItem("userId");
  //   this.targetId = localStorage.getItem("targetId");
  // },

  methods: {
    onFormCancel: function () {
      this.dialogFormVisible = false;
      this.form.assigningStudentId = null;
      this.$emit("on-duplicate-multiple-form-cancel");
    },
    onFormConfirm: function () {
      if (!this.form.assigningStudentId) {
        this.$message("布置对象未填写");
        return;
      }
      for (let i = 0; i < this.multipleSelectedTests.length; i++) {
        let postData = {
          oldTestId: this.multipleSelectedTests[i].id,
          studentId: this.form.assigningStudentId,
          name: this.multipleSelectedTests[i].name,
          type: this.multipleSelectedTests[i].type,
          origin: this.multipleSelectedTests[i].origin,
          description: this.multipleSelectedTests[i].description,
          status: "standing",
          nirvanaCount: 0,
          countDown: this.multipleSelectedTests[i].countDown,
          enAnswerCountDown: this.multipleSelectedTests[i].enAnswerCountDown,
          category: this.multipleSelectedTests[i].category,
          listeningCount: this.multipleSelectedTests[i].listeningCount,
          createdBy: this.userId,
          published: this.form.publishSwitch,
        };
        this.$store
          .dispatch("StudentTest/createDuplicatedTest", postData)
          .then(() => {
            this.$message({
              type: "success",
              message: "测试已生成",
            });
          })
          .catch((error) => this.$message(error.toString()));
      }

      this.dialogFormVisible = false;
    },

    getBoundStudentList: function () {
      this.$store
        .dispatch("Admin/getRelationship", {
          userId: this.userId,
        })
        .then((response) => {
          this.form.boundStudentList = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    handleSelectStudentChange: function (val) {
      this.form.assigningStudentId = val;
    },

    handleOpen: function () {
      this.userId = localStorage.getItem("userId");
      this.targetId = localStorage.getItem("targetId");
      this.getBoundStudentList();
    },

    handleClose: function () {
      // console.log('aaaa')
      this.form.assigningStudentId = null;
      this.$emit("on-duplicate-multiple-form-close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mark-heart {
  color: deepskyblue;
}

.cursor {
  cursor: pointer;
}
</style>
