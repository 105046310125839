<template>
  <div>
    <shared-header
      ref="sharedHeader"
      @org-clicked="handleOrgClicked()"
      @on-bound-student="handleOnBoundStudent()"
      @router-go-home="handleRouterGoHome"
      @router-go-diy="handleRouterGoDIY"
      @student-overview-clicked="handleStudentOverviewClick()"
      @recent-result-click="handleRecentResultsClick()"
      @change-password-click="changePasswordDialogVisible = true"
    />
    <el-row :gutter="20" class="main-content">
      <el-col :offset="1" :span="5"
        ><div
          class="new-bind-student-container"
          v-if="userRole == 'Admin' || userRole == 'Teacher'"
        >
          <new-bind-student
            @on-remote-select-student-click="handleOnBoundStudentClick"
            @on-bound-student-click="handleOnBoundStudentClick"
          />
        </div>
        <div class="" style="margin-top: 1rem">
          <unorganized-set-list
            ref="unorganizedSetList"
            @on-add-unorganized-set-click="handleAddUnorganizedSet"
          /></div
      ></el-col>
      <el-col :offset="2" :span="16"
        ><div class="">
          <project-cards
            ref="projectCardsRef"
            :projectType="projectType"
            @on-project-got="handleOnProjectGot($event)"
          /></div
      ></el-col>
    </el-row>
    <bound-student-list-drawer
      ref="boundStudentListDrawer"
      @on-bound-student-click="handleOnBoundStudentClick"
    />
    <voc-add-many-drawer
      v-model="showVocAddManyDrawer"
      @on-voc-add-many-drawer-closed="handleVocAddManyDrawerClose"
      @on-voc-add-many-process="handleVocAddManyProcess($event)"
      @on-set-added="handleSetAdded()"
    ></voc-add-many-drawer>

    <add-project-drawer
      v-model="showAddProjectDrawer"
      @on-add-project-drawer-closed="showAddProjectDrawer = false"
      :projectType="projectType"
      @on-add-project-drawer-close="handleAddProjectDrawerClose"
    ></add-project-drawer>

    <el-dialog
      v-model="recentResultsDialogVisible"
      title="学生最近的学习集"
      width="90%"
    >
      <el-table
        ref="pagedOverviewTable"
        :data="studentRecentSetsResults"
        @row-click="
          (row, column, event) => {
            handleOverviewListRowClick(row, column, event);
          }
        "
      >
        <el-table-column prop="vocProjectName" label="Project">
        </el-table-column>
        <el-table-column prop="vocSubjectName" label="Subject">
        </el-table-column>
        <el-table-column label="Set">
          <template #default="scope">
            <el-popover
              effect="light"
              trigger="hover"
              placement="top"
              width="auto"
            >
              <template #default>
                <div>{{ scope.row.vocSetNames }}</div>
              </template>
              <template #reference>
                {{
                  scope.row.vocSetNames.length > 16
                    ? scope.row.vocSetNames.substring(0, 16) + "..."
                    : scope.row.vocSetNames
                }}
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="learningMode" label="模式"> </el-table-column>
        <!-- <el-table-column prop="checkingMode" label="检查"> </el-table-column> -->
        <el-table-column
          property="vocStudentOverViewDetails"
          label="正确率"
          width="180"
        >
          <template #default="scope">
            -{{ calculateCorrectRate(scope.row.vocStudentOverViewDetails) }}
          </template>
        </el-table-column>
        <el-table-column prop="totalCount" label="Total"> </el-table-column>

        <el-table-column property="vocStudentOverViewDetails" label="标记">
          <template #default="scope">
            <span
              @click.stop="handleIncorrectColumnClick(scope.row)"
              style="cursor: pointer"
            >
              {{
                scope.row.vocStudentOverViewDetails
                  .filter((item) => !item.isCorrect)
                  .map((item) => item.englishText)
                  .join(", ")
              }}</span
            >
          </template>
        </el-table-column>

        <el-table-column
          property="createdDate"
          label="Created Date"
          sortable
          width="180"
          column-key="createdDate"
        >
          <template #default="scope">
            {{ scope.row.createdDate.substring(5, 16) }}
          </template>
        </el-table-column>
        <el-table-column
          property="updatedDate"
          label="Updated Date"
          sortable
          width="180"
          column-key="updatedDate"
        >
          <template #default="scope">
            {{ scope.row.updatedDate.substring(5, 16) }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      v-model="changePasswordDialogVisible"
      :show-close="false"
      title="密码，改改呗～"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <h4>
          <el-button type="primary" @click="handleChangePasswordConfirm"
            >更新</el-button
          >
        </h4>
        <el-input
          v-model="newPassword"
          type="password"
          placeholder="input new password"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SharedHeader from "./shared/SharedHeader.vue";
import UnorganizedSetList from "./unorganizedSetList.vue";
import ProjectCards from "./ProjectCards.vue";
import VocAddManyDrawer from "./VocAddManyDrawer.vue";
import AddProjectDrawer from "./AddProjectDrawer.vue";
import BoundStudentListDrawer from "./admin/BoundStudentListDrawer.vue";
import NewBindStudent from "./admin/NewBindStudent.vue";
import { ElMessage } from "element-plus";
export default {
  name: "DIY",
  components: {
    SharedHeader,
    UnorganizedSetList,
    ProjectCards,
    VocAddManyDrawer,
    AddProjectDrawer,
    BoundStudentListDrawer,
    NewBindStudent,
  },

  data() {
    return {
      showVocAddManyDrawer: false,
      showAddProjectDrawer: false,
      projectType: 1,
      userRole: null,
      studentRecentSetsResults: [],
      recentResultsDialogVisible: false,
      changePasswordDialogVisible: false,
      newPassword: "",
    };
  },

  mounted: function () {
    this.userRole = localStorage.getItem("userRole");
    if (localStorage.getItem("changePassword") === "true") {
      this.changePasswordDialogVisible = true;
    }
    this.projectType = 1;
    localStorage.setItem("projectType", 1);
    this.$refs.projectCardsRef.getProjects(1);
    this.$refs.projectCardsRef.currentViewingTeacherId = null;
    this.$refs.projectCardsRef.currentTeacher = null;
    //更新sharedHeader的projectType以防 ORG无法出现
    this.$refs.sharedHeader.projectType = 1;
  },

  methods: {
    handleChangePasswordConfirm: function () {
      if (this.newPassword.length < 6 || this.newPassword === "123456") {
        this.$message("密码过于简单");
      } else {
        this.$store
          .dispatch("Login/changePassword", {
            userId: localStorage.getItem("userId"),
            newPassword: this.newPassword,
          })
          .then((response) => {
            console.log(this.newPassword);
            console.log(response);
            if (response.data > 0) {
              localStorage.setItem("changePassword", false);
              this.$message({
                type: "success",
                message: "祝你长命百岁～",
              });
              this.changePasswordDialogVisible = false;
            } else {
              this.$message("糟糕，更新失败");
            }
          })
          .catch((error) => this.$message(error.toString()));
      }
    },

    handleOrgClicked: function () {
      this.showAddProjectDrawer = true;
    },

    handleAddUnorganizedSet: function () {
      this.showVocAddManyDrawer = true;
    },

    handleOnBoundStudent: function () {
      this.$refs.boundStudentListDrawer.openDrawer();
    },

    handleOnBoundStudentClick: function () {
      this.$refs.sharedHeader.updateBoundStudentChineseName();
      this.handleBoundStudentChange();
    },

    handleBoundStudentChange: function () {
      //get unorganized sets
      //远程绑定学生之后，不再能够显示学生unorganized sets
      //this.$refs.unorganizedSetList.getUserUnorganizedSets();
      //get projects
      this.$refs.projectCardsRef.getProjects(this.projectType);
    },

    handleVocAddManyDrawerClose: function () {
      this.showVocAddManyDrawer = false;
      this.$router.go(0);
    },

    handleVocAddManyProcess: function () {},

    handleRouterGoHome: function () {
      this.$router.push("/");
    },

    handleRouterGoDIY: function () {
      this.$router.push("/DIY");
    },

    handleStudentOverviewClick: function () {
      let queryObject = {
        studentId: localStorage.getItem("targetId"),
        studentName: localStorage.getItem("targetCNName"),
      };
      this.openAsNewPageWithCryption("/studentOverview", queryObject);
    },

    handleOnProjectGot: function (proType) {
      this.projectType = proType;
      //console.log(this.projectType)
    },

    handleAddProjectDrawerClose: function () {
      this.$router.go(0);
    },

    handleSetAdded: function () {
      this.$router.go(0);
    },

    handleRecentResultsClick: function () {
      let targetId = localStorage.getItem("targetId");

      this.$message({
        type: "info",
        message: "正在获取学生最近学习集，请稍等～",
      });

      let getParam = {
        studentId: targetId,
        paged: 1, // paged标记的是本次要拿的overviews页码
      };

      this.$store
        .dispatch("StudentOverview/getStudentPagedOverviews", getParam)
        .then((response) => {
          this.studentRecentSetsResults = response.data;
          this.$message({
            type: "success",
            message: "已获取，请查看弹出窗口～",
          });
          this.recentResultsDialogVisible = true;
        })
        .catch((error) => console.log(error.toString()));
    },

    calculateCorrectRate: function (overviewDetail) {
      if (!overviewDetail) return;
      let totalCount = overviewDetail.length;
      let incorrectCount = overviewDetail.filter((item) => {
        return item.isCorrect == false;
      }).length;
      return incorrectCount + "/" + totalCount;
    },

    //handleOverviewListRowClick: function (row, column, event, payload) {
    handleOverviewListRowClick: function (row) {
      // 点击recent results中的某一行，直接跳转到该学生的本条词汇集
      this.openCardByOverviewRow(row);
    },

    openCardByOverviewRow: function (row) {
      //如果setIds里面只有一个值，则找到set name并传到下一个页面； 有set name就说明是单个set，可以同时在下个页面获取set id
      let queryObject = {
        userId: row.studentId,
        userName: localStorage.getItem("targetCNName"),
        setIds: row.vocSetIds.split(",").map((item) => parseInt(item)),
        projectName: row.vocProjectName,
        projectType: this.projectType,
        subjectName: row.vocSubjectName,
        setName: row.vocSetNames,
      };
      // direct to card page
      this.openAsNewPageWithCryption("/card", queryObject);
    },

    handleIncorrectColumnClick: function (row) {
      this.copyIncorrectsToClip(row);
    },

    copyIncorrectsToClip: function (row) {
      //把current marked voc里面的所有单词的englishText都以一定的格式复制到剪切板以便直接复制
      let result = "";
      //获得targetCNName
      result += localStorage.getItem("targetCNName") + ": ";
      //获得当前科目名字
      result += row.vocSubjectName + ": ";
      //获得当前学习集名字
      result += row.vocSetNames + ": ";
      //获得当前错词数量
      result +=
        "(-" +
        this.calculateCorrectRate(row.vocStudentOverViewDetails) +
        ") : ";
      //拼接当前错词EnglishText，map每个voc到englishText，然后join
      result += row.vocStudentOverViewDetails
        .filter((item) => !item.isCorrect)
        .map((item) => item.englishText)
        .join(", ");
      let copyIncorrectsInput = document.createElement("input");
      copyIncorrectsInput.setAttribute("value", result);
      document.body.appendChild(copyIncorrectsInput);
      copyIncorrectsInput.select();
      try {
        let copied = document.execCommand("copy");

        if (copied) {
          ElMessage({
            message: result,
            type: "success",
          });
          document.body.removeChild(copyIncorrectsInput);
        }
      } catch {
        document.body.removeChild(copyIncorrectsInput);
      }
    },

    openAsNewPageWithCryption: function (routeName, query) {
      query = JSON.stringify(query);
      query = window.encodeURIComponent(query);
      let newpage = this.$router.resolve({
        path: routeName,
        query: { encodedString: window.btoa(query) },
      });
      window.open(newpage.href, "_blank");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-content {
  margin-top: 20px;
}
</style>
